<template>
  <v-row align="center" justify="center">
    <v-dialog :fullscreen="$vuetify.breakpoint.mdAndDown"
      v-model="dialog"
      max-width="1280"
    >
      <template slot="default">
        <user-detail
          :user-id="parseInt($route.params.id)"
          :create-new="isCreateNew"
          v-on:closeToParent="closeOverlay"
          v-on:userDataChanged="showNotification = true"
        ></user-detail>
      </template>
    </v-dialog>
    <data-changed-notification
      :visible.sync="showNotification"
    ></data-changed-notification>
  </v-row>
</template>

<script>
import UserDetail from './UserDetail.vue';
import DataChangedNotification from '../../components/DataChangedNotification.vue';

export default {
  name: 'OverlayUserDetail',
  components: {
    UserDetail,
    DataChangedNotification,
  },
  data() {
    return {
      dialog: false,
      showNotification: false,
      createNew: false,
    };
  },
  mounted() {
    this.dialog = true;
    if (this.$route.name === 'usercreate') this.createNew = true;
  },
  computed: {
    isCreateNew() {
      return this.createNew;
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.closeOverlay();
      }
    },
  },
  methods: {
    closeOverlay() {
      console.log('..closing');
      this.$router.push({ path: '/user-management' }).then(() => {
        this.dialog = false;
      }).catch((err) => {
        throw new Error(`Error occurred redirecting to /user-management: ${err}.`);
      });
    },
  },
};
</script>
