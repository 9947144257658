<template>
  <div class="combo-deals">
    <v-card-title>
      <h5 class="clickableElement" @click="showCombo = !showCombo">Combined Deals</h5>
      <v-btn icon @click="showCombo = !showCombo" color="primary">
        <v-icon>{{ showCombo ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition>
      <!-- Combined deals -->
      <div v-show="showCombo">
        <v-list-item three-line class="mb-10" v-if="dealData.escalationState==='NEW'">
          <v-list-item-content>
            Select all deals which are connected with this deal in a combo deal.
            Only deals with the same Escalation Level can be combined.
            To make level adjustments for a particular deal in a new tab,
            click the corresponding "Open Deal" icon. Changes in other tabs
            are not visible in this list immediately. Reload or re-open this
            deal in order to see these changes.
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-content>
            <v-list-item-content>
              Deals which are connected with this deal in a combo deal.
            </v-list-item-content>
          </v-list-item-content>
        </v-list-item>
        <v-text-field class="px-4"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <v-data-table class="shs-table combo-deals px-4"
                      v-model="selected"
                      :headers="tableData.headers"
                      :items="tableData.items"
                      :search="search"
                      :single-select="singleSelect"
                      item-key="quoteId"
                      :item-class="rowDisabled"
                      :show-select="dealData.escalationState==='NEW'"
                      :hide-default-footer="true"
                      disable-pagination
        >
          <template v-slot:header.data-table-select="{ header }">
            {{ header }}
          </template>
          <template v-slot:item.data-table-select="{ item }">
            <v-checkbox
              class="my-0"
              :disabled="checkDisabled(item)"
              color="primary"
              :ripple="false"
              v-model="selected"
              :value="item"
              hide-details
              dense
              @change="handleSelection(item)"
            >
            </v-checkbox>
          </template>
          <template v-slot:item.accountName="{ item }">
            {{ item.accountName }} {{ item.quoteId }}
            <v-chip label small v-if="item.quoteId === dealData.id" class="ml-2">This Deal</v-chip>
            <v-chip label small color="#9592C1"
                    v-if="hasComboLabel(item)" class="ml-2">
              Combo Deal</v-chip>
          </template>
          <template v-slot:item.tco="{ item }">
            {{  item.tco | toCurrency }}
          </template>
          <template v-slot:item.escalationLevelOverwrite="{ item }">
            <div class="icon-chip--wrapper">
              <v-icon
                v-if="item.escalationLevelOverwrite !== dealData.escalationlevelOverwrite">
                mdi-cancel
              </v-icon>
              <v-chip
                label
                :class="[item.escalationLevelOverwrite === 3
                 || item.escalationLevelOverwrite === 5 ? 'text--white' : '']"
                :color="getLevelData(item.escalationLevelOverwrite)"
              >
                Level {{  item.escalationLevelOverwrite }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item.vcai="{ item }">
            {{  item.vcai }}%
          </template>
          <template v-slot:item.quoteId="{ item }">
            <v-icon v-if="item.quoteId !== dealData.id"
              @click="openLink(item.quoteId)"
            >mdi-open-in-new</v-icon>
            <template v-else>--</template>
          </template>
          <template v-slot:body.append>
            <tr>
              <td v-if="dealData.escalationState==='NEW'"></td>
              <td></td>
              <td></td>
              <td>
                <div class="text-smaller">Combo Deal Total</div>
                <div v-if="dealData.escalationState==='NEW'">
                  <strong>{{ sumTcos | toCurrency }}</strong>
                </div>
                <div v-else>
                  <strong>{{ comboDealTotal | toCurrency }}</strong>
                </div>
<!--                <v-text-field
                  class="input-field__no-underline"
                  :value="sumTcos | toCurrency"
                  label="Combo Deal Total"
                  readonly
                ></v-text-field>-->
              </td>
              <td></td>
              <td>
                <template v-if="dealData.escalationState==='NEW'">
                  <v-text-field
                    class="input__vcai-total"
                    v-model="vcaiTotal"
                    label="VCAI Total"
                    suffix="%"
                    @change="vcaiTotalChange"
                  ></v-text-field>
                </template>
                <template v-else>
                  <div class="text-smaller">VCAI Total</div>
                  <strong>{{ vcaiTotal }}%</strong>
                </template>
              </td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import dealFunctions from '@/mixins/DealFunctions';
import ApiService from '@/services/ApiService';

export default {
  name: 'ComboDeals',
  mixins: [
    dealFunctions,
  ],
  props: {
    dealData: Object,
  },
  data() {
    return {
      showCombo: false,
      comboDeals: null,
      singleSelect: false,
      search: '',
      selected: [],
      tableData: {
        headers: [
          {
            text: 'Account Name',
            value: 'accountName',
          },
          {
            text: 'Quote No.',
            value: 'quoteNumber',
          },
          {
            text: 'TCO',
            value: 'tco',
          },
          {
            text: 'Level',
            value: 'escalationLevelOverwrite',
          },
          {
            text: 'VCAI %',
            value: 'vcai',
          },
          {
            text: 'Open Deal',
            value: 'quoteId',
            align: 'center',
            sortable: false,
          },
        ],
        items: [],
      },
      dealDataNew: null,
      comboDealTotal: null,
      vcaiTotal: null,
      comboDealData: {
        comboDealId: null,
        necessaryDealId: null,
        comboVersion: null,
        optionalDealId: null,
        vcaiTotal: null,
        tcoTotal: null,
        selected: true,
      },
      lastDeletedComboDealId: null,
    };
  },
  mounted() {
    if (this.dealData.escalationState === 'NEW') {
      this.getComboDealsAndNew();
    }
    if (this.dealData.escalationState !== 'NEW') {
      if (this.dealData.comboDealId) {
        this.getCombinedDeals();
      }
    }
    if (this.dealData.comboDealId) {
      this.showCombo = true;
      this.comboDealData.comboDealId = this.dealData.comboDealId;
    }
  },
  methods: {
    getCombinedDeals() {
      // this.comboDeals = mockedDeals;
      ApiService.fetchComboDeal(this.dealData.comboDealId).then((response) => {
        if (response) {
          this.comboDealTotal = response.data.tcoTotal;
          this.vcaiTotal = response.data.vcaiTotal;
          this.dealDataNew = response.data.quotes;
          // console.log('this.dealDataNew', this.dealDataNew);
          this.comboDeals = this.sortDealFirst(this.dealDataNew);
          console.log('comboDeals', this.comboDeals);
          if (this.differentLevels(this.comboDeals)) {
            console.log('Different Levels!');
            this.emitDifferentLevels();
          }
          this.tableData.items = this.comboDeals;
        } else {
          console.log('No data');
        }
      })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    getComboDealsAndNew() {
      ApiService.fetchComboDeals(this.dealData.id).then((response) => {
        if (response) {
          this.vcaiTotal = response.data.vcaiTotal;
          this.dealDataNew = response.data.quotes;
          this.comboDealData.comboVersion = response.data.comboVersion;
          // console.log('this.dealDataNew', this.dealDataNew);
          this.comboDeals = this.sortDealFirst(this.dealDataNew);
          // console.log('comboDeals', this.comboDeals);
          if (this.dealData.comboDealId) {
            if (this.differentLevels(this.comboDeals)) {
              console.log('Different Levels!');
              this.emitDifferentLevels();
            }
            const selected = this.findSelected(this.comboDeals);
            selected.forEach((item) => {
              this.selected.push(item);
            });
          } else {
            this.selected.push(this.comboDeals[0]);
          }
          this.tableData.items = this.comboDeals;
        } else {
          console.log('No data');
        }
      })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    setRowVersion(data) {
      console.log('setRowVersion called...', data, 'data.unselectedDeal', data.unselectedDeal);
      const { quotes } = data;
      const thisDeal = quotes.find((item) => item.quoteId === this.dealData.id);
      if (data.unselectedDeal) {
        console.log('unselectedDeal', data.unselectedDeal);
        if (data.unselectedDeal.quoteId === this.dealData.id) {
          console.log('deleted deal was parent, rowVersion', data.unselectedDeal.rowVersion);
          console.log('data', data);
          this.lastDeletedComboDealId = data.comboDealId;
          this.dealData.rowVersion = data.unselectedDeal.rowVersion;
          this.dealData.comboDealId = null;
          console.log('lastDeletedComboDealId', this.lastDeletedComboDealId);
        } else if (typeof thisDeal.rowVersion !== 'undefined') {
          console.log('deleted deal was NOT parent');
          console.log('setting rowVersion', thisDeal.rowVersion);
          this.dealData.rowVersion = thisDeal.rowVersion;
        } else {
          console.log('deleted deal was NOT parent, rowVersion', data.unselectedDeal.rowVersion);
          this.dealData.rowVersion = data.unselectedDeal.rowVersion;
        }
      } else if (typeof thisDeal.rowVersion !== 'undefined') {
        console.log('setting rowVersion', thisDeal.rowVersion);
        this.dealData.rowVersion = thisDeal.rowVersion;
      }
    },
    createComboDeal(data) {
      ApiService.createComboDeal(data).then((response) => {
        if (response) {
          if (response.data.quotes.length > 0) this.setRowVersion(response.data);
          this.dealData.comboDealId = response.data.comboDealId;
          this.comboDealData.comboDealId = this.dealData.comboDealId;
          if (response.data.comboVersion) {
            console.log('create: response.data.comboVersion', response.data.comboVersion);
            this.comboDealData.comboVersion = response.data.comboVersion;
          }
          console.log('comboVersion', response.data.comboVersion);
        } else {
          console.log('No data');
        }
      })
        .catch((error) => {
          console.log('Error:', error);
          if (error.response.status === 409) {
            console.log('Could not save');
            this.$emit('dataChangeConflict');
          }
        });
    },
    saveComboDeal(comboDealId, data) {
      ApiService.saveComboDeal(comboDealId, data).then((response) => {
        if (response) {
          if (response.data.quotes.length > 0) {
            this.setRowVersion(response.data);
          }
          if (!response.data.comboDealId) this.dealData.comboDealId = response.data.comboDealId;
          console.log('save: response.data.comboVersion', response.data.comboVersion);
          if (response.data.comboVersion) {
            this.comboDealData.comboVersion = response.data.comboVersion;
          }
          console.log('comboVersion', response.data.comboVersion);
        } else {
          console.log('No data');
        }
      })
        .catch((error) => {
          console.log('Error:', error);
          if (error.response.status === 409) {
            console.log('Could not save');
            this.$emit('dataChangeConflict');
          }
        });
    },
    filterDeals(data, filterBy) {
      return data.filter((deal) => deal.escalationState === filterBy);
    },
    openLink(dealNumber) {
      const link = `/board/${dealNumber}`;
      window.open(link);
    },
    findSelected(data) {
      const selectedData = data.filter((item) => item.selected);
      return selectedData;
    },
    differentLevels(data) {
      let differentLevels = false;
      const combinedDeals = data.filter((item) => item.comboDealId === this.dealData.comboDealId);
      const checkLevels = combinedDeals.filter((item) => item.escalationLevelOverwrite
        !== this.dealData.escalationlevelOverwrite);
      if (checkLevels.length) differentLevels = true;
      return differentLevels;
    },
    sortDealFirst(data) {
      const sortedData = [
        data.find((item) => item.quoteId === this.dealData.id),
        ...data.filter((item) => item.quoteId !== this.dealData.id),
      ];
      return sortedData;
    },
    rowDisabled(item) {
      let rowClass = '';
      if ((item.escalationLevelOverwrite !== this.dealData.escalationlevelOverwrite)
        || (item.comboDealId && (item.comboDealId !== this.dealData.comboDealId))) rowClass = 'row-disabled';
      if ((item.quoteId === this.dealData.id) && !this.dealData.comboDealId && this.lastDeletedComboDealId) rowClass = '';
      if ((item.quoteId !== this.dealData.id) && !this.dealData.comboDealId
        && this.lastDeletedComboDealId && item.selected) rowClass = '';
      return rowClass;
    },
    hasComboLabel(item) {
      let disabled = false;
      if ((item.comboDealId && (item.comboDealId !== this.dealData.comboDealId))) disabled = true;
      if ((item.quoteId === this.dealData.id) && !this.dealData.comboDealId
        && this.lastDeletedComboDealId) disabled = false;
      if ((item.quoteId !== this.dealData.id) && !this.dealData.comboDealId
        && this.lastDeletedComboDealId && item.selected) disabled = false;
      return disabled;
    },
    checkDisabled(item) {
      let disabled = false;
      if ((item.escalationLevelOverwrite !== this.dealData.escalationlevelOverwrite)
        || (item.comboDealId && (item.comboDealId !== this.dealData.comboDealId))) disabled = true;
      if (item.comboDealId && (item.comboDealId === this.dealData.comboDealId) && (item.escalationState !== 'NEW')) disabled = true;
      if (!this.dealData.comboDealId && this.lastDeletedComboDealId) disabled = false;
      return disabled;
    },
    handleSelection(item) {
      // console.log('item selected:', item);
      console.log('this.selected:', this.selected);
      if (!this.dealData.comboDealId) {
        this.comboDealData.necessaryDealId = this.dealData.id;
        this.comboDealData.optionalDealId = item.quoteId;
        this.comboDealData.vcaiTotal = this.vcaiTotal;
        this.comboDealData.tcoTotal = this.sumTcos;
        this.comboDealData.selected = true;
        if (this.selected.length > 1 && !this.lastDeletedComboDealId) {
          console.log('POST comboDeal');
          this.createComboDeal(this.comboDealData);
        } else {
          console.log('PATCH comboDeal');
          this.comboDealData.necessaryDealId = item.quoteId;
          this.comboDealData.optionalDealId = null;
          if (this.lastDeletedComboDealId && this.selected.length === 2) {
            this.comboDealData.necessaryDealId = this.selected[0].quoteId;
            this.comboDealData.optionalDealId = this.selected[1].quoteId;
          }
          this.comboDealData.vcaiTotal = this.vcaiTotal;
          this.comboDealData.tcoTotal = this.sumTcos;
          console.log('necessaryDealId', this.comboDealData.necessaryDealId, 'optionalDealId',
            this.comboDealData.optionalDealId);
          if (this.isItemSelected(item)) {
            this.saveComboDeal(this.dealData.comboDealId, this.comboDealData);
          } else {
            this.comboDealData.selected = false;
            this.saveComboDeal(this.dealData.comboDealId, this.comboDealData);
          }
        }
      } else {
        this.comboDealData.optionalDealId = null;
        this.comboDealData.necessaryDealId = item.quoteId;
        this.comboDealData.vcaiTotal = this.vcaiTotal;
        this.comboDealData.tcoTotal = this.sumTcos;
        if (this.isItemSelected(item)) {
          this.saveComboDeal(this.dealData.comboDealId, this.comboDealData);
        } else {
          this.comboDealData.selected = false;
          this.saveComboDeal(this.dealData.comboDealId, this.comboDealData);
        }
      }
    },
    vcaiTotalChange() {
      let floatLocale;
      if (this.vcaiTotal) floatLocale = parseFloat(this.vcaiTotal).toFixed(2);
      this.vcaiTotal = floatLocale; console.log('vcaiTotal', this.vcaiTotal);
      this.comboDealData.optionalDealId = null;
      this.comboDealData.necessaryDealId = null;
      this.comboDealData.vcaiTotal = floatLocale;
      this.saveComboDeal(this.dealData.comboDealId, this.comboDealData);
    },
    isItemSelected(item) {
      if (this.selected.includes(item)) return true;
      return false;
    },
    emitDifferentLevels() {
      this.$emit('differentLevels');
    },
  },
  computed: {
    sumTcos() {
      let total = 0;
      if (this.selected.length) {
        this.selected.forEach((item) => {
          total += item.tco;
        });
      }
      return total;
    },
    currentLevel() {
      return this.dealData.escalationlevelOverwrite;
    },
  },
  watch: {
    currentLevel(val) {
      console.log('ecalationlevel changed', val);
      if (this.dealData.escalationState === 'NEW') {
        this.getComboDealsAndNew();
      } else if (this.dealData.escalationState === 'VERIFIER_LD'
        || this.dealData.escalationState === 'VERIFIER_POC') {
        this.getCombinedDeals();
      }
    },
  },
};
</script>

<style lang="scss">
  .icon-chip--wrapper {
    position: relative;
    display: inline;
    .v-icon {
      position: absolute;
      left: 34%;
      top: 0;
      z-index: 1;
    }
  }
  .shs-table.combo-deals {
    tr:last-of-type:hover {
      background: #fff !important;
      cursor: default !important;
    }
    .input__vcai-total {
      width: 5.5em;
    }
  }
</style>
