<template>
  <div class="value-adds">
    <v-card-title>
      <h5 class="clickableElement" @click="showValueAdds = !showValueAdds">Value Adds</h5>
      <v-btn icon @click="showValueAdds = !showValueAdds" color="primary">
        <v-icon>{{ showValueAdds ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition>
      <div v-show="showValueAdds" class="hidden-sm-and-down">
        <template v-if="dealData.additionalItems">
          <v-data-table class="shs-table shs-table-last-border px-4"
                        :headers="tableData.valueAddTable.headers"
                        :items="indexedItems"
                        :single-expand="tableData.valueAddTable.singleExpand"
                        :expanded.sync="tableData.valueAddTable.expanded"
                        item-key="id"
                        disable-pagination
                        disable-sort
                        show-expand
                        :hide-default-footer="true"
                        @click:row="expandTableRow"
          >
            <template v-slot:expanded-item="{ item }" v-if="dealData.escalationState==='NEW'">
              <td colspan="2"></td>
              <td class="text-start">GPO Standard</td>
              <td class="text-start"></td>
              <td class="text-start">
                <v-text-field
                  v-model="item.qtyGpoStandard"
                  type="number"
                  :disabled="isReadOnly"
                  :placeholder="item.qty.toString()"
                  @blur="setValueGpoQty(item)"
                ></v-text-field>
              </td>
              <td class="text-start">
                <v-text-field
                  v-model="item.priceNetGpoStandard"
                  type="number"
                  :disabled="isReadOnly"
                  :placeholder="item.priceNet.toString()"
                  prefix="$"
                  @blur="setValueGpoPriceNet(item)"
                ></v-text-field>
              </td>
              <td class="text-start">
                <v-text-field
                  v-model="item.costGpoStandard"
                  type="number"
                  :disabled="isReadOnly"
                  :placeholder="item.cost.toString()"
                  prefix="$"
                  @blur="setValueGpoCost(item)"
                ></v-text-field>
              </td>
              <td colspan="6"></td>
            </template>
            <template v-slot:expanded-item="props" v-else>
              <td colspan="4"></td>
              <td class="text-start">{{ getQtyValue(props.item) }}</td>
              <td class="text-start">{{ getPriceNetValue(props.item) | toCurrency }}</td>
              <td class="text-start">{{ getCostValue(props.item) | toCurrency }}</td>
              <td colspan="6"></td>
            </template>
            <template v-slot:item.qty="{ item }">
              {{ item.qty }}
            </template>
            <template v-slot:item.priceNet="{ item }">
              {{ item.priceNet | toCurrency }}
            </template>
            <template v-slot:item.cost="{ item }">
              {{ item.cost | toCurrency }}
            </template>
            <template v-slot:item.totalCtrtPriceNet="{ item }">
              {{ item.totalCtrtPriceNet | toCurrency }}
            </template>
            <template v-slot:item.totalCtrtCost="{ item }">
              {{ item.totalCtrtCost | toCurrency }}
            </template>
            <template v-slot:item.valueAdd="{ item }" v-if="dealData.escalationState==='NEW'">
              {{ item.valueAdd | toCurrency }}
            </template>
            <template v-slot:item.valueAdd="{ item }" v-else>
              {{ item.valueAdd | toCurrency }}
            </template>
            <template v-slot:body.append v-if="dealData.escalationState==='NEW'">
              <tr>
                <td v-for="n in (tableData.valueAddTable.headers.length - 4)" :key="n"></td>
                <td colspan="3" class="font-weight-bold text-right">
                  <template v-if="percentValueAddSum">
                    <span v-if="percentValueAddSum < 1" class="success--text">{{ percentValueAddSum }}% </span>
                    <span v-else class="error--text">{{ percentValueAddSum }}% </span>
                  </template>
                  Sum of all Value Adds</td>
                <td>{{ sumValueAddsColumn | toCurrency }}</td>
              </tr>
              <tr>
                <td v-for="n in (tableData.valueAddTable.headers.length - 4)" :key="n"></td>
                <td colspan="3" class="font-weight-bold text-right">
                  1% of TCO</td>
                <td v-if="dealData.additionalItems">
                  {{ dealData.additionalItems.recurringAnnualRevenuePercent | toCurrency }}</td>
              </tr>
              <tr>
                <td v-for="n in (tableData.valueAddTable.headers.length - 4)" :key="n"></td>
                <td colspan="3" class="font-weight-bold text-right">Hurdle Violation</td>
                <td>
                  <span v-if="hurdleViolation > 0" v-bind:class="{'error--text': hurdleViolation > 0}">
                    {{ hurdleViolation | toCurrency }}
                  </span>
                  <span v-else>none</span>
                </td>
              </tr>
            </template>
            <template v-slot:body.append v-else>
              <tr>
                <td v-for="n in (tableData.valueAddTable.headers.length - 4)" :key="n"></td>
                <td colspan="3" class="font-weight-bold text-right">
                  <template v-if="percentValueAddSum">
                    <span v-if="percentValueAddSum < 1" class="success--text">{{ percentValueAddSum }}% </span>
                    <span v-else class="error--text">{{ percentValueAddSum }}% </span>
                  </template>
                  Sum of all Value Adds</td>
                <td>{{ sumValueAddsColumn | toCurrency }}</td>
              </tr>
              <tr>
                <td v-for="n in (tableData.valueAddTable.headers.length - 4)" :key="n"></td>
                <td colspan="3" class="font-weight-bold text-right">
                  1% of TCO</td>
                <td v-if="dealData.additionalItems">
                  {{ dealData.additionalItems.recurringAnnualRevenuePercent | toCurrency }}</td>
              </tr>
              <tr>
                <td v-for="n in (tableData.valueAddTable.headers.length - 4)" :key="n"></td>
                <td colspan="3" class="font-weight-bold text-right">Hurdle Violation</td>
                <td>
                  <span v-if="dealData.additionalItems.valueHurdleViolation > 0"
                        v-bind:class="{'error--text': dealData.additionalItems.valueHurdleViolation > 0}">
                    {{ dealData.additionalItems.valueHurdleViolation | toCurrency }}
                  </span>
                  <span v-else>none</span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
        <template v-else>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                No data available.
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </div>
    </v-expand-transition>
    <v-card-subtitle v-show="showValueAdds" class="hidden-md-and-up">
      Note: See details on bigger screen.
    </v-card-subtitle>
    <template v-if="!showValueAdds">
      <v-list-item>
        <v-list-item-title class="font-weight-bold">
          Value Add Percentage
          <template v-if="percentValueAddSum">
            <span v-if="percentValueAddSum < 3" class="font-weight-regular success--text ml-4">
            {{ percentValueAddSum }}% </span>
            <span v-else class="font-weight-regular error--text ml-4">
            {{ percentValueAddSum }}% </span>
          </template>
          <template v-else>
            <span class="font-weight-regular ml-4">0%</span>
          </template>
        </v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title class="font-weight-bold">
          Hurdle violation
          <span class="font-weight-regular ml-4"
                v-if="hurdleViolation && hurdleViolation > 0" v-bind:class="{'error--text': hurdleViolation > 0}">
            {{ hurdleViolation | toCurrency }}
          </span>
          <span class="font-weight-regular ml-4" v-else>none</span>
        </v-list-item-title>
      </v-list-item>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ValueAdds',
  props: {
    dealData: Object,
  },
  data() {
    return {
      showValueAdds: true,
      tableData: {
        valueAddTable: {
          expanded: [],
          singleExpand: false,
          headers: [
            {
              text: 'Product Line',
              value: 'spl',
            },
            {
              text: 'Type',
              value: 'type',
            },
            {
              text: 'Description',
              value: 'description',
            },
            {
              text: 'GPO',
              value: 'data-table-expand',
            },
            {
              text: 'Qty.',
              value: 'qty',
            },
            {
              text: 'Price Net',
              value: 'priceNet',
            },
            {
              text: 'Cost',
              value: 'cost',
            },
            {
              text: 'Start',
              value: 'start',
            },
            {
              text: 'Dur.',
              value: 'duration',
            },
            {
              text: 'Period',
              value: 'period',
            },
            {
              text: 'Total Price Net',
              value: 'totalCtrtPriceNet',
            },
            {
              text: 'Total Cost',
              value: 'totalCtrtCost',
            },
            {
              text: 'Value Add',
              value: 'valueAdd',
            },
          ],
          items: [],
        },
      },
      emitData: {},
      products: [],
      emitProductSums: [],
    };
  },
  mounted() {
    if (this.dealData.additionalItems) {
      this.tableData.valueAddTable.items = this.getValueAddsTableData(this.dealData.additionalItems.items);
      this.products = this.getProducts(this.tableData.valueAddTable.items);
    }
    if (this.dealData.escalationState !== 'NEW' || this.dealData.escalationState !== 'DONE') {
      if (this.sumValueAddsColumn === 0) this.showValueAdds = false;
    }
    if (this.$vuetify.breakpoint.smAndDown) this.showValueAdds = false;
  },
  methods: {
    expandTableRow(item) {
      const expandedRows = this.tableData.valueAddTable.expanded;
      // check if item row is already expanded
      const found = expandedRows.some((el) => el.id === item.id);
      if (found) {
        // remove item from expanded rows
        expandedRows.splice(expandedRows.findIndex((el) => el.id === item.id), 1);
      } else {
        expandedRows.push(item);
      }
    },
    getValueAddsTableData(items) {
      return items.filter((item) => item !== item.violation);
    },
    getProducts(items) {
      const products = [];
      Object.keys(items).forEach((key) => {
        if (products.indexOf(items[key].spl) === -1) {
          products.push(items[key].spl);
        }
      });
      return products;
    },
    sumValueAddsRow(item) {
      let total = 0;
      if (item.qtyGpoStandard || item.costGpoStandard || item.priceNetGpoStandard) {
        total = (item.qty - item.qtyGpoStandard)
          * ((item.cost - item.costGpoStandard) - (item.priceNet - item.priceNetGpoStandard)) * item.duration;
        this.setValueAddValue(total, item.id);
      } else {
        this.setValueAddValue(total, item.id);
      }
    },
    getQtyValue(item) {
      return item.qtyGpoStandard ? item.qtyGpoStandard : item.qty;
    },
    getPriceNetValue(item) {
      return item.priceNetGpoStandard ? item.priceNetGpoStandard : item.priceNet;
    },
    getCostValue(item) {
      return item.costGpoStandard ? item.costGpoStandard : item.cost;
    },
    setValueGpoQty(item) {
      this.tableData.valueAddTable.items[item.id].qtyGpoStandard = item.qtyGpoStandard;
      this.sumValueAddsRow(item);
      this.emitChangeQuantityGpo(item);
    },
    setValueGpoPriceNet(item) {
      this.tableData.valueAddTable.items[item.id].priceNetGpoStandard = item.priceNetGpoStandard;
      this.sumValueAddsRow(item);
      this.emitChangePriceNetGpo(item);
    },
    setValueGpoCost(item) {
      this.tableData.valueAddTable.items[item.id].costGpoStandard = item.costGpoStandard;
      this.sumValueAddsRow(item);
      this.emitChangeCostGpo(item);
    },
    setValueAddValue(value, id) {
      // console.log('setValueAddValue', value);
      this.tableData.valueAddTable.items[id].valueAdd = value;
    },
    emitChangeQuantityGpo(item) {
      this.emitData.rank = item.rank;
      this.emitData.qtyGpoStandard = item.qtyGpoStandard;
      this.emitData.priceNetGpoStandard = null;
      this.emitData.costGpoStandard = null;
      this.emitData.valueAdd = this.tableData.valueAddTable.items[item.id].valueAdd;
      this.emitData.valueAddSumPercent = this.percentValueAddSum;
      this.emitData.valueAddSum = this.sumValueAddsColumn;
      if (this.hurdleViolation > 0) {
        this.emitData.valueHurdleViolation = this.hurdleViolation;
      } else {
        this.emitData.valueHurdleViolation = null;
      }
      this.emitData.rowVersion = this.dealData.rowVersion;
      this.$emit('changeQuantityGpo', this.emitData);
      this.resetEmitData();
    },
    emitChangePriceNetGpo(item) {
      this.emitData.rank = item.rank;
      this.emitData.qtyGpoStandard = null;
      this.emitData.priceNetGpoStandard = item.priceNetGpoStandard;
      this.emitData.costGpoStandard = null;
      this.emitData.valueAdd = this.tableData.valueAddTable.items[item.id].valueAdd;
      this.emitData.valueAddSumPercent = this.percentValueAddSum;
      this.emitData.valueAddSum = this.sumValueAddsColumn;
      if (this.hurdleViolation > 0) {
        this.emitData.valueHurdleViolation = this.hurdleViolation;
      } else {
        this.emitData.valueHurdleViolation = null;
      }
      this.emitData.rowVersion = this.dealData.rowVersion;
      this.$emit('changePriceNetGpo', this.emitData);
      this.resetEmitData();
    },
    emitChangeCostGpo(item) {
      this.emitData.rank = item.rank;
      this.emitData.qtyGpoStandard = null;
      this.emitData.priceNetGpoStandard = null;
      this.emitData.costGpoStandard = item.costGpoStandard;
      this.emitData.valueAdd = this.tableData.valueAddTable.items[item.id].valueAdd;
      this.emitData.valueAddSumPercent = this.percentValueAddSum;
      this.emitData.valueAddSum = this.sumValueAddsColumn;
      if (this.hurdleViolation > 0) {
        this.emitData.valueHurdleViolation = this.hurdleViolation;
      } else {
        this.emitData.valueHurdleViolation = null;
      }
      this.emitData.rowVersion = this.dealData.rowVersion;
      this.$emit('changeCostGpo', this.emitData);
      this.resetEmitData();
    },
    resetEmitData() {
      this.emitData = {};
    },
    emitChangeProductSums() {
      this.$emit('changesProductSums', this.emitProductSums);
    },
    sumByProduct(product) {
      const filtered = this.tableData.valueAddTable.items.filter((item) => item.spl === product);
      // console.log('filtered', filtered);
      return filtered.reduce((acc, item) => acc + item.valueAdd, 0);
    },
  },
  computed: {
    indexedItems() {
      return this.tableData.valueAddTable.items.map((item, index) => ({
        id: index,
        ...item,
      }));
    },
    sumValueAddsColumn() {
      return this.tableData.valueAddTable.items.reduce((acc, item) => acc + item.valueAdd, 0);
    },
    percentValueAddSum() {
      // eslint-disable-next-line no-mixed-operators
      const sum = this.sumValueAddsColumn / this.dealData.profitQuote.tcoSpend * 100;
      return Math.round(sum * 10) / 10;
    },
    hurdleViolation() {
      const sum = this.sumValueAddsColumn - this.dealData.additionalItems?.recurringAnnualRevenuePercent;
      return Math.round(sum * 100) / 100;
    },
    isReadOnly() {
      return this.$store.state.userReadOnly;
    },
  },
  watch: {
    sumValueAddsColumn() {
      this.products.forEach((product) => {
        const sum = this.sumByProduct(product);
        // console.log(`sum by product ${product}`, sum, 'index', index);
        if (this.emitProductSums.some((item) => item.product === product)) {
          const existing = this.emitProductSums.find((item) => item.product === product);
          existing.sum = sum;
        } else {
          const data = {};
          data.product = product;
          data.sum = sum;
          this.emitProductSums.push(data);
        }
        // console.log('emitProductSums', this.emitProductSums);
      });
      this.emitChangeProductSums(this.emitProductSums);
    },
  },
};
</script>

<style lang="scss">
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  tbody:hover tr:not(.v-data-table__expanded__content) {
    cursor: pointer;
    opacity: .9;
  }
</style>
