<template>
  <v-row align="center" justify="center">
    <v-dialog :fullscreen="$vuetify.breakpoint.mdAndDown"
              v-model="dialog"
              max-width="1280"
    >
      <template slot="default">
        <deal-detail
          :deal-id="parseInt($route.params.id)"
          v-on:closeToParent="closeOverlay"
        ></deal-detail>
      </template>
    </v-dialog>
  </v-row>
</template>

<script>
import DealDetail from '@/views/DealDetail.vue';

export default {
  name: 'OverlayArchiveDetail',
  components: {
    DealDetail,
  },
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {
    this.dialog = true;
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.closeOverlay();
      }
    },
  },
  methods: {
    closeOverlay() {
      console.log('..closing archive details');
      this.$router.push({ path: '/archive' }).then(() => {
        this.dialog = false;
      }).catch((err) => {
        throw new Error(`Error occurred redirecting to /archive: ${err}.`);
      });
    },
  },
};
</script>

<style scoped>

</style>
