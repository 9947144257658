<template>
  <div class="account-data">
    <v-card-title>
      <h5 class="clickableElement" @click="showAccount = !showAccount">Account Data</h5>
      <v-btn icon @click="showAccount = !showAccount" color="primary">
        <v-icon>{{ showAccount ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition>
      <div v-show="showAccount">
        <v-card-subtitle v-if="dealData.customerData === null">
          No Account data.
        </v-card-subtitle>
        <div v-else>
          <div v-if="dealData.escalationState==='NEW'">
            <v-form v-model="valid">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  GPO Affiliation
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ dealData.customerData.affiliation ? dealData.customerData.affiliation : '&ndash;' }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col cols="12" class="py-0 col-md-6">
                    <v-select
                      v-model="prospectExchange"
                      :rules="prospectRules"
                      :items="['PROSPECT', 'EXCHANGE', 'PARTIAL']"
                      label="Prospect or Exchange*"
                      required
                      :disabled="isReadOnly"
                      @change="emitChangeProspectExchange"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Volume (patients)
                </v-list-item-subtitle>
                <v-list-item-title v-if="dealData.profitOverview.volumePatients">
                  {{ dealData.profitOverview.volumePatients | toNumber }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  &ndash;
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col cols="12" class="py-0 col-md-6">
                    <v-text-field
                      v-model="salesRep"
                      :rules="salesRepRules"
                      label="Sales Rep*"
                      required
                      :disabled="isReadOnly"
                      @change="emitChangeSalesRep"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-row>
                  <v-col cols="12" class="py-0 col-md-6">
                    <v-text-field
                      v-model="salesManager"
                      :rules="salesManRules"
                      label="Sales Manager*"
                      required
                      :disabled="isReadOnly"
                      @change="emitChangeSalesManager"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            </v-form>
          </div>
          <div v-else>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Sold to #
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ dealData.customerData.soldToNumber ? dealData.customerData.soldToNumber : '&ndash;' }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  GPO Affiliation
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ dealData.customerData.affiliation ? dealData.customerData.affiliation : '&ndash;' }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Prospect or Exchange
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ dealData.prospectOrExchange ? dealData.prospectOrExchange : '&ndash;'}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Volume (patients)
                </v-list-item-subtitle>
                <v-list-item-title v-if="dealData.profitOverview.volumePatients">
                  {{ dealData.profitOverview.volumePatients | toNumber }}
                </v-list-item-title>
                <v-list-item-title v-else>
                  &ndash;
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Sales Rep
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ dealData.salesRep ? dealData.salesRep : '&ndash;' }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Sales Manager
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ dealData.salesManager ? dealData.salesManager : '&ndash;' }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'AccountData',
  props: {
    dealData: Object,
  },
  data() {
    return {
      showAccount: true,
      valid: true,
      prospectExchange: 'EMPTY',
      prospectRules: [
        (v) => !!v || '',
      ],
      salesRep: '',
      salesRepRules: [
        (v) => !!v || '',
      ],
      salesManager: '',
      salesManRules: [
        (v) => !!v || '',
      ],
    };
  },
  mounted() {
    if (this.dealData.prospectOrExchange) {
      this.prospectExchange = this.dealData.prospectOrExchange;
    }
    if (this.dealData.salesRep) {
      this.salesRep = this.dealData.salesRep;
    }
    if (this.dealData.salesManager) {
      this.salesManager = this.dealData.salesManager;
    }
  },
  computed: {
    isReadOnly() {
      return this.$store.state.userReadOnly;
    },
  },
  methods: {
    emitChangeProspectExchange() {
      this.$emit('changeProspectExchange', this.prospectExchange);
    },
    emitChangeSalesRep() {
      this.$emit('changeSalesRep', this.salesRep);
    },
    emitChangeSalesManager() {
      this.$emit('changeSalesManager', this.salesManager);
    },
    emitFormValid(val) {
      this.$emit('changeFormValid', val);
    },
  },
  watch: {
    valid(val) {
      // console.log('Valid changed Account', val);
      this.emitFormValid(val);
    },
  },
};
</script>

<style lang="scss">

</style>
