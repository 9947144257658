<template>
  <spinner />
</template>

<script>
import Spinner from '../components/Spinner.vue';

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      timeoutID: null,
    };
  },
  created() {
    // If the user unintentionally comes to this page, he will be forwarded after a timeout.
    this.timeoutID = window.setTimeout(() => this.$router.push({ path: '/board' }), 7000);
  },
  beforeDestroy() {
    window.clearTimeout(this.timeoutID);
  },
};
</script>
