<template>
  <div class="notifications-view">
    <shs-header
      :full-header="false"
      :is-user="false"
      :is-archive="false"
      :is-notification-settings="true"
    ></shs-header>
    <v-container class="mb-16">
      <v-layout wrap class="my-8">
        <v-flex xs12 md8>
          <v-layout column>
            <h2>Notification Pop-up</h2>
            <h5>An easy way to push out information to all app users</h5>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap class="mb-8">
        <v-flex xs12 md8>
          <v-layout column>
            <v-textarea
              v-model="message"
              :placeholder="placeholderMessage"
              class="mr-3"
              @change="(value) => setNotificationField({ fieldName: 'message', value})"
            ></v-textarea>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap align-end>
        <v-flex xs12 md8>
          <v-layout wrap>
            <v-flex xs6 md5 class="pr-4">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateRangeText"
                    label="Period of time"
                    append-icon="mdi-calendar"
                    readonly
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="periodOfTime"
                  no-title
                  range
                  @change="(value) => setNotificationField({ fieldName: 'periodOfTime', value})"
                />
              </v-menu>
            </v-flex>
            <v-flex xs6 md5 class="pr-3 pr-md-4">
                <v-select
                  v-model="type"
                  :items="types"
                  label="Choose type"
                  @change="(value) => setNotificationField({ fieldName: 'type', value})"
                >
                </v-select>
            </v-flex>
            <v-flex xs12 md2>
              <v-layout column align-end>
                <p class="mb-0 pb-0 mr-3 shs-switch-label">Notification Status</p>
                <v-switch
                  class="mt-0"
                  :disabled="isSwitchDisabled()"
                  inset
                  v-model="active"
                  @change="(value) => setNotificationField({ fieldName: 'active', value})"
                >
                </v-switch>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <shs-footer
      :full-footer="true"
    ></shs-footer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ShsHeader from '../components/Header.vue';
import ShsFooter from '../components/Footer.vue';

export default {
  name: 'NotificationSettings',
  components: { ShsHeader, ShsFooter },
  data() {
    return {
      message: '',
      periodOfTime: [],
      menu: false,
      types: ['Info', 'Warning'],
      type: '',
      active: false,
      placeholderMessage: 'Insert the information you want to display in the notification pop-up. Below this text box, select the respective period of time in which the notification should appear and the type of it.',
    };
  },
  computed: {
    ...mapGetters({
      getNotification: 'notification',
    }),
    dateRangeText() {
      return this.periodOfTime.join(' - ');
    },
  },
  created() {
    this.setFormFieldsContent();
  },
  watch: {
    getNotification() {
      this.setFormFieldsContent();
    },
  },
  methods: {
    setFormFieldsContent() {
      this.message = this.getNotification.message ? this.getNotification.message : '';
      this.periodOfTime = this.getNotification.periodOfTime ? this.getNotification.periodOfTime : [null, null];
      this.type = this.getNotification.type ? this.getNotification.type : '';
      this.active = !!this.getNotification.active;
    },
    setNotificationField(data) {
      this.$store.dispatch('saveNotificationFields',
        { fieldName: data.fieldName, value: data.value });
    },
    isSwitchDisabled() {
      return !((this.message.length > 0) && (this.periodOfTime.length === 2) && (this.type.length > 0));
    },
  },
};
</script>

<style lang="scss">
@import 'src/scss/colors';

.v-application {
  .home-view {
    h1, h2, h3, h6 {
      margin-bottom: 8px;
    }

    .v-icon + a {
      color: $gray-shade-4;

      &:hover {
        color: $orange;
      }
    }
  }
  .shs-switch-label {
    line-height: 2;
    font-size: 0.8em;
    color: $gray-shade-2;
  }
}
</style>
