<template>
  <div class="profile-data">
    <!-- Profile section-->
    <v-card-title>
      <h5 class="clickableElement" @click="showProfile = !showProfile">Profile Overview</h5>
      <v-btn icon @click="showProfile = !showProfile" color="primary">
        <v-icon>{{ showProfile ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition>
      <div v-show="showProfile">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              TCO Spend $
            </v-list-item-subtitle>
            <v-list-item-title v-if="dealData.profitQuote.tcoSpend">
              {{ dealData.profitQuote.tcoSpend | toCurrency }}
            </v-list-item-title>
            <v-list-item-title v-else>
              &ndash;
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              Instrument Spend $
            </v-list-item-subtitle>
            <v-list-item-title v-if="dealData.profitQuote.instrumentSpend">
              {{ dealData.profitQuote.instrumentSpend | toCurrency }}
            </v-list-item-title>
            <v-list-item-title v-else>
              &ndash;
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              RCO Annual Spend $
            </v-list-item-subtitle>
            <v-list-item-title v-if="dealData.profitQuote.recuringAnnualSpend">
              {{ dealData.profitQuote.recuringAnnualSpend | toCurrency }}
            </v-list-item-title>
            <v-list-item-title v-else>
              &ndash;
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              Overall Deal VCAI %
            </v-list-item-subtitle>
            <v-list-item-title v-if="dealData.profitQuote.dealVcai">
              {{ dealData.profitQuote.dealVcai }}%
            </v-list-item-title>
            <v-list-item-title v-else>
              &ndash;
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              Year 1 VCAI $
            </v-list-item-subtitle>
            <v-list-item-title v-if="dealData.profitQuote.firstYearProfit">
              {{ dealData.profitQuote.firstYearProfit | toCurrency }}
            </v-list-item-title>
            <v-list-item-title v-else>
              &ndash;
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              Year 1 VCAI %
            </v-list-item-subtitle>
            <v-list-item-title v-if="dealData.profitQuote.firstYearProfitPercent">
              {{ dealData.profitQuote.firstYearProfitPercent }}%
            </v-list-item-title>
            <v-list-item-title v-else>
              &ndash;
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'ProfileData',
  props: {
    dealData: Object,
  },
  data() {
    return {
      showProfile: true,
    };
  },
};
</script>

<style lang="scss">

</style>
