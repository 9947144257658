<template>
  <v-footer color="#fff">
    <v-container fluid>
      <v-row v-if="fullFooter">
        <v-col cols="12" class="mb-5">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="mt-5">
          <h6>Documentation</h6>
          <div class="legal-links no-divider color-gray mt-4">
            <div class="legal-links__item d-block">
              <a class="legal-links__link" target="_blank"
                 href="https://healthineersnam.sharepoint.com/teams/DealEscalationGuardrails/Shared%20Documents/Forms/AllItems.aspx?id=%2Fteams%2FDealEscalationGuardrails%2FShared%20Documents%2FFY23_US%20DX_Guardrails_Sales%20Rollout%20vF%2Epdf&parent=%2Fteams%2FDealEscalationGuardrails%2FShared%20Documents">Guardrails for FY23 [PDF]</a>
            </div>
            <div class="legal-links__item d-block">
              <a class="legal-links__link" target="_blank"
                 href="https://bit.ly/2GaowOa">Guardrails SharePoint</a>
            </div>
            <div class="legal-links__item d-block">
              <a class="legal-links__link" target="_blank"
                 href="https://web.microsoftstream.com/video/7a21a70d-4fac-4f96-9eae-15ae41cb5d59">
                Introduction Tutorial [Video]</a>
            </div>
            <div class="legal-links__item d-block">
              <a class="legal-links__link" target="_blank"
                 href="https://web.microsoftstream.com/video/bb3125c3-0eea-4090-9920-36d9f9e70994">
                New Features in Version 2.0 [Video]</a>
            </div>
            <div class="legal-links__item d-block">
              <a class="legal-links__link" target="_blank"
                 href="https://bit.ly/3pLiO9F">New Features in Version 2.1 [PDF]</a>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="mt-5">
          <h6>Support & Questions</h6>
          <v-btn
            class="mt-4"
            rounded
            color="primary"
            depressed
            @click="sendMailContact"
          >
            Contact Us
          </v-btn>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="mt-5">
          <h6>Feedback</h6>
          <div class="feedback-links mt-4">
            <v-icon size="32" @click="sendMailGood">mdi-emoticon-happy-outline</v-icon>
            <v-icon class="ml-7" size="32" @click="sendMailBad">mdi-emoticon-sad-outline</v-icon>
          </div>
        </v-col>
        <v-col lg="3" class="mt-5"></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="mt-5">
          <v-divider></v-divider>
          <div class="footer__legal-links d-flex align-center mt-5">
            <ul class="legal-links color-gray">
              <li class="legal-links__item color-gray">Siemens Medical Solutions USA, Inc. &copy;
                {{ new Date().getFullYear() }}</li>
              <li class="legal-links__item">
                <a class="legal-links__link" target="_blank"
                   href="https://www.siemens-healthineers.com/en-us/corporate-info">Corporate Information</a>
              </li>
              <li class="legal-links__item">
                <a class="legal-links__link" target="_blank"
                   href="https://www.siemens-healthineers.com/en-us/siemens-website-privacy-policy">Privacy Policy</a>
              </li>
              <li class="legal-links__item">
                <a class="legal-links__link" target="_blank"
                   href="https://www.siemens-healthineers.com/cookie">Cookie Policy</a>
              </li>
              <li class="legal-links__item">
                <a class="legal-links__link" target="_blank"
                   href="https://www.siemens-healthineers.com/en-us/terms-of-use">Terms of Use</a>
              </li>
            </ul>
            <v-spacer></v-spacer>
            <div class="color-gray text-smaller text-sm-right">
              <span v-if="version">Version: {{ version }}</span>
              <span class="footer-credits">powered by
                <a href=" https://gms.siemens.cloud/" target="_blank">/GMS/</a>
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <Notification v-if="isUserLoggedIn"/>
  </v-footer>
</template>

<script>
import packageInfo from '../../package.json';
import Notification from './misc/Notification.vue';

export default {
  name: 'ShsFooter',
  components: { Notification },
  props: {
    fullFooter: Boolean,
  },
  data() {
    const gitHash = process.env.VUE_APP_GIT_HASH;
    const fullVersion = gitHash ? `${packageInfo.version}@${gitHash}` : `${packageInfo.version}`;
    return {
      version: fullVersion,
    };
  },
  computed: {
    isUserLoggedIn() {
      return !!this.$store.getters.loggedInUser.id;
    },
  },
  methods: {
    sendMailContact() {
      window.location = 'mailto:dxpricing-escalations.team@siemens-healthineers.com';
    },
    sendMailGood() {
      window.location = 'mailto:dxpricing-escalations.team@siemens-healthineers.com?subject=What I like about Pricing Escalation App';
    },
    sendMailBad() {
      window.location = 'mailto:dxpricing-escalations.team@siemens-healthineers.com?subject=I have a proposal for Pricing Escalation App';
    },
  },
};
</script>

<style lang="scss">
  .v-application {
    .legal-links.no-divider .legal-links__item:after {
      content: '';
      margin: 0;
    }
    .footer__legal-links {
      flex-direction: column;
      > span {
        line-height: 2;
      }
    }
    // desktop, tablet
    @media screen and (min-width: 600px) {
      .footer__legal-links {
        flex-direction: row;
      }
    }
    .legal-links {
      list-style-type:none;
      margin:0;
      padding-left:0;
      font-size:.8em;
    }
    .legal-links {
      &__item {
        display: inline-block;
        margin: 0;
        a {
          text-decoration: none;
        }
        a:hover, a:active {
          box-shadow: none;
          text-decoration: none;
        }
      }
      &__item:after {
        display: inline-block;
        content: "|";
        margin: 0 .2em;
      }
      &__item:last-of-type:after {
        display: inline-block;
        content: "";
      }
      &__link {
        color: inherit;
        &:hover {
          color: #1a1a1a;
        }
      }
    }
    .feedback-links a {
      text-decoration: none;
    }

    .footer-credits {
      display: block;
      &  > a {
        text-decoration: none;
        color: #666;
        &:hover, &:active {
          box-shadow: none;
          text-decoration: none;
          color: #000;
        }
      }
    }
  }

</style>
