const isInTime = (periodOfTime) => {
  const currentDate = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
  );
  const minDate = new Date(
    new Date(periodOfTime[0]).getFullYear(),
    new Date(periodOfTime[0]).getMonth(),
    new Date(periodOfTime[0]).getDate(),
  );
  const maxDate = new Date(
    new Date(periodOfTime[1]).getFullYear(),
    new Date(periodOfTime[1]).getMonth(),
    new Date(periodOfTime[1]).getDate(),
  );
  return currentDate >= minDate && currentDate <= maxDate;
};
export default isInTime;
