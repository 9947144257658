// eslint-disable-next-line import/prefer-default-export
const dealFunctions = {
  methods: {
    getLevelData(level) {
      let levelData = null;
      switch (level) {
        case 1:
          levelData = '#c9e7e9';
          break;
        case 2:
          levelData = '#89d0d4';
          break;
        case 3:
          levelData = '#009997';
          break;
        case 4:
          levelData = '#f8b394';
          break;
        case 5:
          levelData = '#ec6602';
          break;
        default:
          levelData = '#c9e7e9';
          break;
      }
      return levelData;
    },
    getInitials(firstName, lastName) {
      return firstName[0].toUpperCase() + lastName[0].toUpperCase();
    },
    getInitialsFull(fullName) {
      let initials = '';
      const name = fullName.split(' ');
      if (name.length > 1) {
        initials = name[0][0] + name[1][0];
      } else {
        // eslint-disable-next-line prefer-destructuring
        initials = name[0][0];
      }
      return initials.toUpperCase();
    },
    getFullNameWithRegion(firstName, lastName, region) {
      let regionText = '';
      if (region) regionText = ` (${region})`;
      return `${firstName} ${lastName}${regionText}`;
    },
  },
  computed: {
    isManuallyChanged() {
      return this.dealData.escalationlevelOverwrite !== this.dealData.escalationlevel;
    },
  },
};

export default dealFunctions;
