<template>
  <v-snackbar
    v-if="getIsNotificationShown"
    :timeout="-1"
    :value="true"
    :color="notificationColor"
    right
    bottom
    class="mb-md-10 mr-md-10"
  >
    <v-container class="esc-notification">
      <v-layout wrap>
        <v-flex xs2 md1>
          <v-layout column align-start>
            <v-icon :color="notificationTextColor">{{ notificationIcon }}</v-icon>
          </v-layout>
        </v-flex>
        <v-flex xs10 md11>
          <v-layout column>
            <p :class="'note_' + notificationTextColor" v-html="notificationMessage"></p>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12>
          <v-layout column align-end>
            <v-icon :color="notificationTextColor" @click="setNotificationShown">mdi-close</v-icon>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>

  </v-snackbar>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Notification',
  computed: {
    ...mapGetters({
      getNotification: 'notification',
      getIsNotificationShown: 'isNotificationShown',
    }),
    notificationIcon() {
      switch (this.getNotification.type) {
        case 'Info':
          return 'mdi-information-outline';
        case 'Warning':
          return 'mdi-alert-outline';
        default:
          return 'mdi-alert-outline';
      }
    },
    notificationColor() {
      return this.getNotification.type.toLowerCase();
    },
    notificationTextColor() {
      return this.getNotification.type.toLowerCase().concat('Text');
    },
    notificationMessage() {
      return this.getNotification.message.replace(/(\r\n|\r|\n)/g, '<br />');
    },
  },
  mounted() {
    this.$store.dispatch('getNotification');
  },
  methods: {
    setNotificationShown() {
      this.$store.commit('setNotificationShownState');
    },
  },
};
</script>
<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
p.note_infoText {
  color: #1a1a1a;
}

p.note_warningText {
  color: #FFFFFF;
}
.esc-notification {
  @media #{map-get($display-breakpoints, 'md-and-up')} {
    min-width: 500px;
  }
}
</style>
