<template>
  <v-app class="shs-app">
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    drawer: false,
  }),
};
</script>

<style lang="scss">
  @import "scss/main";

</style>
