<template>
  <div class="people-level1-avatar">
    <!-- LD-->
    <v-avatar v-if="creatorFull"
              color="#EEEEEE"
              size="40"
              class="mr-1"
    >{{ creatorFull[0].initials }}
    </v-avatar>
    <template v-if="salesRep">
      <v-avatar color="#EEEEEE"
                size="40"
                class="mr-1"
      >{{ getInitialsFull(salesRep) }}
      </v-avatar>
    </template>
    <template v-else-if="dealData.salesRep">
      <v-avatar color="#EEEEEE"
                size="40"
                class="mr-1"
      >{{ getInitialsFull(dealData.salesRep) }}
      </v-avatar>
    </template>
    <v-avatar v-if="creatorFull"
              color="#EEEEEE"
              size="40"
              class="mr-1"
    >{{ creatorFull[0].initials }}
    </v-avatar>
    <!-- POC-->
    <template v-if="dealData.quoteType.poc">
      <template v-if="salesRep">
        <v-avatar color="#EEEEEE"
                  size="40"
                  class="mr-1"
        >{{ getInitialsFull(salesRep) }}
        </v-avatar>
      </template>
      <template v-else-if="dealData.salesRep">
        <v-avatar color="#EEEEEE"
                  size="40"
                  class="mr-1"
        >{{ getInitialsFull(dealData.salesRep) }}
        </v-avatar>
      </template>
      <v-avatar v-if="creatorFull"
                color="#EEEEEE"
                size="40"
                class="mr-1"
      >{{ creatorFull[0].initials }}
      </v-avatar>
    </template>
  </div>
</template>

<script>
import dealFunctions from '../../../mixins/DealFunctions';

export default {
  name: 'PeopleLevel1Avatar',
  props: {
    dealData: Object,
    creatorFull: Array,
    salesRep: String,
  },
  mixins: [
    dealFunctions,
  ],
};
</script>
