<template>
  <v-list-item v-if="approverTypeFunctionFullNew.length > 0"
               :class="{ 'no-select': approverTypeFunctionFullNew.length === 1 }">
    <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
      <v-icon v-if="!selectedTypeFunction && approverTypeFunctionFullNew.length !== 1">mdi-account</v-icon>
      <template  v-if="approverTypeFunctionFullNew && selectedTypeFunction && approverTypeFunctionFullNew.length !== 1">
        <span class="black--text">
          {{ selectedTypeFunction.initials }}
        </span>
      </template>
      <template v-else-if="approverTypeFunctionFullNew.length === 1">
        <span class="black--text">
          {{ approverTypeFunctionFullNew[0].initials }}
        </span>
      </template>
    </v-list-item-avatar>
    <v-list-item-content class="py-0">
      <v-list-item-title v-if="approverTypeFunctionFullNew.length > 1">
        <v-select
          :items="approverTypeFunctionFullNew"
          v-model="selectedTypeFunction"
          :rules="approverTypeFunctionRules"
          item-text="fullName"
          item-value="id"
          return-object
          :label="label"
          :disabled="isReadOnly"
          @change="emitApprover"
        >
        </v-select>
      </v-list-item-title>
      <template v-if="approverTypeFunctionFullNew.length === 1">
        <v-list-item-subtitle>{{ label }}</v-list-item-subtitle>
        <v-list-item-title>
          {{ approverTypeFunctionFullNew[0].firstName }}
          {{ approverTypeFunctionFullNew[0].lastName }}
        </v-list-item-title>
      </template>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'PeopleApprover',
  props: {
    approverTypeFunctionFullNew: Array,
    dealType: String,
    dealFunction: String,
    selectedFromBe: Array,
  },
  data() {
    return {
      approverTypeFunctionRules: [
        (v) => !!v || '',
      ],
      selectedTypeFunction: [],
      label: '',
    };
  },
  mounted() {
    this.updateSelectedFromBe();
    switch (this.dealFunction) {
      case 'VERIFIER':
        this.label = `${this.dealType} Verifier*`;
        break;
      case 'SALES':
        this.label = `${this.dealType} Sales*`;
        break;
      case 'FINANCE':
        this.label = `${this.dealType} Finance*`;
        break;
      default:
        this.label = '';
        break;
    }
  },
  methods: {
    updateSelectedFromBe() {
      this.$nextTick(() => {
        if (this.selectedFromBe) {
          // console.log('this.selectedFromBe', this.selectedFromBe[0]);
          // eslint-disable-next-line prefer-destructuring
          this.selectedTypeFunction = this.selectedFromBe[0];
        }
      });
    },
    emitApprover() {
      const data = [this.dealFunction, this.dealType, this.selectedTypeFunction.id];
      // console.log('emitted data', data);
      this.$emit('emitApprover', data);
    },
    emitFormValid(val) {
      this.$emit('peopleFormValid', val);
    },
  },
  computed: {
    isReadOnly() {
      return this.$store.state.userReadOnly;
    },
  },
  watch: {
    selectedTypeFunction(val) {
      if (val) this.emitFormValid(val);
    },
    selectedFromBe(val) {
      if (val && this.approverTypeFunctionFullNew.length !== 1) this.updateSelectedFromBe();
    },
  },
};
</script>

<style lang="scss">
  .no-select {
    padding-top: 7px;
    padding-bottom: 7px;
  }
</style>
