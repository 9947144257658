<template>
  <v-row class="flex-nowrap">
    <v-col class="lanes lanes__new">
      <div class="lanes__header text-center">
        <div class="spacer"></div>
        <div class="spacer"></div>
      </div>
      <div class="lane" id="new_deals">
        <div class="lane__header text-center mb-7">
          <h4>New Deals</h4>
        </div>
        <div class="lane__content">
          <slot name="content-new"></slot>
        </div>
      </div>
    </v-col>
    <v-col class="lanes lanes__inprogress ld" v-if="isExpandedLd">
      <div class="lanes__header text-center">
        <h5 class="shs-grey-dark">LD: In Progress</h5>
        <v-btn text @click="toggleElement('ld')" class="shs-toggle">
          <v-icon
            left
            color="orange darken-3"
          >mdi-arrow-left</v-icon>
          Close
        </v-btn>
      </div>
      <v-divider></v-divider>
      <v-row class="flex-nowrap">
        <v-col>
          <div class="lane" id="verifier_ld">
            <div class="lane__header text-center mb-7 mt-3">
              <h4>Verifier</h4>
            </div>
            <div class="lane__content">
              <slot name="content-ld-verifier"></slot>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="lane" id="sales_ld">
            <div class="lane__header text-center mb-7 mt-3">
              <h4>Sales Approver</h4>
            </div>
            <div class="lane__content">
              <slot name="content-ld-sales"></slot>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="lane" id="finance_ld">
            <div class="lane__header text-center mb-7 mt-3">
              <h4>Finance Approver</h4>
            </div>
            <div class="lane__content">
              <slot name="content-ld-finance"></slot>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="lanes lanes__inprogress ld collapsed" v-if="!isExpandedLd">
      <div class="lanes__header text-center mb-10">
        <h5 class="shs-grey-dark">LD</h5>
        <v-btn text @click="toggleElement('ld')" class="shs-toggle">
          <v-icon
            left
            color="orange darken-3"
          >mdi-arrow-right</v-icon>
          Expand
        </v-btn>
      </div>
      <v-row class="flex-nowrap clickableElement" no-gutters @click="toggleElement('ld')">
        <v-col>
          <div class="lane">
            <div class="lane__header mb-10">
            </div>
            <div class="lane__content">
              <v-card outlined tile class="mr-2" height="450">
                <v-card-text class="vertical-text subtitle-2">
                  Verifier ({{ numberVerifierLd }} Deals)
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="lane">
            <div class="lane__header mb-10">
            </div>
            <div class="lane__content">
              <v-card outlined tile class="mr-2" height="450">
                <v-card-text class="vertical-text subtitle-2">
                  Sales Approver ({{ numberSalesLd }} Deals)
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="lane">
            <div class="lane__header mb-10">
            </div>
            <div class="lane__content">
              <v-card outlined tile height="450">
                <v-card-text class="vertical-text subtitle-2">
                  Finance Approver ({{ numberFinanceLd }} Deals)
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="lanes lanes__inprogress poc" v-if="isExpandedPoc">
      <div class="lanes__header text-center">
        <h5 class="shs-grey-dark">POC: In Progress</h5>
        <v-btn text @click="toggleElement('poc')" class="shs-toggle">
          <v-icon
            left
            color="orange darken-3"
          >mdi-arrow-left</v-icon>
          Close
        </v-btn>
      </div>
      <v-divider></v-divider>
      <v-row class="flex-nowrap">
        <v-col>
          <div class="lane" id="verifier_poc">
            <div class="lane__header text-center mb-7 mt-3">
              <h4>Verifier</h4>
            </div>
            <div class="lane__content">
              <slot name="content-poc-verifier"></slot>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="lane" id="sales_poc">
            <div class="lane__header text-center mb-7 mt-3">
              <h4>Sales Approver</h4>
            </div>
            <div class="lane__content">
              <slot name="content-poc-sales"></slot>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="lane" id="finance_poc">
            <div class="lane__header text-center mb-7 mt-3">
              <h4>Finance Approver</h4>
            </div>
            <div class="lane__content">
              <slot name="content-poc-finance"></slot>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="lanes lanes__inprogress poc collapsed" v-if="!isExpandedPoc">
      <div class="lanes__header text-center mb-10">
        <h5 class="shs-grey-dark">POC</h5>
        <v-btn text @click="toggleElement('poc')" class="shs-toggle">
          <v-icon
            left
            color="orange darken-3"
          >mdi-arrow-right</v-icon>
          Expand
        </v-btn>
      </div>
      <v-row class="flex-nowrap clickableElement" no-gutters @click="toggleElement('poc')">
        <v-col>
          <div class="lane">
            <div class="lane__header mb-10">
            </div>
            <div class="lane__content">
              <v-card outlined tile class="mr-2" height="450">
                <v-card-text class="vertical-text subtitle-2">
                  Verifier ({{ numberVerifierPoc }} Deals)
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="lane">
            <div class="lane__header mb-10">
            </div>
            <div class="lane__content">
              <v-card outlined tile class="mr-2" height="450">
                <v-card-text class="vertical-text subtitle-2">
                  Sales Approver ({{ numberSalesPoc }} Deals)
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
        <v-col>
          <div class="lane">
            <div class="lane__header mb-10">
            </div>
            <div class="lane__content">
              <v-card outlined tile height="450">
                <v-card-text class="vertical-text subtitle-2">
                  Finance Approver ({{ numberFinancePoc }} Deals)
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-col class="lanes lanes__done">
      <div class="lanes__header">
        <div class="spacer"></div>
        <div class="spacer"></div>
      </div>
      <div class="lane" id="done">
        <div class="lane__header text-center mb-7">
          <h4>Done</h4>
        </div>
        <div class="lane__content">
          <slot name="content-done"></slot>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'DealLanes',
  props: {
    numberVerifierLd: Number,
    numberFinanceLd: Number,
    numberSalesLd: Number,
    numberVerifierPoc: Number,
    numberFinancePoc: Number,
    numberSalesPoc: Number,
  },
  data() {
    return {
      isExpandedLd: true,
      isExpandedPoc: false,
    };
  },
  mounted() {
    if (this.$store.state.jumpTarget) {
      setTimeout(() => {
        this.jumpToElement(this.$store.state.jumpTarget);
      }, 400);
    }
  },
  methods: {
    toggleElement(el) {
      if (el === 'poc') {
        this.isExpandedPoc = !this.isExpandedPoc;
      } else if (el === 'ld') {
        this.isExpandedLd = !this.isExpandedLd;
      }
    },
    jumpToElement(el) {
      const domEl = document.getElementById(el.substr(1));
      if (domEl) domEl.scrollIntoView(true);
      // fix for iphone scrolling bug
      const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
      if (iPhone) {
        const scrollContainer = document.getElementsByClassName('container')[0];
        const whiteRoom = window.innerWidth - 258;
        console.log('on iphone, scrolling back', whiteRoom / 2);
        scrollContainer.scrollBy(whiteRoom / 2, 0);
      }
    },
    getJumpTarget(perms) {
      if (perms.includes('LD') || perms.includes('POC')) {
        if (perms.includes('LD')) {
          if (perms.includes('VERIFIER')) return '#verifier_ld';
          if (perms.includes('FINANCE')) return '#finance_ld';
          if (perms.includes('SALES')) return '#sales_ld';
        } else if (perms.includes('POC')) {
          if (perms.includes('VERIFIER')) return '#verifier_poc';
          if (perms.includes('FINANCE')) return '#finance_poc';
          if (perms.includes('SALES')) return '#sales_poc';
        }
      }
      return null;
    },
  },
  computed: {
    hasItemsLd() {
      return (this.numberVerifierLd > 0 || this.numberSalesLd > 0 || this.numberFinanceLd > 0);
    },
    hasItemsPoc() {
      return (this.numberVerifierPoc > 0 || this.numberSalesPoc > 0 || this.numberFinancePoc > 0);
    },
    loggedInUser() {
      return this.$store.state.loggedInUser;
    },
  },
  watch: {
    hasItemsLd(val) {
      if (val) this.isExpandedLd = true;
      else this.isExpandedLd = false;
    },
    hasItemsPoc(val) {
      if (val) this.isExpandedPoc = true;
      else this.isExpandedPoc = false;
    },
    loggedInUser(val) {
      if (val) {
        if (this.$vuetify.breakpoint.xs) {
          // console.log('computed user', this.loggedInUser);
          if (this.loggedInUser) {
            let target = null;
            if (this.$store.state.jumpTarget) {
              target = this.$store.state.jumpTarget;
            } else {
              target = this.getJumpTarget(this.loggedInUser.perms);
              if (target) this.$store.commit('setJumpTarget', target);
            }
            // console.log('Jumptarget Neu', target);
            if (target) {
              setTimeout(() => {
                this.jumpToElement(target);
              }, 400);
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
  .lanes {
    &__container {
      // overflow: scroll;
    }
    &__header {
      .shs-toggle {
        font-size: 1.1rem;
      }
    }
    &__inprogress {
      width: calc(3 * 282px);
      max-width: fit-content;
      &.collapsed {
        max-width: fit-content;
        .lane {
          width: auto;
          .vertical-text {
            padding: 8px 4px;
          }
        }
      }
    }
    &__new, &__done, &__poc {
      max-width: 282px;
      width: 282px;
    }
  }
  .lane {
    width: 258px;
  }
  .vertical-text {
    text-transform: uppercase;
    writing-mode: vertical-rl;
    transform:rotate(180deg);
  }
  .lanes__header .spacer {
    height: 40px;
  }
  .shs-grey-dark {
    color: #4D4D4D;
  }
  .scroll-container {
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    .lane {
        scroll-snap-align: center;
      }
    .lanes {
      &.collapsed {
        .lane {
          scroll-snap-align: none;
        }
      }
    }
  }
</style>
