<template>
  <v-snackbar
    :timeout="-1"
    :value="snackbar"
    bottom
    color="error"
    multi-line
  >
    <div class="esc-notification">
      <p class="mb-3">{{ message }}</p>
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn icon
             @click="hideSnackbar"
             v-bind="attrs"
      >
        <v-icon color="white" >mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>

export default {
  name: 'UserNotification',
  emits: 'changeState',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      snackbar: false,
    };
  },
  methods: {
    hideSnackbar(event) {
      event.stopPropagation();
      this.snackbar = false;
      this.$emit('changeState', false);
    },
  },
  watch: {
    visible(val) {
      this.snackbar = val;
    },
  },
};
</script>
<style lang="scss" scoped>

.v-snack .v-snack__wrapper {
  background: hotpink!important;
}
</style>
