<template>
  <div class="archive-view">
    <shs-header
      :full-header="true"
      :is-archive="true"
      :is-user="false"
      v-on:searchFilter="searchDeals($event)"
      v-on:searchCleared="fetchInitialDeals"
    ></shs-header>

    <v-container>
      <v-row>
        <v-col cols="12">
          <div class="lanes__header text-center">
            <div class="spacer"></div>
          </div>
          <h3>Archived Deals</h3>
          <p>{{ searchMessage }}</p>
          <div class="card-container d-flex flex-wrap mt-10">
            <empty-card v-show="!dealsData.length"></empty-card>
            <deal-card class="mr-5"
              v-for="(deal, index) in dealsData"
              :key="index"
              :deal-data="deal"
              v-on:reloadOverview="reloadOverview"
            ></deal-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <router-view/>
    <shs-footer
      :full-footer="true"
    ></shs-footer>
  </div>
</template>

<script>
import ShsHeader from '@/components/Header.vue';
import EmptyCard from '@/components/EmptyCard.vue';
import DealCard from '@/components/DealCard.vue';
import ShsFooter from '@/components/Footer.vue';
import ApiService from '@/services/ApiService';

export default {
  name: 'ArchiveOverview',
  components: {
    EmptyCard,
    DealCard,
    ShsHeader,
    ShsFooter,
  },
  data() {
    return {
      quoteId: null,
      dealsData: {},
      searchTerm: null,
      searchMessage: '',
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'archive' && from.name === 'archivedetails') {
      this.reloadOverview();
    }
    next();
  },
  mounted() {
    this.fetchInitialDeals();
  },
  methods: {
    fetchInitialDeals() {
      this.searchDeals('');
    },
    searchDeals(searchTerm) {
      this.searchTerm = searchTerm;
      // console.log('search filter fired', this.searchTerm);
      ApiService.fetchArchivedDeals(searchTerm).then((response) => {
        if (response) {
          this.dealsData = response.data;
          // console.log('this.dealsData', this.dealsData);
        } else {
          console.log('No data');
        }
      })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    reloadOverview() {
      if (this.searchTerm) {
        this.searchDeals(this.searchTerm);
      } else {
        this.fetchInitialDeals();
      }
    },
  },
  watch: {
    searchTerm(newSearchTerm) {
      this.searchMessage = newSearchTerm === '' ? 'Most recently archived deals' : `Search Result for ${newSearchTerm}`;
    },
  },
};
</script>

<style lang="scss">
  .archive-view {
    .empty-card, .deal-card {
      width: 258px;
    }
  }
</style>
