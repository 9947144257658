<template>
  <div class="ml-3 quote-type">
    <template v-if="dealData.escalationState === 'NEW'">
      <template v-for="(value, name) in dealData.quoteType">
        <v-chip class="mr-1 mb-1"
                label
                :key="name"
                v-if="name === 'rco' && rcoActive"
                @click="toggleRco"
        >
          {{ name.toUpperCase() }}
          <v-icon right small>mdi-close</v-icon>
        </v-chip>
        <v-chip class="mr-1 mb-1"
                label
                outlined
                :key="name"
                v-else-if="name === 'rco' && !rcoActive"
                @click="toggleRco"
        >
          {{ name.toUpperCase() }}
          <v-icon right small>mdi-plus</v-icon>
        </v-chip>
        <v-chip class="mr-1 mb-1"
                label
                :key="name"
                v-else-if="value"
        >
          {{ name.toUpperCase() }}
        </v-chip>
        <v-chip class="mr-1 mb-1"
                label
                outlined
                :key="name"
                v-else
        >
          {{ name.toUpperCase() }}
        </v-chip>
      </template>
    </template>
    <template v-else>
      <template v-for="(value, name) in dealData.quoteType">
        <v-chip class="mr-1 mb-1"
                label
                :key="name"
                v-if="value"
        >
          {{ name.toUpperCase() }}
        </v-chip>
        <v-chip class="mr-1 mb-1"
                label
                outlined
                :key="name"
                v-else
        >
          {{ name.toUpperCase() }}
        </v-chip>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'QuoteTypes',
  props: {
    dealData: Object,
  },
  data() {
    return {
      rcoActive: false,
    };
  },
  mounted() {
    this.setRcoStatus();
  },
  methods: {
    setRcoStatus() {
      if (this.dealData.quoteType.rco) this.rcoActive = true;
    },
    toggleRco() {
      this.rcoActive = !this.rcoActive;
      this.emitRcoStatus();
    },
    emitRcoStatus() {
      this.$emit('changeRcoStatus', this.rcoActive);
    },
  },
};
</script>

<style scoped>

</style>
