<template>
  <v-row align="center" justify="center">
    <v-dialog :fullscreen="$vuetify.breakpoint.mdAndDown"
      v-model="dialog"
      max-width="1280"
    >
      <template slot="default">
        <deal-detail
          :deal-id="parseInt($route.params.id)"
          v-on:closeToParent="closeOverlay"
          v-on:dealDataChanged="showNotification = true"
        ></deal-detail>
      </template>
    </v-dialog>
   <data-changed-notification
     :visible.sync="showNotification"
   ></data-changed-notification>
  </v-row>
</template>

<script>
import DealDetail from './DealDetail.vue';
import DataChangedNotification from '../components/DataChangedNotification.vue';

export default {
  name: 'OverlayDealDetail',
  components: {
    DataChangedNotification,
    DealDetail,
  },
  data() {
    return {
      dialog: false,
      showNotification: false,
    };
  },
  mounted() {
    this.dialog = true;
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.closeOverlay();
      }
    },
  },
  methods: {
    closeOverlay() {
      console.log('..closing');
      this.$router.push({ path: '/board' }).then(() => {
        this.dialog = false;
      }).catch((err) => {
        throw new Error(`Error occurred redirecting to /board: ${err}.`);
      });
    },
  },
};
</script>
