<template>
    <v-row align="center" justify="center" style="height: 100vh" v-if="fullscreen">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="spinnerSize"
      >
      </v-progress-circular>
    </v-row>
    <v-progress-circular
      indeterminate
      color="primary"
      :size="spinnerSize"
      v-else
    >
    </v-progress-circular>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    fullscreen: {
      type: Boolean,
      default: true,
    },
    spinnerSize: {
      type: String,
      default: '64',
    },
  },
};
</script>
