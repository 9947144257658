<template>
  <div class="deal-actions">
    <!-- Comment section-->
    <v-list-item>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-form v-model="commentFormValid">
            <template v-if="this.isVerifierOrNew">
              <v-textarea
                v-model="comment"
                :rules="commentRules"
                required
                name="comment"
                :placeholder="commentPlaceholder"
                auto-grow
                rows="1"
                row-height="15"
                :disabled="isReadOnly"
              ></v-textarea>
            </template>
            <template v-else>
              <v-textarea
                v-model="comment"
                :rules="commentRules"
                name="comment"
                :placeholder="commentPlaceholder"
                auto-grow
                rows="1"
                row-height="15"
                :disabled="isReadOnly"
              ></v-textarea>
            </template>
          </v-form>
        </v-col>
      </v-row>
    </v-list-item>
    <!-- Action Buttons-->
    <v-card-actions class="flex-column flex-md-row"
                    :class="[isVerifierOrNew && isManuallyChanged ? 'align-md-start' : '']"
    >
      <div class="responsive-block d-flex flex-column flex-sm-row align-center mb-md-0 mb-sm-5 mb-0">
        <template v-if="this.dealData.escalationState !== 'NEW'
       && this.dealData.escalationState !== 'DONE' && dealData.escalationState !== 'ARCHIVE'">
          <v-btn
            rounded
            outlined
            :disabled="!commentFormValid || dealData.inquiry || isReadOnly"
            @click="addCommentInquiry"
          >
            <v-icon left color="primary">mdi-forum-outline</v-icon>
            Start Inquiry</v-btn>
          <v-btn
            class="ml-0 ml-sm-3 mt-5 mt-sm-0"
            rounded
            outlined
            :disabled="!commentFormValid || isReadOnly"
            @click="addComment"
          >Add Comment Only</v-btn>
        </template>
        <template v-else>
          <v-btn
            rounded
            outlined
            :disabled="!commentFormValid || isReadOnly"
            @click="addComment"
          >Add Comment Only</v-btn>
        </template>
        <template v-if="dealData.escalationState === 'NEW'">
          <v-btn
            class="ml-0 ml-sm-3 mt-5 mt-sm-0"
            rounded
            outlined
            :class="{ 'btn-confirm': btnClickedDelete }"
            :disabled="!commentFormValid || isReadOnly"
            @click.native.prevent.stop="emitDelete"
          >
            <span :class="{ 'dp-none': btnClickedDelete }">
              Delete Deal
            </span>
            <span :class="[btnClickedDelete ? 'dp-flex': 'dp-none']">Confirm</span>
          </v-btn>
        </template>
        <template v-else-if="dealData.escalationState === 'DONE'
       || dealData.escalationState === 'ARCHIVE'">
        </template>
        <template v-else>
          <v-btn
            class="ml-0 ml-sm-3 mt-5 mt-sm-0"
            rounded
            outlined
            :class="{ 'btn-confirm': btnClickedCancel }"
            :disabled="!commentFormValid || isReadOnly"
            @click.native.prevent.stop="emitCancel"
          >
            <span :class="{ 'dp-none': btnClickedCancel }">
              Cancel Deal
            </span>
            <span :class="[btnClickedCancel ? 'dp-flex': 'dp-none']">Confirm</span>
          </v-btn>
        </template>
      </div>
      <v-spacer></v-spacer>
      <div class="responsive-block d-flex flex-column flex-sm-row"
           :class="[isVerifierOrNew && isManuallyChanged ? 'align-md-start' : 'align-center']"
      >
        <template v-if="isVerifierOrNew">
          <div class="text-center mb-sm-0 mb-5 mr-0 my-sm-0 mt-sm-0 mt-10">
            <!-- Level -->
            <v-chip
              class=""
              label
              :class="[level === 3 || level === 5 ? 'text--white' : '',
             this.isManuallyChanged ? `border--dotted level--${level}` : '']"
              :color="getLevelData(level)"
              :disabled="isReadOnly"
              @click="showLevelMenu = !showLevelMenu"
            >
              Level {{ level }}
            </v-chip>
            <v-menu v-model="showLevelMenu" offset-y>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mr-md-3 mb-sm-0 mr-0 mb-0"
                  icon
                  text
                  v-on="on"
                  :disabled="isReadOnly"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="n in 5"
                  :key=n
                  @click="emitChangeLevel(n)"
                >
                  <v-chip
                    label
                    :class="[n === 3 || n === 5 ? 'text--white' : '',
                   dealData.escalationlevel === n ? '' : `border--dotted level--${n}`]"
                    :color="getLevelData(n)"
                  >
                    Level {{ n }}</v-chip>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
        <template v-else>
          <!-- Level -->
          <v-chip
            class="mb-sm-0 mb-5 mr-sm-5 mr-0 my-sm-0 mt-sm-0 mt-10"
            label
            :class="[level === 3 || level === 5 ? 'text--white' : '',
             this.isManuallyChanged ? `border--dotted level--${level}` : '']"
            :color="getLevelData(level)"
          >
            Level {{ level }}
          </v-chip>
        </template>
        <template v-if="this.dealData.escalationState !== 'NEW'
       && this.dealData.escalationState !== 'DONE' && dealData.escalationState !== 'ARCHIVE'">
          <v-btn
            class="mr-sm-3 mb-sm-0 mr-0 mb-5"
            rounded
            outlined
            :class="{ 'btn-confirm': btnClickedDecline }"
            :disabled="!commentFormValid || isReadOnly"
            @click.native.prevent.stop="emitDecline"
          >
            <span :class="{ 'dp-none': btnClickedDecline }">
              <v-icon left>mdi-close</v-icon>Decline
            </span>
            <span :class="[btnClickedDecline ? 'dp-flex': 'dp-none']">Confirm</span>
          </v-btn>
        </template>
        <span class="flex-column">
        <template v-if="this.dealData.escalationState === 'NEW'">
            <v-btn
              class="start-escalation"
              rounded
              color="primary"
              depressed
              :class="{ 'btn-confirm': btnClickedApprove }"
              :disabled="!isFormValid || isReadOnly"
              @click.native.prevent.stop="emitApprove"
            >
              <span :class="{ 'dp-none': btnClickedApprove }">
                <v-icon left>mdi-check</v-icon>
                <template v-if="dealData.escalationlevelOverwrite === 1">Done</template>
                <template v-else>Start Escalation</template>
              </span>
              <span :class="[btnClickedApprove ? 'dp-flex': 'dp-none']">Confirm</span>
            </v-btn>
        </template>
        <template v-else-if="dealData.escalationState === 'DONE'">
          <v-btn rounded
                 outlined
                 depressed
                 :disabled="isReadOnly"
                 @click="sendToArchive"
          >
            <v-icon
              size="20"
              left
              color="#ec6602"
            >mdi-archive</v-icon>
            Archive Deal
          </v-btn>
        </template>
        <template v-else-if="dealData.escalationState === 'ARCHIVE'">
          <!--<v-btn rounded
                 outlined
                 depressed
                 @click="deleteDeal"
          >
            <v-icon
              size="20"
              left
              color="#ec6602"
            >mdi-delete</v-icon>
            Delete deal
          </v-btn>-->
          <v-btn
            rounded
            outlined
            depressed
            :disabled="isReadOnly"
            @click="sendBackToDone"
          >
            <v-icon
              size="20"
              left
              color="#EC6602"
            >mdi-undo</v-icon>
            Restore To Board
          </v-btn>
        </template>
        <template v-else>
          <v-btn
            rounded
            color="primary"
            depressed
            :class="{ 'btn-confirm': btnClickedApprove }"
            :disabled="!isFormValid || isReadOnly"
            @click.native.prevent.stop="emitApprove"
          >
            <span :class="{ 'dp-none': btnClickedApprove }">
              <v-icon left>mdi-check</v-icon>Approve
            </span>
            <span :class="[btnClickedApprove ? 'dp-flex': 'dp-none']">Confirm</span>
          </v-btn>
        </template>
        <p v-if="isVerifierOrNew && isManuallyChanged"
           class="text-center text-smaller text--primary mb-0 mt-1">
          Manually set level
        </p>
        </span>
      </div>
    </v-card-actions>
    <div class="row justify-end px-6">
      <div class="col-12 col-md-3 pl-0 py-1 text-center text-md-left">
        <ul class="shs-list">
          <li>
            <v-icon size="20">{{ getViolationIcon(dealData.numberOfVcaiViolations) }}</v-icon>
            {{ dealData.numberOfVcaiViolations }}
            | VCAI Violations
          </li>
          <li>
            <v-icon size="20">{{ getViolationIcon(dealData.numberOfComponentPricesViolations) }}</v-icon>
            {{ dealData.numberOfComponentPricesViolations }}
            | Floor Price Violations
          </li>
          <li>
            <v-icon size="20">{{ getViolationIcon(dealData.numberOfValueAddsViolations) }}</v-icon>
            {{ dealData.numberOfValueAddsViolations }}
            | Value Adds Violations
          </li>
        </ul>
      </div>
    </div>
    <template v-if="this.dealData.escalationlevelOverwrite === 5">
      <div class="d-flex v-alert elevation-2 mx-4 mt-8 mb-10">
        <v-icon size="24" color="#FFD200" class="mr-4">mdi-alert-circle</v-icon>
        <p class="mb-0">Deals with Escalation Level 5 are processed with the escalation workflow of
          Level 4 deals. After this, the approval for Level 5 must be triggered manually via e-mail
          by sending the deal link to the respective Level 5 approvers.
        </p>
      </div>
    </template>
    <template v-if="this.dealData.escalationState === 'NEW' && this.showLevelComboDeals">
      <div class="d-flex v-alert elevation-2 mx-4 mt-8 mb-10">
        <v-icon size="24" color="#FFD200" class="mr-4">mdi-alert-circle</v-icon>
        <p class="mb-0">The Escalation Level has been changed. Please make sure that all combined
          deals listed above have the same Escalation Level.
        </p>
      </div>
    </template>
    <template v-if="isVerifier && this.showLevelComboDeals">
      <div class="d-flex v-alert elevation-2 mx-4 mt-8 mb-10">
        <v-icon size="24" color="#FFD200" class="mr-4">mdi-alert-circle</v-icon>
        <p class="mb-0">Combined Deal with differing Escalation Level. Be aware that all combined
          deals listed above must have the same Escalation Level.
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import dealFunctions from '../../mixins/DealFunctions';

export default {
  name: 'DealActions',
  mixins: [
    dealFunctions,
  ],
  props: {
    dealData: Object,
    formsValid: Boolean,
    resetConfirm: Boolean,
    showLevelComboDeals: Boolean,
  },
  data() {
    return {
      commentFormValid: true,
      comment: '',
      commentRules: [
        (v) => !!v || '',
      ],
      commentPlaceholder: 'Add your comment here. Mandatory for starting an inquiry, declining, cancelling and manual level change.',
      showLevelMenu: false,
      btnClickedApprove: false,
      btnConfirmedApprove: false,
      btnClickedDelete: false,
      btnConfirmedDelete: false,
      btnClickedCancel: false,
      btnConfirmedCancel: false,
      btnClickedDecline: false,
      btnConfirmedDecline: false,
    };
  },
  mounted() {
    if (this.dealData.escalationState === 'NEW') this.commentPlaceholder = 'Add your comment here. Mandatory for declining, deleting and manual level change.';
  },
  methods: {
    sendToArchive() {
      const data = { comment: '', rowVersion: this.dealData.rowVersion };
      ApiService.setEscalationState(this.dealData.id, 'ARCHIVED', JSON.stringify(data)).then((response) => {
        if (response) {
          this.emitClose();
        }
      }).catch((error) => console.log(error));
    },
    sendBackToDone() {
      const data = { comment: '', rowVersion: this.dealData.rowVersion };
      ApiService.setEscalationState(this.dealData.id, 'BACK_TO_DONE', JSON.stringify(data)).then((response) => {
        if (response) {
          console.log('deal back in done', response.data);
          this.emitClose();
        }
      }).catch((error) => console.log(error));
    },
    deleteDeal() {
      const data = { comment: '', rowVersion: this.dealData.rowVersion };
      ApiService.setEscalationState(this.dealData.id, 'TRASH', JSON.stringify(data)).then((response) => {
        if (response) {
          this.emitClose();
        }
      }).catch((error) => console.log(error));
    },
    emitClose() {
      this.$emit('closeToParent');
    },
    emitCancel() {
      if (this.btnClickedCancel) {
        const data = { comment: this.comment, rowVersion: this.dealData.rowVersion };
        this.$emit('cancelDeal', data);
      } else {
        this.btnClickedCancel = true;
        this.emitConfirmPending();
      }
    },
    emitDecline() {
      if (this.btnClickedDecline) {
        const data = { comment: this.comment, rowVersion: this.dealData.rowVersion };
        this.$emit('declineDeal', data);
      } else {
        this.btnClickedDecline = true;
        this.emitConfirmPending();
      }
    },
    emitDelete() {
      if (this.btnClickedDelete) {
        const data = { comment: this.comment, rowVersion: this.dealData.rowVersion };
        this.$emit('deleteDeal', data);
      } else {
        this.btnClickedDelete = true;
        this.emitConfirmPending();
      }
    },
    emitApprove() {
      if (this.btnClickedApprove) {
        const data = { comment: this.comment, rowVersion: this.dealData.rowVersion };
        this.$emit('approveDeal', data);
      } else {
        this.btnClickedApprove = true;
        this.emitConfirmPending();
      }
    },
    emitConfirmPending() {
      this.$emit('confirmationPending');
    },
    emitComment() {
      this.$emit('commentAdded');
    },
    emitInquiry() {
      this.$emit('inquiryAdded');
    },
    addComment() {
      // console.log('New comment', this.comment);
      const data = { comment: this.comment, query: '', rowVersion: this.dealData.rowVersion };
      ApiService.setDealComment(this.dealData.id, JSON.stringify(data)).then((response) => {
        if (response) {
          this.emitComment();
          this.comment = '';
        }
      }).catch((error) => console.log(error));
    },
    addCommentInquiry() {
      console.log('New Inquiry', this.comment);
      const data = { comment: '', query: this.comment, rowVersion: this.dealData.rowVersion };
      ApiService.setDealComment(this.dealData.id, JSON.stringify(data)).then((response) => {
        if (response) {
          this.emitInquiry();
          this.comment = '';
        }
      }).catch((error) => console.log(error));
    },
    emitChangeLevel(level) {
      // console.log('Emitted: Level changed to', level);
      this.$emit('changedLevel', level);
    },
    getViolationIcon(violationNumber) {
      if (violationNumber === 0) {
        return 'mdi-check';
      }
      return 'mdi-alert-outline';
    },
  },
  computed: {
    level() {
      return this.dealData.escalationlevelOverwrite;
    },
    isVerifier() {
      return (this.dealData.escalationState === 'VERIFIER_LD' || this.dealData.escalationState === 'VERIFIER_POC');
    },
    isVerifierOrNew() {
      return (this.dealData.escalationState === 'NEW' || this.dealData.escalationState === 'VERIFIER_LD' || this.dealData.escalationState === 'VERIFIER_POC');
    },
    isFormValid() {
      let result = false;
      if (this.dealData.escalationState === 'NEW') {
        result = this.formsValid;
        if (this.isManuallyChanged) result = this.formsValid && this.commentFormValid;
      } else if (this.isVerifier) {
        if (!this.formsValid) {
          // if approvalAlreadyObtained is set, a comment is mandatory
          result = this.commentFormValid;
        } else result = true;
        if (this.$store.state.levelChanged) {
          result = this.formsValid;
        }
      } else result = true;
      return result;
    },
    isReadOnly() {
      return this.$store.state.userReadOnly;
    },
  },
  watch: {
    level(val) {
      console.log('level changed to', val);
    },
    resetConfirm(val) {
      console.log('this.resetConfirm watcher', val);
      this.btnClickedApprove = false;
      this.btnClickedDelete = false;
      this.btnClickedCancel = false;
      this.btnClickedDecline = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  // mobile
  .v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
    margin-left: 0;
  }
  // desktop, tablet
  @media screen and (min-width: 600px) {
    .v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
      margin-left: 8px;
    }
  }
  .row {
    margin: 0 -12px !important;
  }
</style>
