<template>
  <div class="deal-card mb-6">
      <v-card
        class="pa-4 pt-7"
        outlined
        tile
        @click="showDetails(dealData.quoteId)"
      >
        <done-labels class="mb-2"
                     :dealData="dealData"
                     :is-detail-view="false"
                     v-on:reloadOverview="emitReload"
        ></done-labels>
        <v-card-text class="pb-0">{{ dealData.timestamp | toDate }}</v-card-text>
        <v-card-title class="pb-0">{{ dealData.customerData.accountName }}</v-card-title>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle class="mb-2">
              {{ dealData.customerData.accountCity }},
              {{ dealData.customerData.accountState }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="mb-2">
              Sold to {{ dealData.customerData.soldToNumber }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="mt-2">
              {{ dealData.quoteNumber }}-{{ dealData.revisionNumber }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <!-- Quote type -->
        <div class="ml-3 quote-type">
          <template v-for="(value, name) in dealData.quoteType">
            <v-chip class="mr-1 mb-1"
                    label
                    :key="name"
                    v-if="value"
            >
              {{ name.toUpperCase() }}
            </v-chip>
            <v-chip class="mr-1 mb-1"
                    label
                    outlined
                    :key="name"
                    v-else
            >
              {{ name.toUpperCase() }}
            </v-chip>
          </template>
        </div>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>{{ dealData.numberOfVcaiViolations }}
              | VCAI Violations</v-list-item-subtitle>
            <v-list-item-subtitle>{{ dealData.numberOfComponentPricesViolations }}
              | Floor Price Violations</v-list-item-subtitle>
            <v-list-item-subtitle>{{ dealData.numberOfValueAddsViolations }}
              | Value Adds Violations</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content class="pt-0">
            <v-list-item-title>
              TCO Spend <strong>{{ dealData.price | toCurrency }}</strong>
            </v-list-item-title>
            <v-list-item-title v-if="dealData.tcoTotal">
              TCO Total <strong>{{ dealData.tcoTotal | toCurrency }}</strong>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-chip-group class="ml-3" column>
          <template v-if="allApproverFull">
            <template v-for="(approver, index) in allApproverFull">
              <v-avatar v-if="approver[0]"
                        color="#EEEEEE"
                        size="40"
                        class="mr-1 mb-2"
                        :class="{ 'is-current': index === currentApprover,
                         'is-done': index < currentApprover }"
                        :key="index"
              >
                {{ approver[0].initials }}
              </v-avatar>
            </template>
          </template>
          <template v-if="dealData.escalationState &&
           dealData.escalationState === 'DONE' || dealData.escalationState === 'ARCHIVE'
           && dealData.escalationlevelOverwrite === 1">
            <template v-if="dealData.salesRep">
              <v-avatar color="#EEEEEE"
                        size="40"
                        class="mr-1 mb-2 is-done"
              >{{ getInitialsFull(dealData.salesRep) }}
              </v-avatar>
            </template>
            <v-avatar v-if="creatorFull"
                      color="#EEEEEE"
                      size="40"
                      class="mr-1 mb-2 is-done"
            >{{ creatorFull[0].initials }}
            </v-avatar>
          </template>
        </v-chip-group>
        <v-row class="ml-3 mb-2 mt-1">
          <v-icon left>mdi-comment</v-icon>
          <span class="text-small">{{ dealData.numberOfComments }} Comments</span>
        </v-row>
      </v-card>
  </div>
</template>

<script>
import dealFunctions from '../mixins/DealFunctions';
import DoneLabels from './misc/DoneLabels.vue';

export default {
  name: 'DealCard',
  components: {
    DoneLabels,
  },
  mixins: [
    dealFunctions,
  ],
  props: {
    dealId: String,
    dealData: Object,
  },
  data() {
    return {
      rcoActive: false,
      currentApprover: 0,
      doneApprover: false,
    };
  },
  mounted() {
    if (this.dealData.quoteType) {
      this.setRcoStatus();
    }
    this.setCurrentApprover();
  },
  methods: {
    emitReload() {
      this.$emit('reloadOverview');
    },
    showDetails(dealId) {
      if (this.dealData.escalationState === 'ARCHIVE') {
        this.$router.push({ name: 'archivedetails', params: { id: dealId } });
      } else {
        this.$router.push({ name: 'details', params: { id: dealId } });
      }
    },
    setRcoStatus() {
      if (this.dealData.quoteType.rco) this.rcoActive = true;
    },
    toggleRco() {
      this.rcoActive = !this.rcoActive;
    },
    setCurrentApprover() {
      switch (this.dealData.escalationState) {
        case 'VERIFIER_LD':
          this.currentApprover = 1;
          break;
        case 'SALES_LD':
          this.currentApprover = 2;
          break;
        case 'FINANCE_LD':
          this.currentApprover = 3;
          break;
        case 'VERIFIER_POC':
          this.currentApprover = 4;
          break;
        case 'SALES_POC':
          this.currentApprover = 5;
          break;
        case 'FINANCE_POC':
          this.currentApprover = 6;
          break;
        case 'DONE':
          this.currentApprover = 7;
          break;
        default:
          this.currentApprover = 0;
          break;
      }
    },
  },
  computed: {
    creatorFull() {
      let creator = false;
      if (this.dealData.creator.firstName !== null) {
        creator = [this.dealData.creator].map((item) => ({
          fullName: `${item.firstName} ${item.lastName}`,
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return creator;
    },
    approverLdVerifierFull() {
      let verifierLD = false;
      if (this.dealData.assignedApprover.verifierLD.firstName !== null) {
        verifierLD = [this.dealData.assignedApprover.verifierLD].map((item) => ({
          fullName: `${item.firstName} ${item.lastName}`,
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return verifierLD;
    },
    approverLdSalesFull() {
      let salesApproverLD = false;
      if (this.dealData.assignedApprover.salesApproverLD.firstName !== null) {
        salesApproverLD = [this.dealData.assignedApprover.salesApproverLD].map((item) => ({
          fullName: `${item.firstName} ${item.lastName}`,
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return salesApproverLD;
    },
    approverLdFinanceFull() {
      let financeApproverLD = false;
      if (this.dealData.assignedApprover.financeApproverLD.firstName !== null) {
        financeApproverLD = [this.dealData.assignedApprover.financeApproverLD].map((item) => ({
          fullName: `${item.firstName} ${item.lastName}`,
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return financeApproverLD;
    },
    approverPocVerifierFull() {
      let verifierPOC = false;
      if (this.dealData.assignedApprover.verifierPOC.firstName !== null) {
        verifierPOC = [this.dealData.assignedApprover.verifierPOC].map((item) => ({
          fullName: `${item.firstName} ${item.lastName}`,
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return verifierPOC;
    },
    approverPocSalesFull() {
      let salesApproverPOC = false;
      if (this.dealData.assignedApprover.salesApproverPOC.firstName !== null) {
        salesApproverPOC = [this.dealData.assignedApprover.salesApproverPOC].map((item) => ({
          fullName: `${item.firstName} ${item.lastName}`,
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return salesApproverPOC;
    },
    approverPocFinanceFull() {
      let financeApproverPOC = false;
      if (this.dealData.assignedApprover.financeApproverPOC.firstName !== null) {
        financeApproverPOC = [this.dealData.assignedApprover.financeApproverPOC].map((item) => ({
          fullName: `${item.firstName} ${item.lastName}`,
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return financeApproverPOC;
    },
    allApproverFull() {
      const allApprover = [];
      allApprover.push(this.creatorFull);
      allApprover.push(this.approverLdVerifierFull);
      allApprover.push(this.approverLdSalesFull);
      allApprover.push(this.approverLdFinanceFull);
      allApprover.push(this.approverPocVerifierFull);
      allApprover.push(this.approverPocSalesFull);
      allApprover.push(this.approverPocFinanceFull);
      return allApprover;
    },
  },
};
</script>

<style lang="scss">
  @import "src/scss/cards";
  @import "src/scss/chips";
  .deal-card {
    .v-card__title {
      word-break: normal;
    }
    .v-list-item {
      min-height: 0;
    }
    .quote-type {
      span.mr-1:nth-of-type(3) {
        margin-left: 8px;
      }
    }
    .v-avatar {
      &.is-current {
        border: 1px solid #ec6602 !important;
      }
      &.is-done {
        opacity: .4;
      }
    }
  }
</style>
