<template>
  <div class="people-data pb-5">
    <v-card-title>
      <h5 class="clickableElement" @click="showPeople = !showPeople">People</h5>
      <v-btn icon @click="showPeople = !showPeople" color="primary">
        <v-icon>{{ showPeople ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-chip-group
      v-show="!showPeople"
      class="ml-3 mb-3">
      <template v-if="this.dealData.escalationState === 'NEW' ||
         (this.dealData.escalationState === 'VERIFIER_LD' && this.levelChanged)
          || (this.dealData.escalationState === 'VERIFIER_POC' && this.levelChanged)">
        <template v-if="dealData.escalationlevelOverwrite !== 1">
          <template v-if="allApproverFull">
            <template v-for="(approver, index) in allApproverFull">
              <v-avatar v-if="approver[0]"
                        color="#EEEEEE"
                        size="40"
                        class="mr-1"
                        :key="index"
              >
                {{ approver[0].initials }}
              </v-avatar>
            </template>
          </template>
        </template>
        <template v-else>
          <people-level1-avatar
            :deal-data="dealData"
            :creator-full="creatorFull"
            :sales-rep="salesRep"
          ></people-level1-avatar>
        </template>
      </template>
      <template v-else-if="this.dealData.escalationState === 'DONE' && this.dealData.escalationlevelOverwrite === 1">
        <people-level1-avatar
          :deal-data="dealData"
          :creator-full="creatorFull"
          :sales-rep="salesRep"
        ></people-level1-avatar>
      </template>
      <template v-else>
        <template v-if="allApproverFull">
          <template v-for="(approver, index) in allApproverFull">
            <v-avatar v-if="approver[0]"
                      color="#EEEEEE"
                      size="40"
                      class="mr-1"
                      :key="index"
            >
              {{ approver[0].initials }}
            </v-avatar>
          </template>
        </template>
      </template>
    </v-chip-group>
    <v-expand-transition>
      <div v-show="showPeople">
        <template v-if="this.dealData.escalationState === 'NEW' ||
         (this.dealData.escalationState === 'VERIFIER_LD' && this.levelChanged)">
          <v-form ref="peopleForm"
                  v-model="valid"
          >
          <!-- Q2B -->
          <people-q2b
            :deal-data="dealData"
            :creator-full="creatorFull"
          ></people-q2b>
          <v-row>
            <v-col cols="12" lg="6" class="py-0" v-if="dealData.quoteType.ld">
              <template v-if="dealData.escalationlevelOverwrite !== 1">
                <!-- LD Verifier -->
                <people-approver
                  :approver-type-function-full-new="approverLdVerifierFullNew"
                  :selected-from-be="selectedLdVerifier"
                  deal-type="LD"
                  deal-function="VERIFIER"
                  v-on:emitApprover="setApproverFromChild($event)"
                  v-on:peopleFormValid="isVerifierLdValid($event)"
                ></people-approver>
                <!-- LD Sales Approver -->
                <people-approver
                  :approver-type-function-full-new="approverLdSalesFullNew"
                  :selected-from-be="selectedLdSales"
                  deal-type="LD"
                  deal-function="SALES"
                  v-on:emitApprover="setApproverFromChild($event)"
                  v-on:peopleFormValid="isSalesLdValid($event)"
                ></people-approver>
                <!-- LD Finance Approver -->
                <people-approver
                  :approver-type-function-full-new="approverLdFinanceFullNew"
                  :selected-from-be="selectedLdFinance"
                  deal-type="LD"
                  deal-function="FINANCE"
                  v-on:emitApprover="setApproverFromChild($event)"
                  v-on:peopleFormValid="isFinanceLdValid($event)"
                ></people-approver>
              </template>
              <template v-else>
                <people-level1
                  :deal-data="dealData"
                  :creator-full="creatorFull"
                  :sales-rep="salesRep"
                  deal-type="LD"
                ></people-level1>
              </template>
            </v-col>
            <v-col cols="12" lg="6" class="py-0" v-if="dealData.quoteType.poc">
              <template v-if="dealData.escalationlevelOverwrite !== 1">
                <!-- POC Verifier -->
                <people-approver
                  :approver-type-function-full-new="approverPocVerifierFullNew"
                  :selected-from-be="selectedPocVerifier"
                  deal-type="POC"
                  deal-function="VERIFIER"
                  v-on:emitApprover="setApproverFromChild($event)"
                  v-on:peopleFormValid="isVerifierPocValid($event)"
                ></people-approver>
                <!-- POC Sales Approver -->
                <people-approver
                  :approver-type-function-full-new="approverPocSalesFullNew"
                  :selected-from-be="selectedPocSales"
                  deal-type="POC"
                  deal-function="SALES"
                  v-on:emitApprover="setApproverFromChild($event)"
                  v-on:peopleFormValid="isSalesPocValid($event)"
                ></people-approver>
                <!-- POC Finance Approver -->
                <people-approver
                  :approver-type-function-full-new="approverPocFinanceFullNew"
                  :selected-from-be="selectedPocFinance"
                  deal-type="POC"
                  deal-function="FINANCE"
                  v-on:emitApprover="setApproverFromChild($event)"
                  v-on:peopleFormValid="isFinancePocValid($event)"
                ></people-approver>
              </template>
              <template v-else>
                <people-level1
                  :deal-data="dealData"
                  :creator-full="creatorFull"
                  :sales-rep="salesRep"
                  deal-type="POC"
                ></people-level1>
              </template>
            </v-col>
          </v-row>
          </v-form>
        </template>
        <template v-else-if="this.dealData.escalationState === 'VERIFIER_POC' && this.levelChanged">
          <v-form ref="peopleForm"
                  v-model="valid"
          >
            <!-- Q2B -->
            <v-list-item v-if="creatorFull">
              <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                {{ creatorFull[0].initials }}
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>
                  Q2B
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ dealData.creator.firstName }} {{ dealData.creator.lastName }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-row>
              <v-col cols="12" lg="6" class="py-0" v-if="dealData.quoteType.ld">
                <v-list-item v-if="approverLdVerifierFull">
                  <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                    {{ approverLdVerifierFull[0].initials }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      LD Verifier
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ approverLdVerifierFull[0].fullName }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="approverLdSalesFull">
                  <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                    {{ approverLdSalesFull[0].initials }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      LD Sales
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ approverLdSalesFull[0].fullName }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="approverLdFinanceFull">
                  <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                    {{ approverLdFinanceFull[0].initials }}
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      LD Finance
                    </v-list-item-subtitle>
                    <v-list-item-title>
                      {{ approverLdFinanceFull[0].fullName }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" lg="6" class="py-0" v-if="dealData.quoteType.poc">
                <template v-if="dealData.escalationlevelOverwrite !== 1">
                  <!-- POC Verifier -->
                  <people-approver
                    :approver-type-function-full-new="approverPocVerifierFullNew"
                    :selected-from-be="selectedPocVerifier"
                    deal-type="POC"
                    deal-function="VERIFIER"
                    v-on:emitApprover="setApproverFromChild($event)"
                    v-on:peopleFormValid="isVerifierPocValid($event)"
                  ></people-approver>
                  <!-- POC Sales Approver -->
                  <people-approver
                    :approver-type-function-full-new="approverPocSalesFullNew"
                    :selected-from-be="selectedPocSales"
                    deal-type="POC"
                    deal-function="SALES"
                    v-on:emitApprover="setApproverFromChild($event)"
                    v-on:peopleFormValid="isSalesPocValid($event)"
                  ></people-approver>
                  <!-- POC Finance Approver -->
                  <people-approver
                    :approver-type-function-full-new="approverPocFinanceFullNew"
                    :selected-from-be="selectedPocFinance"
                    deal-type="POC"
                    deal-function="FINANCE"
                    v-on:emitApprover="setApproverFromChild($event)"
                    v-on:peopleFormValid="isFinancePocValid($event)"
                  ></people-approver>
                </template>
                <template v-else>
                  <people-level1
                    :deal-data="dealData"
                    :creator-full="creatorFull"
                    :sales-rep="salesRep"
                    deal-type="POC"
                  ></people-level1>
                </template>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-else-if="this.dealData.escalationState === 'DONE' && this.dealData.escalationlevelOverwrite === 1">
          <!-- Q2B -->
          <people-q2b
            :deal-data="dealData"
            :creator-full="creatorFull"
          ></people-q2b>
          <people-level1
            :deal-data="dealData"
            :creator-full="creatorFull"
            :sales-rep="salesRep"
            deal-type="LD"
          ></people-level1>
          <people-level1 v-if="dealData.quoteType.poc"
            :deal-data="dealData"
            :creator-full="creatorFull"
            :sales-rep="salesRep"
            deal-type="POC"
          ></people-level1>
        </template>
        <template v-else>
          <v-list-item v-if="creatorFull">
            <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
              {{ creatorFull[0].initials }}
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-subtitle>
                Q2B
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ dealData.creator.firstName }} {{ dealData.creator.lastName }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-row>
            <v-col cols="12" lg="6" class="py-0" v-if="dealData.quoteType.ld">
              <v-list-item v-if="approverLdVerifierFull">
                <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                  {{ approverLdVerifierFull[0].initials }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    LD Verifier
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ approverLdVerifierFull[0].fullName }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="approverLdSalesFull">
                <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                  {{ approverLdSalesFull[0].initials }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    LD Sales
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ approverLdSalesFull[0].fullName }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="approverLdFinanceFull">
                <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                  {{ approverLdFinanceFull[0].initials }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    LD Finance
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ approverLdFinanceFull[0].fullName }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" lg="6" class="py-0" v-if="dealData.quoteType.poc">
              <v-list-item v-if="approverPocVerifierFull">
                <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                  {{ approverPocVerifierFull[0].initials }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    POC Verifier
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ approverPocVerifierFull[0].fullName }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="approverPocSalesFull">
                <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                  {{ approverPocSalesFull[0].initials }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    POC Sales
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ approverPocSalesFull[0].fullName }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="approverPocFinanceFull">
                <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                  {{ approverPocFinanceFull[0].initials }}
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    POC Finance
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    {{ approverPocFinanceFull[0].fullName }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </template>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import dealFunctions from '../../mixins/DealFunctions';
import PeopleQ2b from './people/PeopleQ2b.vue';
import PeopleLevel1 from './people/PeopleLevel1.vue';
import PeopleLevel1Avatar from './people/PeopleLevel1Avatar.vue';
import PeopleApprover from './people/PeopleApprover.vue';

export default {
  name: 'PeopleData',
  components: {
    PeopleApprover,
    PeopleLevel1Avatar,
    PeopleLevel1,
    PeopleQ2b,
  },
  props: {
    dealData: Object,
    salesRep: String,
    levelChanged: Boolean,
  },
  mixins: [
    dealFunctions,
  ],
  data() {
    return {
      showPeople: true,
      approverLdVerifier: [],
      selectedLdVerifier: [],
      approverLdSales: [],
      selectedLdSales: [],
      approverLdFinance: [],
      selectedLdFinance: [],
      approverPocVerifier: [],
      selectedPocVerifier: [],
      approverPocSales: [],
      selectedPocSales: [],
      approverPocFinance: [],
      selectedPocFinance: [],
      valid: true,
      verifierLdValid: false,
      salesLdValid: false,
      financeLdValid: false,
      verifierPocValid: false,
      salesPocValid: false,
      financePocValid: false,
      singleApprover: [],
    };
  },
  mounted() {
    if (this.dealData.escalationState === 'NEW') {
      this.getAllApprovers();
      // eslint-disable-next-line prefer-destructuring
      this.selectedLdVerifier = this.approverLdVerifierFull || [];
      this.selectedLdSales = this.approverLdSalesFull || [];
      this.selectedLdFinance = this.approverLdFinanceFull || [];
      this.selectedPocVerifier = this.approverPocVerifierFull || [];
      this.selectedPocSales = this.approverPocSalesFull || [];
      this.selectedPocFinance = this.approverPocFinanceFull || [];
    }
    if (this.dealData.escalationState === 'NEW' && this.dealData.escalationlevelOverwrite === 1
      && this.dealData.salesRep) {
      this.emitFormValid(true);
    }
    if (this.dealData.escalationState !== 'NEW' && this.dealData.escalationState !== 'VERIFIER_LD'
      && this.dealData.escalationState !== 'VERIFIER_POC') this.showPeople = false;
  },
  methods: {
    getAllApprovers() {
      const approverPromises = [];
      if (this.dealData.quoteType.ld) {
        approverPromises.push(this.getApprovers(this.dealData.escalationlevelOverwrite, 'VERIFIER', 'LD'));
        approverPromises.push(this.getApprovers(this.dealData.escalationlevelOverwrite, 'SALES', 'LD'));
        approverPromises.push(this.getApprovers(this.dealData.escalationlevelOverwrite, 'FINANCE', 'LD'));
      }
      if (this.dealData.quoteType.poc) {
        approverPromises.push(this.getApprovers(this.dealData.escalationlevelOverwrite, 'VERIFIER', 'POC'));
        approverPromises.push(this.getApprovers(this.dealData.escalationlevelOverwrite, 'SALES', 'POC'));
        approverPromises.push(this.getApprovers(this.dealData.escalationlevelOverwrite, 'FINANCE', 'POC'));
      }

      Promise.all(approverPromises).then(async () => {
        await this.setSingleApprovers();
      })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.singleApprover = [];
        });
    },
    async setSingleApprovers() {
      for (let index = 0; index < this.singleApprover.length; index += 1) {
        const { shsFunction, productType, userId } = this.singleApprover[index];
        // console.log(`setting approver for user ${userId}`);
        // eslint-disable-next-line no-await-in-loop
        await this.setApprover(shsFunction, productType, userId).then(() => {
          this.setSingleApproverValid(shsFunction, productType);
        });
      }
    },
    setSingleApproverValid(shsFunction, productType) {
      if (productType === 'LD') {
        if (shsFunction === 'VERIFIER') this.verifierLdValid = true;
        else if (shsFunction === 'SALES') this.salesLdValid = true;
        else if (shsFunction === 'FINANCE') this.financeLdValid = true;
      }
      if (productType === 'POC') {
        if (shsFunction === 'VERIFIER') this.verifierPocValid = true;
        else if (shsFunction === 'SALES') this.salesPocValid = true;
        else if (shsFunction === 'FINANCE') this.financePocValid = true;
      }
    },
    getApprovers(level, shsFunction, quoteType) {
      return ApiService.getApprovers(level, shsFunction, quoteType).then((response) => {
        if (response) {
          if (quoteType === 'LD') {
            if (shsFunction === 'VERIFIER') {
              this.approverLdVerifier = this.assignApprover(shsFunction, quoteType, response.data);
            } else if (shsFunction === 'SALES') {
              this.approverLdSales = this.assignApprover(shsFunction, quoteType, response.data);
            } else if (shsFunction === 'FINANCE') {
              this.approverLdFinance = this.assignApprover(shsFunction, quoteType, response.data);
            }
          }
          if (quoteType === 'POC') {
            if (shsFunction === 'VERIFIER') {
              this.approverPocVerifier = this.assignApprover(shsFunction, quoteType, response.data);
            } else if (shsFunction === 'SALES') {
              this.approverPocSales = this.assignApprover(shsFunction, quoteType, response.data);
            } else if (shsFunction === 'FINANCE') {
              this.approverPocFinance = this.assignApprover(shsFunction, quoteType, response.data);
            }
          }
        }
        return response;
      });
    },
    assignApprover(shsFunction, quoteType, approvers) {
      let newList = {};
      if ((this.dealData.escalationlevel >= 3 || this.dealData.escalationlevelOverwrite >= 3) && (shsFunction === 'SALES' || shsFunction === 'FINANCE')) {
        newList = approvers.filter((approver) => !approver.perms.includes('EXCLUDE_FROM_LEVELS'));
      } else {
        newList = approvers;
      }
      if (newList.length === 1) {
        this.singleApprover.push({
          shsFunction,
          productType: quoteType,
          userId: newList[0].id,
        });
      }
      return newList;
    },
    setApproverFromChild(data) {
      // console.log('setApproverFromChild called...', data);
      this.setApprover(data[0], data[1], data[2]);
    },
    setApprover(shsFunction, productType, userId) {
      const formData = {
        shsFunction,
        productType,
        userId,
        rowVersion: this.dealData.rowVersion,
      };
      return ApiService.setApprovers(this.dealData.id, JSON.stringify(formData)).then((response) => {
        if (response) {
          // console.log('response', response.data);
          this.dealData.rowVersion = response.data.rowVersion;
        }
      }).catch((error) => {
        console.log(error);
        if (error.response.status === 409) {
          this.getDetailData(this.dealId);
        }
      });
    },
    emitFormValid(val) {
      this.$emit('changeFormValid', val);
    },
    isVerifierLdValid(approver) {
      if (approver) this.verifierLdValid = true;
    },
    isSalesLdValid(approver) {
      if (approver) this.salesLdValid = true;
    },
    isFinanceLdValid(approver) {
      if (approver) this.financeLdValid = true;
    },
    isVerifierPocValid(approver) {
      if (approver) this.verifierPocValid = true;
    },
    isSalesPocValid(approver) {
      if (approver) this.salesPocValid = true;
    },
    isFinancePocValid(approver) {
      if (approver) this.financePocValid = true;
    },
    resetVerifiers() {
      // console.log('resetting selected verifier, type LD?', this.dealData.quoteType.ld);
      if (this.dealData.quoteType.ld) {
        this.selectedLdFinance = [];
        this.selectedLdSales = [];
        this.selectedLdVerifier = [];
      }
      if (this.dealData.quoteType.poc) {
        this.selectedPocFinance = [];
        this.selectedPocSales = [];
        this.selectedPocVerifier = [];
      }
    },
  },
  computed: {
    creatorFull() {
      let creator = false;
      if (this.dealData.creator.firstName !== null) {
        creator = [this.dealData.creator].map((item) => ({
          fullName: `${item.firstName} ${item.lastName}`,
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return creator;
    },
    approverLdVerifierFullNew() {
      return this.approverLdVerifier.map((item) => ({
        fullName: this.getFullNameWithRegion(item.firstName, item.lastName, item.region),
        initials: this.getInitials(item.firstName, item.lastName),
        ...item,
      }));
    },
    approverLdVerifierFull() {
      let verifierLD = false;
      if (this.dealData.assignedApprover.verifierLD.firstName !== null) {
        verifierLD = [this.dealData.assignedApprover.verifierLD].map((item) => ({
          fullName: this.getFullNameWithRegion(item.firstName, item.lastName, item.region),
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return verifierLD;
    },
    approverLdFinanceFullNew() {
      return this.approverLdFinance.map((item) => ({
        fullName: this.getFullNameWithRegion(item.firstName, item.lastName, item.region),
        initials: this.getInitials(item.firstName, item.lastName),
        ...item,
      }));
    },
    approverLdSalesFull() {
      let salesApproverLD = false;
      if (this.dealData.assignedApprover.salesApproverLD.firstName !== null) {
        salesApproverLD = [this.dealData.assignedApprover.salesApproverLD].map((item) => ({
          fullName: this.getFullNameWithRegion(item.firstName, item.lastName, item.region),
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return salesApproverLD;
    },
    approverLdSalesFullNew() {
      return this.approverLdSales.map((item) => ({
        fullName: this.getFullNameWithRegion(item.firstName, item.lastName, item.region),
        initials: this.getInitials(item.firstName, item.lastName),
        ...item,
      }));
    },
    approverLdFinanceFull() {
      let financeApproverLD = false;
      if (this.dealData.assignedApprover.financeApproverLD.firstName !== null) {
        financeApproverLD = [this.dealData.assignedApprover.financeApproverLD].map((item) => ({
          fullName: this.getFullNameWithRegion(item.firstName, item.lastName, item.region),
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return financeApproverLD;
    },
    approverPocVerifierFullNew() {
      return this.approverPocVerifier.map((item) => ({
        fullName: this.getFullNameWithRegion(item.firstName, item.lastName, item.region),
        initials: this.getInitials(item.firstName, item.lastName),
        ...item,
      }));
    },
    approverPocVerifierFull() {
      let verifierPOC = false;
      if (this.dealData.assignedApprover.verifierPOC.firstName !== null) {
        verifierPOC = [this.dealData.assignedApprover.verifierPOC].map((item) => ({
          fullName: this.getFullNameWithRegion(item.firstName, item.lastName, item.region),
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return verifierPOC;
    },
    approverPocFinanceFullNew() {
      return this.approverPocFinance.map((item) => ({
        fullName: this.getFullNameWithRegion(item.firstName, item.lastName, item.region),
        initials: this.getInitials(item.firstName, item.lastName),
        ...item,
      }));
    },
    approverPocSalesFull() {
      let salesApproverPOC = false;
      if (this.dealData.assignedApprover.salesApproverPOC.firstName !== null) {
        salesApproverPOC = [this.dealData.assignedApprover.salesApproverPOC].map((item) => ({
          fullName: this.getFullNameWithRegion(item.firstName, item.lastName, item.region),
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return salesApproverPOC;
    },
    approverPocSalesFullNew() {
      return this.approverPocSales.map((item) => ({
        fullName: this.getFullNameWithRegion(item.firstName, item.lastName, item.region),
        initials: this.getInitials(item.firstName, item.lastName),
        ...item,
      }));
    },
    approverPocFinanceFull() {
      let financeApproverPOC = false;
      if (this.dealData.assignedApprover.financeApproverPOC.firstName !== null) {
        financeApproverPOC = [this.dealData.assignedApprover.financeApproverPOC].map((item) => ({
          fullName: this.getFullNameWithRegion(item.firstName, item.lastName, item.region),
          initials: this.getInitials(item.firstName, item.lastName),
          ...item,
        }));
      }
      return financeApproverPOC;
    },
    allApproverFullNew() {
      const allApproverNew = [];
      allApproverNew.push(this.creatorFull);
      allApproverNew.push(this.approverLdVerifierFullNew);
      allApproverNew.push(this.approverLdFinanceFullNew);
      allApproverNew.push(this.approverLdSalesFullNew);
      allApproverNew.push(this.approverPocVerifierFullNew);
      allApproverNew.push(this.approverPocFinanceFullNew);
      allApproverNew.push(this.approverPocSalesFullNew);
      return allApproverNew;
    },
    allApproverFull() {
      const allApprover = [];
      allApprover.push(this.creatorFull);
      allApprover.push(this.approverLdVerifierFull);
      allApprover.push(this.approverLdSalesFull);
      allApprover.push(this.approverLdFinanceFull);
      allApprover.push(this.approverPocVerifierFull);
      allApprover.push(this.approverPocSalesFull);
      allApprover.push(this.approverPocFinanceFull);
      return allApprover;
    },
    isFormValid() {
      let response = false;
      if (this.dealData.quoteType.ld && !this.dealData.quoteType.poc) {
        response = this.verifierLdValid && this.salesLdValid && this.financeLdValid;
      } else if (this.dealData.quoteType.ld && this.dealData.quoteType.poc) {
        response = this.verifierLdValid && this.salesLdValid && this.financeLdValid
          && this.verifierPocValid && this.salesPocValid && this.financePocValid;
      } else if (!this.dealData.quoteType.ld && this.dealData.quoteType.poc) {
        response = this.verifierPocValid && this.salesPocValid && this.financePocValid;
      }
      // console.log('peopleData: isFormValid', response);
      return response;
    },
    levelNumberChanged() {
      return this.dealData.escalationlevelOverwrite;
    },
  },
  watch: {
    valid(val) {
      // console.log('Valid changed People', val);
      this.emitFormValid(val);
    },
    salesRep(val) {
      // console.log('Valid changed SalesRep', val);
      if (this.dealData.escalationState === 'NEW'
        && this.dealData.escalationlevelOverwrite === 1) this.emitFormValid(val);
    },
    levelNumberChanged(val) {
      console.log('People-data: Level changed to Level', val);
      this.resetVerifiers();
      this.getAllApprovers();
      if (val === 2) {
        if (this.dealData.quoteType.ld && !this.dealData.quoteType.poc) {
          this.setApprover('VERIFIER', 'LD', 0);
        } else if (!this.dealData.quoteType.ld && this.dealData.quoteType.poc) {
          this.setApprover('VERIFIER', 'POC', 0);
        } else if (this.dealData.quoteType.ld && this.dealData.quoteType.poc) {
          this.setApprover('VERIFIER', 'LD', 0).then(() => {
            this.setApprover('VERIFIER', 'POC', 0);
          });
        }
      }
    },
    isFormValid(val) {
      // console.log('++++ People Form changed, valid?', val);
      this.emitFormValid(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  margin: 0 -12px !important;
}
</style>
