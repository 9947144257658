<template>
  <div class="deals-view">
    <deal-upload
      v-if="files.length"
      :files="files"
      v-on:uploadCompleted="getAllDeals"
      v-on:uploadDialogClosed="resetFiles"
    ></deal-upload>
    <shs-header
      :full-header="true"
      :is-user="false"
      :is-archive="false"
      v-on:searchFilter="searchFilter($event)"
      v-on:getDealsComplete="getDealsComplete"
      v-on:setNoneSelected="setNoneSelected"
      v-on:searchTermNull="setSearchTermNull"
      v-on:getDealsByUser="getDealsByUser($event)"
    ></shs-header>

    <v-container fluid :class="{'overflow-x-auto': true, 'scroll-container': $vuetify.breakpoint.smAndDown}">
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" class="lanes__container pt-12">
              <deal-lanes
                :number-verifier-ld="dealsDataLdVerfifier.length"
                :number-finance-ld="dealsDataLdFinance.length"
                :number-sales-ld="dealsDataLdSales.length"
                :number-verifier-poc="dealsDataPocVerfifier.length"
                :number-finance-poc="dealsDataPocFinance.length"
                :number-sales-poc="dealsDataPocSales.length"
              >
                <template slot="content-new">
                  <empty-card v-show="!dealsDataNew.length"></empty-card>
                  <deal-card
                    v-for="(deal, index) in dealsDataNew"
                    :key="index"
                    :deal-data="deal"
                  ></deal-card>
                </template>
                <template slot="content-ld-verifier">
                  <empty-card v-show="!dealsDataLdVerfifier.length"></empty-card>
                  <deal-card
                    v-for="(deal, index) in dealsDataLdVerfifier"
                    :key="index"
                    :deal-data="deal"
                  ></deal-card>
                </template>
                <template slot="content-ld-sales">
                  <empty-card v-show="!dealsDataLdSales.length"></empty-card>
                  <deal-card
                    v-for="(deal, index) in dealsDataLdSales"
                    :key="index"
                    :deal-data="deal"
                  ></deal-card>
                </template>
                <template slot="content-ld-finance">
                  <empty-card v-show="!dealsDataLdFinance.length"></empty-card>
                  <deal-card
                    v-for="(deal, index) in dealsDataLdFinance"
                    :key="index"
                    :deal-data="deal"
                  ></deal-card>
                </template>
                <template slot="content-poc-verifier">
                  <empty-card v-show="!dealsDataPocVerfifier.length"></empty-card>
                  <deal-card
                    v-for="(deal, index) in dealsDataPocVerfifier"
                    :key="index"
                    :deal-data="deal"
                  ></deal-card>
                </template>
                <template slot="content-poc-sales">
                  <empty-card v-show="!dealsDataPocSales.length"></empty-card>
                  <deal-card
                    v-for="(deal, index) in dealsDataPocSales"
                    :key="index"
                    :deal-data="deal"
                  ></deal-card>
                </template>
                <template slot="content-poc-finance">
                  <empty-card v-show="!dealsDataPocFinance.length"></empty-card>
                  <deal-card
                    v-for="(deal, index) in dealsDataPocFinance"
                    :key="index"
                    :deal-data="deal"
                  ></deal-card>
                </template>
                <template slot="content-done">
                  <empty-card v-show="!dealsDataDone.length"></empty-card>
                  <deal-card
                    v-for="(deal, index) in dealsDataDone"
                    :key="index"
                    :deal-data="deal"
                    v-on:reloadOverview="reloadOverview"
                  ></deal-card>
                </template>
              </deal-lanes>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <router-view/>
    <shs-footer
      :full-footer="true"
    ></shs-footer>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService';
import DealLanes from '../components/DealLanes.vue';
import DealCard from '../components/DealCard.vue';
import EmptyCard from '../components/EmptyCard.vue';
import DealUpload from '../components/DealUpload.vue';
import ShsHeader from '../components/Header.vue';
import ShsFooter from '../components/Footer.vue';
import dealFunctions from '../mixins/DealFunctions';

export default {
  name: 'Index',
  components: {
    ShsHeader,
    DealUpload,
    EmptyCard,
    DealLanes,
    DealCard,
    ShsFooter,
  },
  mixins: [
    dealFunctions,
  ],
  data() {
    return {
      dealId: null,
      dealsData: {},
      dealsDataNew: {},
      dealsDataLdVerfifier: {},
      dealsDataLdSales: {},
      dealsDataLdFinance: {},
      dealsDataPocVerfifier: {},
      dealsDataPocSales: {},
      dealsDataPocFinance: {},
      dealsDataDone: {},
      dragAndDropCapable: false,
      files: [],
      dialog: false,
      selectedUser: {},
      searchTerm: null,
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'overview' && from.name === 'details') {
      // console.log('Overview this.selectedUser', this.selectedUser, 'length', Object.keys(this.selectedUser).length);
      if (this.searchTerm?.length) {
        // console.log('searchterm present');
        next();
      } else if (Object.keys(this.selectedUser).length > 0) this.getDealsByUser(this.selectedUser);
      else if (Object.keys(this.selectedUser).length === 0) this.getDealsComplete();
      else this.getAllDeals();
    }
    next();
  },
  mounted() {
    this.getAllDeals();
  },
  methods: {
    reloadOverview() {
      if (Object.keys(this.selectedUser).length > 0) this.getDealsByUser(this.selectedUser);
      else if (Object.keys(this.selectedUser).length === 0) this.getDealsComplete();
      else this.getAllDeals();
    },
    getAllDeals() {
      console.log('getAllDeals fired');
      ApiService.fetchAllDeals().then((response) => {
        if (response) {
          this.dealsDataNew = this.filterDeals(response.data, 'NEW');
          this.dealsDataLdVerfifier = this.filterDeals(response.data, 'VERIFIER_LD');
          this.dealsDataLdSales = this.filterDeals(response.data, 'SALES_LD');
          this.dealsDataLdFinance = this.filterDeals(response.data, 'FINANCE_LD');
          this.dealsDataPocVerfifier = this.filterDeals(response.data, 'VERIFIER_POC');
          this.dealsDataPocSales = this.filterDeals(response.data, 'SALES_POC');
          this.dealsDataPocFinance = this.filterDeals(response.data, 'FINANCE_POC');
          this.dealsDataDone = this.filterDeals(response.data, 'DONE');
        } else {
          console.log('No data');
        }
      })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    filterDeals(data, filterBy) {
      return data.filter((deal) => deal.escalationState === filterBy);
    },
    resetFiles() {
      this.files = [];
    },
    getDealsByUser(user) {
      // console.log('received user selected', user?.firstName);
      if (user) {
        this.selectedUser = user;
        ApiService.fetchDealsByUser(user.userId).then((response) => {
          if (response) {
            this.dealsDataNew = this.filterDeals(response.data, 'NEW');
            this.dealsDataLdVerfifier = this.filterDeals(response.data, 'VERIFIER_LD');
            this.dealsDataLdSales = this.filterDeals(response.data, 'SALES_LD');
            this.dealsDataLdFinance = this.filterDeals(response.data, 'FINANCE_LD');
            this.dealsDataPocVerfifier = this.filterDeals(response.data, 'VERIFIER_POC');
            this.dealsDataPocSales = this.filterDeals(response.data, 'SALES_POC');
            this.dealsDataPocFinance = this.filterDeals(response.data, 'FINANCE_POC');
            this.dealsDataDone = this.filterDeals(response.data, 'DONE');
          } else {
            console.log('No data');
          }
        })
          .catch((error) => {
            console.log('Error:', error);
          });
      }
    },
    getDealsComplete() {
      console.log('get deals complete');
      // console.log('getDealsComplete/user selected', this.userSelected?.firstName);
      ApiService.fetchingAllDealsComplete().then((response) => {
        if (response) {
          this.dealsData = response.data;
          this.dealsDataNew = this.filterDeals(response.data, 'NEW');
          this.dealsDataLdVerfifier = this.filterDeals(response.data, 'VERIFIER_LD');
          this.dealsDataLdSales = this.filterDeals(response.data, 'SALES_LD');
          this.dealsDataLdFinance = this.filterDeals(response.data, 'FINANCE_LD');
          this.dealsDataPocVerfifier = this.filterDeals(response.data, 'VERIFIER_POC');
          this.dealsDataPocSales = this.filterDeals(response.data, 'SALES_POC');
          this.dealsDataPocFinance = this.filterDeals(response.data, 'FINANCE_POC');
          this.dealsDataDone = this.filterDeals(response.data, 'DONE');
        } else {
          console.log('No data');
        }
      })
        .catch((error) => {
          console.log('Error:', error);
        });
    },
    searchFilter(searchTerm) {
      this.searchTerm = searchTerm;
      // console.log('filteredData', this.searchFilteredData);
      if (this.searchFilteredData) {
        this.dealsDataNew = this.filterDeals(this.searchFilteredData, 'NEW');
        this.dealsDataLdVerfifier = this.filterDeals(this.searchFilteredData, 'VERIFIER_LD');
        this.dealsDataLdSales = this.filterDeals(this.searchFilteredData, 'SALES_LD');
        this.dealsDataLdFinance = this.filterDeals(this.searchFilteredData, 'FINANCE_LD');
        this.dealsDataPocVerfifier = this.filterDeals(this.searchFilteredData, 'VERIFIER_POC');
        this.dealsDataPocSales = this.filterDeals(this.searchFilteredData, 'SALES_POC');
        this.dealsDataPocFinance = this.filterDeals(this.searchFilteredData, 'FINANCE_POC');
        this.dealsDataDone = this.filterDeals(this.searchFilteredData, 'DONE');
      }
    },
    getUniqueUserData(data) {
      return [...new Map(data.map((item) => [item.quoteId, item])).values()];
    },
    setNoneSelected() {
      this.selectedUser = {};
      // console.log('None selected', this.selectedUser);
      this.searchTerm = null;
      this.getDealsComplete();
    },
    setSearchTermNull() {
      this.searchTerm = null;
    },
  },
  computed: {
    loggedInUser() {
      return this.$store.state.loggedInUser;
    },
    searchFilteredData() {
      const filteredData = [];
      if (this.searchTerm) {
        const searchArray = this.searchTerm.toLowerCase().split(' ');
        // console.log('this.dealsData', this.dealsData);
        searchArray.forEach((entry) => {
          // eslint-disable-next-line max-len
          const filterByAccountname = this.dealsData.filter((item) => item.customerData.accountName.toLowerCase().includes(entry));
          if (filterByAccountname) filteredData.push(...filterByAccountname);

          // eslint-disable-next-line max-len
          const filterByAccountcity = this.dealsData.filter((item) => item.customerData.accountCity.toLowerCase().includes(entry));
          if (filterByAccountcity) filteredData.push(...filterByAccountcity);

          // eslint-disable-next-line max-len
          const filterBySoldToNumber = this.dealsData.filter((item) => item.customerData.soldToNumber.toLowerCase().includes(entry));
          if (filterBySoldToNumber) filteredData.push(...filterBySoldToNumber);

          // eslint-disable-next-line max-len
          const filterByAffiliation = this.dealsData.filter((item) => item.customerData.affiliation.toLowerCase().includes(entry));
          if (filterByAffiliation) filteredData.push(...filterByAffiliation);

          // eslint-disable-next-line max-len
          const filterByQuoteNumber = this.dealsData.filter((item) => item.quoteNumber.toLowerCase().includes(entry));
          if (filterByQuoteNumber) filteredData.push(...filterByQuoteNumber);

          // eslint-disable-next-line max-len
          const filterByRevisionNumber = this.dealsData.filter((item) => item.revisionNumber === entry);
          if (filterByRevisionNumber) filteredData.push(...filterByRevisionNumber);

          // eslint-disable-next-line max-len
          const filterBySalesRep = this.dealsData.filter((item) => item.salesRep?.toLowerCase().includes(entry));
          if (filterBySalesRep) filteredData.push(...filterBySalesRep);

          // eslint-disable-next-line max-len
          const filterBySalesManager = this.dealsData.filter((item) => item.salesManager?.toLowerCase().includes(entry));
          if (filterBySalesManager) filteredData.push(...filterBySalesManager);

          // eslint-disable-next-line max-len
          const filterByApprovalText = this.dealsData.filter((item) => item.approvalText?.toLowerCase().includes(entry));
          if (filterByApprovalText) filteredData.push(...filterByApprovalText);
        });
      }
      return this.getUniqueUserData(filteredData);
    },
  },
  watch: {
    searchTerm(val) {
      if (val == null) {
        if (Object.keys(this.selectedUser).length > 0) this.getDealsByUser(this.selectedUser);
        else if (Object.keys(this.selectedUser).length === 0) this.getDealsComplete();
        else this.getAllDeals();
      } else if (val === '') this.getDealsComplete();
    },
  },
};
</script>

<style lang="scss">
</style>
