<template>
  <div class="deal-card__detail"
       @click="resetConfirm"
  >
    <v-card
      class="mx-auto deal-detail pa-4"
      max-width="1280"
      min-height="300"
      light
      tile
      :key="componentKey"
    >
      <div class="align-top-right pa-7">
        <v-btn icon
               @click="emitClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <section v-if="this.loading">
        <spinner />
      </section>
      <section v-else-if="this.dealNotFound">
        <div class="d-flex flex-column mb-5 mt-12 text-center">
          <v-icon size="60" color="#FFD200" class="mt-5 mb-5">mdi-alert-circle</v-icon>
          <p class="header-5 mb-0">The deal you are expecting to open does not exist.
            You might want to use the search to find what you are looking for.</p>
        </div>
      </section>
      <section v-else class="mt-12 mt-md-8">
        <v-row>
          <v-col cols="12">
            <!-- Stepper indicator -->
            <v-row v-if="dealData.quoteType.poc && dealData.quoteType.ld">
              <div class="col col-4 offset-2 stepper__indicator--ld">
                <div class="stepper__indicator__line"></div>
                <div class="stepper__indicator__label text-smaller">LD</div>
              </div>
              <div class="col col-4 stepper__indicator--poc">
                <div class="stepper__indicator__line"></div>
                <div class="stepper__indicator__label text-smaller">POC</div>
              </div>
            </v-row>
            <v-stepper alt-labels :value="getEscalationStep()">
              <v-stepper-header>
                <v-stepper-step step="1">New Deal</v-stepper-step>
                <v-divider></v-divider>
                <template v-if="dealData.quoteType.ld && !dealData.quoteType.poc">
                  <v-stepper-step step="2">Verifier</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="3">Sales Approver</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="4">Finance Approver</v-stepper-step>
                </template>
                <template v-if="dealData.quoteType.poc && !dealData.quoteType.ld">
                    <v-stepper-step step="2">Verifier</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3">Sales Approver</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="4">Finance Approver</v-stepper-step>
                </template>
                <template v-if="dealData.quoteType.poc && dealData.quoteType.ld">
                  <v-stepper-step step="2">Verifier</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="3">Sales Approver</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="4">Finance Approver</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="5">Verifier</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="6">Sales Approver</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="7">Finance Approver</v-stepper-step>
                </template>
                <template v-if="dealData.quoteType.poc && dealData.quoteType.ld">
                  <v-divider></v-divider>
                  <v-stepper-step step="8">Done</v-stepper-step>
                </template>
                <template v-else>
                  <v-divider></v-divider>
                  <v-stepper-step step="5">Done</v-stepper-step>
                </template>
              </v-stepper-header>
            </v-stepper>
          </v-col>
        </v-row>
        <div class="mt-10 justify-space-between">
          <done-labels
            :deal-data="dealData"
            :is-detail-view="true"
            v-on:closeToParent="emitClose"
          ></done-labels>
        </div>
        <!-- Main Info-->
        <div class="text-smaller ml-4 mt-8">{{ dealData.timestamp | toDate }}</div>
        <v-card-title v-if="dealData.customerData === null">
          No Deal data.
        </v-card-title>
        <template v-else>
          <v-card-title><h5>{{ dealData.customerData.accountName }}</h5></v-card-title>
          <v-card-text class="pb-0">
            <p>{{ dealData.customerData.accountCity }},
              {{ dealData.customerData.accountState }}</p>
            <p>Sold to {{ dealData.customerData.soldToNumber }}</p>
            <p>{{ dealData.quoteNumber }}-{{ dealData.revisionNumber }}</p>
          </v-card-text>
        </template>
        <!-- Quote type -->
        <quote-types
          :deal-data="dealData"
          v-on:changeRcoStatus="setRcoStatus($event)"
          ></quote-types>
        <!-- Approval Text area-->
        <approval-text
          :deal-data="dealData"
          v-on:changeApprovalText="setApprovalText($event)"
          v-on:changeFormValid="setFormApprovalState($event)"
          v-on:changeStandardAgreement="setDistStandAgreement($event)"
          v-on:changeApprovalsObtained="setApprovalsObtained($event)"
        ></approval-text>
        <v-divider class="mx-4 my-4"></v-divider>
        <!-- Combo Deals area -->
        <combo-deals v-if="showComboDeals"
          :deal-data="dealData"
          v-on:dataChangeConflict="handleDataChangedConflict"
          v-on:differentLevels="handleDifferentLevels"
        ></combo-deals>
        <v-divider v-if="showComboDeals" class="mx-4 my-4"></v-divider>
        <!-- Account data section NEW -->
        <template v-if="dealData.escalationState === 'NEW'">
          <account-data
            :deal-data="dealData"
            v-on:changeProspectExchange="setProspectExchange($event)"
            v-on:changeSalesRep="setSalesRep($event)"
            v-on:changeSalesManager="setSalesManager($event)"
            v-on:changeFormValid="setFormAccountState($event)"
          ></account-data>
          <v-divider class="mx-4 my-4"></v-divider>
          <!-- Quote data section NEW -->
          <quote-data
            :deal-data="dealData"
            v-on:changeBillingOption="setBillingOption($event)"
          ></quote-data>
          <v-divider class="mx-4 my-4"></v-divider>
        </template>
        <div v-if="dealData.escalationState !== 'NEW'">
          <!-- People Data-->
          <people-data
            :deal-data="dealData"
            :level-changed="levelChanged"
            v-on:changeFormValid="setFormPeopleState"
          ></people-data>
          <v-divider class="mx-4 my-4"></v-divider>
          <!-- Comment section-->
          <deal-actions
            :deal-data="dealData"
            :forms-valid="isValid"
            :reset-confirm="isResetConfirmation"
            :show-level-combo-deals="isDifferentLevelCombo"
            v-on:cancelDeal="emitCancel($event)"
            v-on:declineDeal="emitDecline($event)"
            v-on:approveDeal="emitApprove($event)"
            v-on:commentAdded="setCommentAdded"
            v-on:inquiryAdded="reloadData"
            v-on:changedLevel="changeLevel($event)"
            v-on:closeToParent="emitClose"
            v-on:confirmationPending="setConfirmationPending()"
          ></deal-actions>
          <v-divider class="mx-4 my-4"></v-divider>
          <!-- History section-->
          <deal-history
            :deal-data="dealData"
            :update-history="commentChanged || levelChanged"
            v-on:resetUpdateHistory="commentChanged = false"
          ></deal-history>
          <v-divider class="mx-4 my-4"></v-divider>
        </div>
        <template v-if="dealData.escalationState !== 'NEW'">
          <!-- Account data section-->
          <account-data
            :deal-data="dealData"></account-data>
          <v-divider class="mx-4 my-4"></v-divider>
          <!-- Quote data section-->
          <quote-data
            :deal-data="dealData"></quote-data>
          <v-divider class="mx-4 my-4"></v-divider>
        </template>
        <!-- Profile section-->
        <profile-data :deal-data="dealData"></profile-data>
        <v-divider class="mx-4 my-4"></v-divider>
        <!-- Value adds section-->
        <value-adds
          :deal-data="dealData"
          v-on:changeQuantityGpo="setQuantityGpo($event)"
          v-on:changePriceNetGpo="setPriceNetGpo($event)"
          v-on:changeCostGpo="setCostGpo($event)"
          v-on:changesProductSums="setValueAddsProductSums($event)"
        ></value-adds>
        <v-divider class="mx-4 my-4"></v-divider>
        <!-- Product data section-->
        <product-data
          :deal-data="dealData"
          :value-add-product-sums="valueAddsProductSums"
          v-on:changeAutomation="setAutomation($event)"
          v-on:changeAptio="setAptio($event)"
          v-on:changeAtellica="setAtellica($event)"
          v-on:changeAccessories="setAccessories($event)"
          v-on:changeDcaDiscount="setDcaDiscount($event)"
        ></product-data>
        <div v-if="dealData.escalationState === 'NEW'">
          <v-divider class="mx-4 my-4"></v-divider>
          <!-- People Data-->
          <people-data
            :deal-data="dealData"
            :sales-rep="reviewInput.salesRep"
            :level-changed="levelChanged"
            v-on:changeFormValid="setFormPeopleState"
          ></people-data>
          <v-divider class="mx-4 my-4"></v-divider>
          <!-- Comment section-->
          <deal-actions
            :deal-data="dealData"
            :forms-valid="isValid"
            :reset-confirm="isResetConfirmation"
            :show-level-combo-deals="isDifferentLevelCombo"
            v-on:deleteDeal="emitDelete($event)"
            v-on:approveDeal="emitApprove($event)"
            v-on:changedLevel="changeLevel($event)"
            v-on:commentAdded="setCommentAdded"
            v-on:confirmationPending="setConfirmationPending()"
          ></deal-actions>
          <v-divider class="mx-4 my-4"></v-divider>
          <!-- History section-->
          <deal-history
            :deal-data="dealData"
            :update-history="commentChanged"
            v-on:resetUpdateHistory="commentChanged = false"
          ></deal-history>
          <v-divider class="mx-4 my-4"></v-divider>
          <v-card-subtitle>
            * Required
          </v-card-subtitle>
        </div>
      </section>
    </v-card>
  </div>
</template>

<script>
import ApiService from '@/services/ApiService';
import dealFunctions from '../mixins/DealFunctions';
import AccountData from '../components/deal-details/AccountData.vue';
import QuoteData from '../components/deal-details/QuoteData.vue';
import ProfileData from '../components/deal-details/ProfileData.vue';
import ValueAdds from '../components/deal-details/ValueAdds.vue';
import DealHistory from '../components/deal-details/DealHistory.vue';
import ProductData from '../components/deal-details/ProductData.vue';
import DealActions from '../components/deal-details/DealActions.vue';
import ApprovalText from '../components/deal-details/ApprovalText.vue';
import PeopleData from '../components/deal-details/PeopleData.vue';
import QuoteTypes from '../components/deal-details/QuoteTypes.vue';
import DoneLabels from '../components/misc/DoneLabels.vue';
import Spinner from '../components/Spinner.vue';
import ComboDeals from '../components/deal-details/ComboDeals.vue';

export default {
  name: 'DealDetail',
  components: {
    DoneLabels,
    QuoteTypes,
    PeopleData,
    ApprovalText,
    DealActions,
    ProductData,
    DealHistory,
    ValueAdds,
    ProfileData,
    QuoteData,
    AccountData,
    Spinner,
    ComboDeals,
  },
  mixins: [
    dealFunctions,
  ],
  props: {
    dealId: Number,
  },
  data() {
    return {
      dealData: null,
      loading: true,
      dealNotFound: false,
      componentKey: 0,
      valueAddInput: {
        rank: null,
        qtyGpoStandard: null,
        priceNetGpoStandard: null,
        costGpoStandard: null,
        valueAdd: null,
        valueAddSumPercent: null,
        valueAddSum: null,
        valueHurdleViolation: null,
        rowVersion: null,
      },
      reviewInput: {
        acceptAccessoriesPeripherealSoftwareDiscountLD: null,
        acceptAptioDiscountLD: null,
        acceptAtellicaSCdiscountLD: null,
        acceptAutomationITdiscountLD: null,
        acceptDCAdiscountPOC: null,
        approvalText: null,
        distributorStandardAgreement: null,
        prospectOrExchange: null,
        rcoActiv: null,
        salesManager: null,
        salesRep: null,
        rowVersion: null,
        approvalAlreadyObtained: false,
      },
      valueAddsProductSums: [],
      formAccountValid: false,
      formPeopleValid: false,
      formApprovalValid: false,
      levelChanged: false,
      commentChanged: false,
      triggerRecalculation: false,
      approvalAlreadyObtained: false,
      confirmationPending: false,
      resetConfirmation: false,
      differentLevelsComboDeals: false,
      showComboDeals: false,
    };
  },
  mounted() {
    this.getDetailData(this.dealId);
    this.$store.commit('setLevelChanged', false);
  },
  methods: {
    resetConfirm() {
      if (this.confirmationPending) {
        if (this.resetConfirmation) {
          this.resetConfirmation = false;
        } else {
          this.resetConfirmation = true;
        }
      }
    },
    setConfirmationPending() {
      this.confirmationPending = true;
    },
    emitClose() {
      this.$emit('closeToParent');
    },
    emitDecline(comment) {
      this.setEscalationState('DEAL_DECLINED', comment);
    },
    emitCancel(comment) {
      this.setEscalationState('DEAL_CANCELLED', comment);
    },
    emitDelete(comment) {
      this.setEscalationState('DEAL_DELETED', comment);
    },
    emitApprove(comment) {
      if (this.dealData.escalationState === 'NEW') {
        this.setEscalationState('ESCALATION_STARTED', comment);
      } else {
        this.setEscalationState('DEAL_APPROVED', comment);
      }
    },
    changeLevel(level) {
      console.log('changing Level to ', level);
      if (this.differentLevelsComboDeals) this.differentLevelsComboDeals = false;
      this.setEscalationLevel(level);
    },
    handleDataChangedConflict() {
      // TODO: evaluate if forcing rerender is the best way to refresh data
      // console.log('handleDataChangedConflict called...');
      this.getDetailData(this.dealId, this.forceRerender);
      this.$emit('dealDataChanged');
    },
    handleDifferentLevels() {
      this.differentLevelsComboDeals = true;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    reloadData() {
      this.getDetailData(this.dealId, this.forceRerender);
    },
    setDistStandAgreement(input) {
      this.reviewInput.distributorStandardAgreement = input;
      this.triggerRecalculation = true;
      this.setReviewInput();
    },
    setApprovalText(text) {
      this.reviewInput.approvalText = text;
      this.setReviewInput();
    },
    setApprovalsObtained(input) {
      this.approvalAlreadyObtained = input;
      this.reviewInput.approvalAlreadyObtained = input;
      this.setReviewInput();
    },
    setProspectExchange(input) {
      this.reviewInput.prospectOrExchange = input;
      this.setReviewInput();
    },
    setSalesRep(input) {
      this.reviewInput.salesRep = input;
      this.setReviewInput();
    },
    setSalesManager(input) {
      this.reviewInput.salesManager = input;
      this.setReviewInput();
    },
    setAutomation(input) {
      this.reviewInput.acceptAutomationITdiscountLD = input;
      this.triggerRecalculation = true;
      this.setReviewInput();
    },
    setAtellica(input) {
      this.reviewInput.acceptAtellicaSCdiscountLD = input;
      this.triggerRecalculation = true;
      this.setReviewInput();
    },
    setAptio(input) {
      this.reviewInput.acceptAptioDiscountLD = input;
      this.triggerRecalculation = true;
      this.setReviewInput();
    },
    setDcaDiscount(input) {
      this.reviewInput.acceptDCAdiscountPOC = input;
      this.triggerRecalculation = true;
      this.setReviewInput();
    },
    setAccessories(input) {
      this.reviewInput.acceptAccessoriesPeripherealSoftwareDiscountLD = input;
      this.triggerRecalculation = true;
      this.setReviewInput();
    },
    setRcoStatus(input) {
      this.reviewInput.rcoActiv = input;
      this.triggerRecalculation = true;
      this.setReviewInput();
    },
    setReviewInput() {
      const data = this.reviewInput;
      data.rowVersion = this.dealData.rowVersion;
      data.recalculationRequired = this.triggerRecalculation;

      this.triggerRecalculation = false; // reset recalculation flag
      ApiService.setReviewInput(this.dealData.id, JSON.stringify(data)).then((response) => {
        if (response) {
          // console.log('setReviewInput response', response.data);
          this.dealData = response.data;
          if (response.data.escalationlevelOverwrite !== this.dealData.escalationlevelOverwrite) {
            this.dealData.escalationlevelOverwrite = response.data.escalationlevelOverwrite;
            this.levelChanged = true;
            this.$store.commit('setLevelChanged', true);
          }
        }
      }).catch((error) => {
        if (error.response.status === 409) {
          console.log('Could not save');
          this.handleDataChangedConflict();
        }
      });
    },
    setBillingOption(input) {
      console.log('setBillingOption', input);
      this.setQuoteDataInput(input);
    },
    setQuoteDataInput(input) {
      const data = {};
      data.rowVersion = this.dealData.rowVersion;
      data.billingOption = input;

      ApiService.setQuoteDataInput(this.dealData.id, JSON.stringify(data)).then((response) => {
        if (response) {
          // console.log('setQuoteDataInput response', response.data);
          this.dealData = response.data;
        }
      }).catch((error) => {
        if (error.response.status === 409) {
          console.log('Could not save');
          this.handleDataChangedConflict();
        }
      });
    },
    setQuantityGpo(input) {
      this.setValueAddInput(input);
    },
    setPriceNetGpo(input) {
      this.setValueAddInput(input);
    },
    setCostGpo(input) {
      this.setValueAddInput(input);
    },
    setValueAddInput(data) {
      ApiService.setValueAddInput(this.dealData.id, JSON.stringify(data)).then((response) => {
        if (response) {
          // console.log('setValueAddInput response', response.data);
          this.dealData = response.data;
        }
      }).catch((error) => {
        if (error.response.status === 409) {
          this.handleDataChangedConflict();
        }
      });
    },
    setValueAddsProductSums(input) {
      this.valueAddsProductSums = input;
      // console.log('this.valueAddsProductSums received...', this.valueAddsProductSums);
    },
    setFormAccountState(valid) {
      // console.log('Form state', valid);
      this.formAccountValid = valid;
    },
    setFormPeopleState(valid) {
      // console.log('Deal-detail: Form people state', valid);
      this.formPeopleValid = valid;
    },
    setFormApprovalState(valid) {
      this.formApprovalValid = valid;
    },
    setEscalationState(action, comment) {
      ApiService.setEscalationState(this.dealData.id, action, JSON.stringify(comment)).then((response) => {
        if (response) {
          // console.log('escalationState set', response.data);
          this.emitClose();
        }
      }).catch((error) => {
        if (error.response.status === 409) {
          this.handleDataChangedConflict();
        }
      });
    },
    setEscalationLevel(level) {
      ApiService.setEscalationLevel(this.dealData.id, level, this.dealData.rowVersion).then((response) => {
        if (response) {
          // console.log('escalationLevel set', response.data);
          this.dealData = response.data;
          this.levelChanged = true;
          this.$store.commit('setLevelChanged', true);
        }
      }).catch((error) => {
        if (error.response.status === 409) {
          this.handleDataChangedConflict();
        }
      });
    },
    setCommentAdded() {
      this.commentChanged = true;
      this.reloadData();
    },
    getDetailData(dealId, callbackFunc = () => {}) {
      // service call
      ApiService.fetchDealDetails(dealId).then((response) => {
        // console.log('Response', response.data);
        if (response) {
          if (!Object.keys(response.data).length) {
            console.log('Empty Array');
          } else {
            // console.log('Response', response.data);
            this.dealData = response.data;
            this.valueAddInput.rowVersion = this.dealData.rowVersion;
            // eslint-disable-next-line max-len
            this.reviewInput.acceptAccessoriesPeripherealSoftwareDiscountLD = this.dealData.acceptAccessoriesPeripherealSoftwareDiscountLD;
            this.reviewInput.acceptAptioDiscountLD = this.dealData.acceptAptioDiscountLD;
            this.reviewInput.acceptAtellicaSCdiscountLD = this.dealData.acceptAtellicaSCdiscountLD;
            this.reviewInput.acceptAutomationITdiscountLD = this.dealData.acceptAutomationITdiscountLD;
            this.reviewInput.acceptDCAdiscountPOC = this.dealData.acceptDCAdiscountPOC;
            this.reviewInput.approvalText = this.dealData.approvalText;
            this.reviewInput.distributorStandardAgreement = this.dealData.distributorStandardAgreement;
            this.reviewInput.prospectOrExchange = this.dealData.prospectOrExchange;
            this.reviewInput.rcoActiv = this.dealData.quoteType.rco;
            this.reviewInput.salesManager = this.dealData.salesManager;
            this.reviewInput.salesRep = this.dealData.salesRep;
            this.reviewInput.rowVersion = this.dealData.rowVersion;
            if (this.dealData.escalationState === 'NEW' || this.dealData.comboDealId) this.showComboDeals = true;
          }
        } else {
          console.log('No data.');
        }
      }).catch((error) => {
        if (error.response.status === 400) {
          this.dealNotFound = true;
        }
      })
        .then(() => {
          callbackFunc();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getEscalationStep() {
      let escalationStep = 1;
      if (this.dealData.escalationState) {
        switch (this.dealData.escalationState) {
          case 'NEW':
            escalationStep = 1;
            break;
          case 'VERIFIER_LD':
            escalationStep = 2;
            break;
          case 'SALES_LD':
            escalationStep = 3;
            break;
          case 'FINANCE_LD':
            escalationStep = 4;
            break;
          case 'VERIFIER_POC':
            if (this.dealData.quoteType.poc && !this.dealData.quoteType.ld) {
              escalationStep = 2;
            } else {
              escalationStep = 5;
            }
            break;
          case 'SALES_POC':
            if (this.dealData.quoteType.poc && !this.dealData.quoteType.ld) {
              escalationStep = 3;
            } else {
              escalationStep = 6;
            }
            break;
          case 'FINANCE_POC':
            if (this.dealData.quoteType.poc && !this.dealData.quoteType.ld) {
              escalationStep = 4;
            } else {
              escalationStep = 7;
            }
            break;
          case 'DONE':
            if (this.dealData.quoteType.poc && this.dealData.quoteType.ld) {
              escalationStep = 8;
            } else {
              escalationStep = 5;
            }
            break;
          case 'ARCHIVE':
            escalationStep = 9;
            break;
          default:
            escalationStep = 1;
            break;
        }
      }
      return escalationStep;
    },
  },
  computed: {
    isVerifier() {
      return (this.dealData.escalationState === 'VERIFIER_LD' || this.dealData.escalationState === 'VERIFIER_POC');
    },
    isValid() {
      let result = false;
      if (this.isVerifier) {
        if (this.isManuallyChanged) result = this.formPeopleValid;
        else if (this.$store.state.levelChanged) result = this.formPeopleValid;
        else result = true;
        if (this.approvalAlreadyObtained) {
          // console.log('this.approvalAlreadyObtained', this.approvalAlreadyObtained);
          result = false;
        }
      } else {
        result = this.formAccountValid && this.formPeopleValid && this.formApprovalValid;
      // console.log('deal-detail: isValid?', result);
      }
      return result;
    },
    isResetConfirmation() {
      return this.resetConfirmation;
    },
    isDifferentLevelCombo() {
      return this.differentLevelsComboDeals;
    },
  },
};
</script>

<style lang="scss">
  @import "src/scss/chips";
.deal-card {
  &__detail {
    .deal-detail {
      .v-list-item__avatar {
        justify-content: center;
      }

      // mobile
      .v-stepper--alt-labels {
        .v-stepper__step {
          flex-basis: auto;
        }
        .v-stepper__header .v-divider {
          margin: 12px 0 0;
        }
      }
      .v-stepper:not(.v-stepper--vertical) {
        .v-stepper__step {
          &.v-stepper__step--active {
            .v-stepper__label {
              display: block;
            }
          }
        }
      }
      .v-stepper {
        // width: 95%;
        .v-stepper__label {
          font-size: .8em;
        }
        .v-stepper__step__step {
          // font-size: 1em;
        }
        .v-stepper__step {
          padding: 0 4px 24px 4px;
          &.v-stepper__step--active {
            .v-stepper__label {
              color: #ec6602;
            }
          }
        }

      }
      .stepper__indicator {
        &--ld {
          display: flex;
          height: 30px;
          position: relative;
          padding-left: 0;
          padding-right: 0;
          .stepper__indicator__label {
            left: 43%;
          }
        }
        &--poc {
          display: flex;
          height: 30px;
          position: relative;
          padding-left: 35px;
          padding-right: 0;
        }
        &__line {
          width: 100%;
          border-top: 1px solid #e6e6e6;
          border-left: 1px solid #e6e6e6;
          border-right: 1px solid #e6e6e6;
          align-items: flex-end;
        }
        &__label {
          position: absolute;
          top: 1%;
          left: 50%;
          padding: 0 3px;
          background-color: #fff;
          color: #999;
        }
      }

      // desktop, tablet
      @media screen and (min-width: 600px) {
        .v-stepper--alt-labels {
          .v-stepper__step {
            flex-basis: 175px;
          }
          .v-stepper__header .v-divider {
            margin: 35px -67px 0;
          }
        }
        .v-stepper {
          // width: 95%;
          .v-stepper__label {
            font-size: .8em;
          }
          .v-stepper__step__step {
            // font-size: 1em;
          }
          .v-stepper__step {
            padding: 24px;
            &.v-stepper__step--active {
              .v-stepper__label {
                color: #ec6602;
              }
            }
          }

        }
        .stepper__indicator {
          &--ld {
            display: flex;
            height: 30px;
            position: relative;
            padding-left: 43px;
            padding-right: 73px;
            .stepper__indicator__label {
              left: 43%;
            }
          }
          &--poc {
            display: flex;
            height: 30px;
            position: relative;
            padding-left: 73px;
            padding-right: 43px;
          }
          &__line {
            width: 100%;
            border-top: 1px solid #e6e6e6;
            border-left: 1px solid #e6e6e6;
            border-right: 1px solid #e6e6e6;
            align-items: flex-end;
          }
          &__label {
            position: absolute;
            top: 1%;
            left: 50%;
            padding: 0 3px;
            background-color: #fff;
            color: #999;
          }
        }
      }
      .quote-type {
        span.mr-1:nth-of-type(3) {
          margin-left: 15px;
        }
      }
      .text-center .v-icon.v-icon::after {
        width: auto;
      }
    }
  }
}
</style>
