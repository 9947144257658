<template>
  <v-autocomplete
    outlined
    rounded
    dense
    v-model="userSelected"
    :items="userEntries"
    :loading="userLoading"
    :search-input.sync="userSearch"
    hide-no-data
    hide-selected
    item-text="fullName"
    item-value="id"
    label="Deals related to"
    return-object
    clearable
    height="36"
    :disabled="isDisabled"
  ></v-autocomplete>
</template>

<script>
import ApiService from '@/services/ApiService';
import dealFunctions from '../mixins/DealFunctions';

export default {
  name: 'UserFilter',
  props: {
    isDisabled: Boolean,
  },
  mixins: [
    dealFunctions,
  ],
  data() {
    return {
      userSelected: {},
      userSearch: null,
      userLoading: false,
      userEntries: [],
      userAll: [],
    };
  },
  mounted() {
    this.getAllUsers();
  },
  methods: {
    getAllUsers() {
      ApiService.fetchAllUsers().then((response) => {
        this.userLoading = true;
        if (response) {
          // console.log('response', response.data);
          this.userAll = response.data;
          // eslint-disable-next-line prefer-destructuring
          this.userSelected = this.filterCurrentUser()[0];
          this.userSearch = this.userSelected.fullName;
        }
      })
        .catch((error) => console.log(error))
        .finally(() => {
          this.userLoading = false;
        });
    },
    filterUsers(filter) {
      return this.usersAllFull.filter((user) => user.fullName?.toLowerCase().indexOf(filter?.toLowerCase()) !== -1);
    },
    filterCurrentUser() {
      return this.usersAllFull.filter((user) => user.loggedInUser);
    },
    emitUserSelected() {
      this.$emit('userSelected', this.userSelected);
    },
    emitNoneSelected() {
      this.$emit('noneSelected');
      console.log('noneSelected emitted...');
    },
  },
  computed: {
    usersAllFull() {
      return this.userAll.map((item) => ({
        fullName: `${item.firstName} ${item.lastName}`,
        initials: this.getInitials(item.firstName, item.lastName),
        ...item,
      }));
    },
  },
  watch: {
    userSearch(val) {
      // console.log('userSearch', val);
      this.userEntries = this.filterUsers(val);
      if (val == null) this.emitNoneSelected();
    },
    userSelected(val) {
      // console.log('userSelected', val?.firstName);
      this.emitUserSelected(val);
    },
  },
};
</script>

<style lang="scss">
  .v-list--dense .v-list-item .v-list-item__title {
    font-size: .89em;
  }
  .theme--light.v-list-item .v-list-item__mask {
    color: inherit;
    background: white;
    font-weight: bold;
  }
</style>
