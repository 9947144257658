import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { variations: false },
    themes: {
      light: {
        primary: '#ec6602',
        secondary: '#b0bec5',
        accent: '#009999',
        success: '#009a38',
        error: '#e7001d',
        info: '#FFFFFF',
        infoText: '#1a1a1a',
        warning: '#ec6602',
        warningText: '#FFFFFF',
      },
    },
  },
});
