<template>
  <div class="deal-history">
    <v-card-title>
      <h5 class="clickableElement" @click="showHistory = !showHistory">History</h5>
      <v-btn icon @click="showHistory = !showHistory" color="primary">
        <v-icon>{{ showHistory ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition>
      <div v-show="showHistory">
        <v-card-subtitle v-if="!dealHistory.length">
          No History yet.
        </v-card-subtitle>
        <v-list v-else>
          <template v-for="(comment, index) in dealHistory">
            <v-list-item class="mb-4"
              :key="index"
            >
              <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                <span class="black--text">{{ getInitials(comment.user.firstName, comment.user.lastName) }}</span>
              </v-list-item-avatar>
              <v-list-item-content :key="index">
                <v-list-item-title class="font-weight-bold overflow-visible">
                  {{ comment.reasonDescription }}
                   {{ comment.user.firstName }} {{ comment.user.lastName }}
                  <span class="font-weight-regular text-uppercase grey--text mt-md-0 mt-1 ml-md-4 d-md-inline d-block">
                  {{ comment.timestamp | toDate }}
                  </span>
                </v-list-item-title>
                <v-list-item-title v-if="comment.comment" class="deal-history--text overflow-visible mt-3">
                  {{ comment.comment }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import dealFunctions from '../../mixins/DealFunctions';

export default {
  name: 'DealHistory',
  props: {
    dealData: Object,
    updateHistory: Boolean,
  },
  mixins: [
    dealFunctions,
  ],
  data() {
    return {
      showHistory: true,
      dealHistory: {},
    };
  },
  mounted() {
    this.getDealHistory();
  },
  methods: {
    getDealHistory() {
      ApiService.getDealHistory(this.dealData.id).then((response) => {
        if (response) {
          // console.log('getDealHistory response', response.data);
          this.dealHistory = response.data;
        }
      }).catch((error) => console.log(error))
        .finally(() => {
          // reset history update
          this.$emit('resetUpdateHistory');
        });
    },
  },
  watch: {
    updateHistory(val) {
      if (val) {
        this.getDealHistory();
      }
    },
  },
};
</script>

<style lang="scss">
.deal-history {
  .v-list-item__content {
    .v-list-item__title.deal-history--text {
      white-space: pre-line;
    }
  }
}
  .v-list-item__title.overflow-visible {
    white-space: normal;
  }
</style>
