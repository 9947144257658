<template>
  <div class="user-detail">
    <v-card
      class="mx-auto deal-detail pa-4"
      max-width="1280"
      min-height="300"
      light
      tile
    >
      <div class="align-top-right pa-7">
        <v-btn icon
               @click="emitClose"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <section v-if="this.loading">
        <spinner />
      </section>
      <section v-else class="mt-2">
        <v-form v-model="valid">
          <v-list-item class="mb-7">
            <h3 v-if="isCreateNew">Create New User</h3>
            <h3 v-else>Edit User</h3>
          </v-list-item>
          <v-list-item class="mb-2">
            <v-list-item-avatar color="#eeeeee" size="60" class="mr-2">
              <template v-if="isCreateNew">
                <v-icon size="30">mdi-account</v-icon>
              </template>
              <template v-else>
                {{ getInitials(userData.firstName, userData.lastName) }}
              </template>
            </v-list-item-avatar>
          </v-list-item>
          <v-list-item>
            <v-row>
              <v-col cols="12" class="col-md-6">
                <v-text-field
                  v-model="firstName"
                  label="First Name"
                  :rules="firstNameRules"
                  :disabled="!hasEditRight"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="col-md-6">
                <v-text-field
                  v-model="lastName"
                  label="Last Name"
                  :rules="lastNameRules"
                  :disabled="!hasEditRight"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <template v-if="isCreateNew">
              <v-row>
                <v-col cols="12" class="col-md-6">
                  <v-text-field
                    v-model="userEmail"
                    label="E-mail"
                    :rules="userEmailRules"
                    :disabled="!hasEditRight"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-list-item-content class="pt-0">
                <v-list-item-subtitle>
                  E-mail
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ userData.email }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-list-item>
          <v-list-item class="flex-wrap mt-10">
            <h5 class="full-width">Business Line</h5>
            <p class="text-smaller color-gray">
              Check all business lines this person can be selected for request for approval.
            </p>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="checkLd"
              label="LD"
              :disabled="!hasEditRight"
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="checkPoc"
              label="POC"
              :disabled="!hasEditRight"
            ></v-checkbox>
          </v-list-item>
          <v-list-item class="flex-wrap mt-10">
            <h5 class="full-width">Function</h5>
            <p class="text-smaller color-gray">
              Check all functions this person can be selected for request for approval.
            </p>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="checkVerifier"
              label="Verifier"
              :disabled="!hasEditRight"
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="checkSales"
              label="Sales Approver"
              :disabled="!hasEditRight"
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="checkFinance"
              label="Finance Approver"
              :disabled="!hasEditRight"
            ></v-checkbox>
          </v-list-item>
          <v-list-item class="flex-wrap mt-10">
            <h5 class="full-width">Responsible for</h5>
            <p class="text-smaller color-gray">
              This text will be shown next to this person's name in the lists of persons to select
              for request for approval. It should contain e.g. the region this person is responsible for.
            </p>
          </v-list-item>
          <v-list-item>
            <v-row>
              <v-col cols="12" class="col-md-6 py-0">
                <v-text-field
                  v-model="region"
                  label="Area"
                  :disabled="!hasEditRight"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item class="flex-wrap mt-10">
            <h5 class="full-width">Escalation Level</h5>
            <p class="text-smaller color-gray">
              Check all escalation levels this person can be selected for request for approval.
            </p>
          </v-list-item>
          <div class="escalation-level">
            <v-list-item>
              <v-checkbox
                class="mt-2"
                v-model="checkLevel1"
                label=""
                :disabled="!hasEditRight"
              ></v-checkbox>
              <div class="user-chip">
                <v-chip label
                        :color="getLevelData(1)"
                >Level 1</v-chip>
              </div>
            </v-list-item>
            <v-list-item>
              <v-checkbox
                class="mt-2"
                v-model="checkLevel2"
                label=""
                :disabled="!hasEditRight"
              ></v-checkbox>
              <div class="user-chip">
                <v-chip label
                        :color="getLevelData(2)"
                >Level 2</v-chip>
              </div>
            </v-list-item>
            <v-list-item>
              <v-checkbox
                class="mt-2"
                v-model="checkLevel3"
                label=""
                :disabled="!hasEditRight"
              ></v-checkbox>
              <div class="user-chip">
                <v-chip label
                        class="text--white"
                        :color="getLevelData(3)"
                >Level 3</v-chip>
              </div>
            </v-list-item>
            <v-list-item>
              <v-checkbox
                class="mt-2"
                v-model="checkLevel4"
                label=""
                :disabled="!hasEditRight"
              ></v-checkbox>
              <div class="user-chip">
                <v-chip label
                        :color="getLevelData(4)"
                >Level 4</v-chip>
              </div>
            </v-list-item>
            <v-list-item>
              <v-checkbox
                class="mt-2"
                v-model="checkLevel5"
                label=""
                :disabled="!hasEditRight"
              ></v-checkbox>
              <div class="user-chip">
                <v-chip label
                        class="text--white"
                        :color="getLevelData(5)"
                >Level 5</v-chip>
              </div>
            </v-list-item>
          </div>
          <v-list-item class="flex-wrap mt-10">
            <h5 class="full-width">Further Options</h5>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="checkDealsManagement"
              label="Allowed to create and change deals"
              :disabled="!hasEditRight"
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="checkUserManagement"
              label="Allowed to create and edit users"
              :disabled="!hasEditRight"
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="excludeFromLevels"
              label="Exclude user from sales/finance approval (only escalation level 3, 4 & 5)"
              :disabled="!hasEditRight"
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="allowedExportDealData"
              label="Allowed to export deal data"
              :disabled="!hasEditRight"
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <!-- Action Buttons-->
            <v-card-actions class="px-0 mt-10 mb-7 flex-column flex-md-row full-width">
              <template v-if="!userData.active">
                <v-btn
                  rounded
                  outlined
                  depressed
                  @click="sendBackToUserManagement"
                >
                  <v-icon
                    size="20"
                    left
                    color="#EC6602"
                  >mdi-undo</v-icon>
                  Restore User
                </v-btn>
              </template>
              <template v-else>
                <template v-if="isCreateNew">
                  <div class="flex-column">
                    <v-list-item class="px-0">
                      <v-btn
                        rounded
                        color="primary"
                        depressed
                        :disabled="!hasEditRight"
                        @click="createUser"
                      >
                        Create this user
                      </v-btn>
                    </v-list-item>
                    <v-list-item class="full-width px-0">
                      <p class="text-smaller color-gray mt-3">
                        <span class="font-weight-bold">Note:</span>
                        An e-mail will be sent to the new user with the login details.
                      </p>
                    </v-list-item>
                  </div>
                </template>
                <template v-else>
                  <div class="responsive-block d-flex flex-column flex-sm-row align-center mb-md-0 mb-sm-5 mb-0">
                    <v-btn
                      rounded
                      outlined
                      @click="emitCancel"
                    >Cancel</v-btn>
                    <v-btn
                      class="ml-0 ml-sm-3 mt-5 mt-sm-0"
                      rounded
                      color="primary"
                      depressed
                      :disabled="!hasEditRight"
                      @click="saveUser"
                    >
                      Save changes
                    </v-btn>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="responsive-block d-flex flex-column flex-sm-row align-center mb-md-0 mb-sm-5 mb-0">
                    <v-btn
                      class="mb-sm-0 mb-5 mr-0 my-sm-0 mt-sm-0 mt-10"
                      rounded
                      outlined
                      depressed
                      :disabled="isReadOnly"
                      @click="sendToArchive"
                    >
                      <v-icon
                        size="20"
                        left
                        color="#ec6602"
                      >mdi-archive</v-icon>
                      Archive User
                    </v-btn>
                  </div>
                </template>
              </template>
            </v-card-actions>
          </v-list-item>
        </v-form>
        <template v-if="!isCreateNew">
          <v-divider class="mx-4 my-4"></v-divider>
          <!-- User History -->
          <v-card-title>
            <h5>History</h5>
            <v-btn icon @click="showHistory = !showHistory" color="primary">
              <v-icon>{{ showHistory ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
          </v-card-title>
          <v-expand-transition>
            <div v-show="showHistory">
              <v-card-subtitle v-if="Object.keys(this.userHistory).length === 0">
                No History yet.
              </v-card-subtitle>
              <v-list v-else class="user-history">
                <v-list-item class="mb-4">
                  <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                      <span class="black--text">
                        {{ getInitials(userHistory.lastUpdatedByFirstName, userHistory.lastUpdatedByLastName) }}
                      </span>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold overflow-visible">
                      {{ userHistory.lastUpdatedByFirstName }} {{ userHistory.lastUpdatedByLastName }}
                      changed this user
                      <span class="font-weight-regular text-uppercase grey--text mt-md-0 mt-1
                       ml-md-4 d-md-inline d-block">
                  {{ userHistory.lastUpdatedAt | toDate }}
                    </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item class="mb-4">
                  <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
                      <span class="black--text">
                        {{ getInitials(userHistory.createdByFirstName, userHistory.createdByLastName) }}
                      </span>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold overflow-visible">
                        {{ userHistory.createdByFirstName }} {{ userHistory.createdByLastName }}
                       created this user
                      <span class="font-weight-regular text-uppercase grey--text mt-md-0 mt-1
                       ml-md-4 d-md-inline d-block">
                        {{ userHistory.createdAt | toDate }}
                    </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-expand-transition>
        </template>
      </section>
    </v-card>
    <user-notification :message="notificationMessage" :visible="notificationShown" @changeState="setNotification('', false)"></user-notification>
  </div>
</template>

<script>
import dealFunctions from '../../mixins/DealFunctions';
import ApiService from '../../services/ApiService';
import Spinner from '../../components/Spinner.vue';
import UserNotification from '../../components/user/UserNotification.vue';

export default {
  name: 'UserDetail',
  components: {
    UserNotification,
    Spinner,
  },
  mixins: [
    dealFunctions,
  ],
  props: {
    userId: Number,
    createNew: Boolean,
  },
  data() {
    return {
      isCreateNew: false,
      userData: null,
      loading: true,
      firstName: '',
      firstNameRules: [
        (v) => !!v || '',
      ],
      lastName: '',
      lastNameRules: [
        (v) => !!v || '',
      ],
      userEmail: '',
      userEmailRules: [
        (v) => !!v || '',
      ],
      checkLd: false,
      checkPoc: false,
      checkVerifier: false,
      checkSales: false,
      checkFinance: false,
      region: '',
      checkLevel1: false,
      checkLevel2: false,
      checkLevel3: false,
      checkLevel4: false,
      checkLevel5: false,
      checkUserManagement: false,
      checkDealsManagement: true,
      excludeFromLevels: false,
      allowedExportDealData: false,
      userFormValid: true,
      valid: true,
      showHistory: true,
      userHistory: {},
      notificationMessage: '',
      notificationShown: false,
    };
  },
  mounted() {
    if (!this.createNew) {
      this.getDetailData(this.userId);
      this.getUserHistory(this.userId);
    } else {
      this.isCreateNew = true;
      this.loading = false;
      this.userData = {};
      this.userData.active = true;
    }
  },
  methods: {
    emitClose() {
      this.$emit('closeToParent');
    },
    emitCancel() {
      this.emitClose();
    },
    getDetailData(userId) {
      ApiService.editUser(userId).then((response) => {
        if (response) {
          if (!Object.keys(response.data).length) {
            console.log('Empty Array');
          } else {
            this.userData = response.data;
            if (this.userData.firstName) this.firstName = this.userData.firstName;
            if (this.userData.lastName) this.lastName = this.userData.lastName;
            if (this.userData.businessLineLD) this.checkLd = this.userData.businessLineLD;
            if (this.userData.businessLinePOC) this.checkPoc = this.userData.businessLinePOC;
            if (this.userData.verifier) this.checkVerifier = this.userData.verifier;
            if (this.userData.sales) this.checkSales = this.userData.sales;
            if (this.userData.finance) this.checkFinance = this.userData.finance;
            if (this.userData.region) this.region = this.userData.region;
            if (this.userData.escalationLevel1) this.checkLevel1 = this.userData.escalationLevel1;
            if (this.userData.escalationLevel2) this.checkLevel2 = this.userData.escalationLevel2;
            if (this.userData.escalationLevel3) this.checkLevel3 = this.userData.escalationLevel3;
            if (this.userData.escalationLevel4) this.checkLevel4 = this.userData.escalationLevel4;
            if (this.userData.escalationLevel5) this.checkLevel5 = this.userData.escalationLevel5;
            if (this.userData.userManagement) this.checkUserManagement = this.userData.userManagement;
            if (this.userData.excludeFromLevels) this.excludeFromLevels = this.userData.excludeFromLevels;
            if (this.userData.allowedExportDealData) this.allowedExportDealData = this.userData.allowedExportDealData;
            this.checkDealsManagement = !this.userData.readOnly;
          }
        }
      }).catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
    getUserHistory(userId) {
      ApiService.getUserHistory(userId).then((response) => {
        if (response) {
          this.userHistory = response.data;
        }
      }).catch((error) => console.log(error.response.data.description));
    },
    saveUser() {
      const formData = {
        userId: this.userId,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.userData.email,
        region: this.region,
        businessLineLD: this.checkLd,
        businessLinePOC: this.checkPoc,
        verifier: this.checkVerifier,
        sales: this.checkSales,
        finance: this.checkFinance,
        escalationLevel1: this.checkLevel1,
        escalationLevel2: this.checkLevel2,
        escalationLevel3: this.checkLevel3,
        escalationLevel4: this.checkLevel4,
        escalationLevel5: this.checkLevel5,
        userManagement: this.checkUserManagement,
        excludeFromLevels: this.excludeFromLevels,
        allowedExportDealData: this.allowedExportDealData,
        readOnly: !this.checkDealsManagement,
        loggedInUser: this.userData.loggedInUser,
        rowVersion: this.userData.rowVersion,
        active: this.userData.active,
      };
      ApiService.saveUser(this.userId, JSON.stringify(formData)).then((response) => {
        if (response) {
          this.emitClose();
        }
      }).catch((error) => {
        // handle data changed conflict
        if (error.response.status === 409) {
          this.getDetailData(this.userId);
          this.$emit('userDataChanged');
        }
      });
    },
    createUser() {
      const formData = {
        userId: null,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.userEmail,
        region: this.region,
        businessLineLD: this.checkLd,
        businessLinePOC: this.checkPoc,
        verifier: this.checkVerifier,
        sales: this.checkSales,
        finance: this.checkFinance,
        escalationLevel1: this.checkLevel1,
        escalationLevel2: this.checkLevel2,
        escalationLevel3: this.checkLevel3,
        escalationLevel4: this.checkLevel4,
        escalationLevel5: this.checkLevel5,
        userManagement: this.checkUserManagement,
        excludeFromLevels: this.excludeFromLevels,
        allowedExportDealData: this.allowedExportDealData,
        readOnly: this.checkDealsManagement,
        loggedInUser: null,
        rowVersion: 0,
      };
      // console.log('formData', formData);
      ApiService.createUser(JSON.stringify(formData)).then((response) => {
        if (response) {
          this.emitClose();
        }
      }).catch((error) => this.setNotification(error.response.data.description, true));
    },
    setNotification(message, visible) {
      this.notificationMessage = message;
      this.notificationShown = visible;
    },
    sendToArchive() {
      console.log('sending user to archive...');
      this.userData.active = false;
      this.saveUser();
    },
    sendBackToUserManagement() {
      console.log('sending back to user management...');
      this.userData.active = true;
      this.saveUser();
    },
  },
  computed: {
    hasEditRight() {
      if (this.userData.active) {
        const userPerms = this.$store.state.loggedInUser.perms;
        return userPerms != null && userPerms.includes('USER_MANAGEMENT');
      }
      return false;
    },
    isReadOnly() {
      return this.$store.state.userReadOnly;
    },
  },
};
</script>

<style lang="scss">
.user-detail {
  .v-card__title {
    word-break: normal;
  }
  .escalation-level {
    .v-list-item {
      .v-input__slot {
        // margin-bottom: 8px;
        .v-messages {
          // min-height: 0;
        }
      }
    }
  }
  .v-list-item {
    min-height: 0;
    .v-list-item__avatar {
      align-self: flex-start;
    }
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}
</style>
