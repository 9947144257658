<template>
  <div class="quote-data">
    <!-- Quote data section-->
    <v-card-title>
      <h5 class="clickableElement" @click="showQuote = !showQuote">Quote Data</h5>
      <v-btn icon @click="showQuote = !showQuote" color="primary">
        <v-icon>{{ showQuote ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition>
      <div v-show="showQuote">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              Term (months)
            </v-list-item-subtitle>
            <v-list-item-title>
              {{ dealData.profitOverview.term ? dealData.profitOverview.term : '&ndash;' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-subtitle>
              Finance Option
            </v-list-item-subtitle>
            <v-list-item-title>
              {{ dealData.profitOverview.financeOption ? dealData.profitOverview.financeOption
              : '&ndash;' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <template v-if="dealData.escalationState === 'NEW'">
              <v-row>
                <v-col cols="12" class="py-0 col-md-6">
                  <v-select
                    v-model="billingOptions"
                    :rules="billingRules"
                    :items="['CPT', 'CPT-I', 'CPR', 'PARTIAL']"
                    label="Billing Options"
                    :disabled="isReadOnly"
                    @change="emitChangeBillingOption"
                  ></v-select>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-list-item-subtitle>
                Billing Option
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ dealData.billingOption ? dealData.billingOption : '&ndash;' }}
              </v-list-item-title>
            </template>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="dealData.pickTier">
          <v-list-item-content>
            <v-list-item-subtitle>
              Tier List
            </v-list-item-subtitle>
            <v-list-item-title v-for="(item, index) in dealData.pickTier.tierList" :key="index">
              {{ item }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'QuoteData',
  props: {
    dealData: Object,
  },
  data() {
    return {
      showQuote: true,
      billingOptions: 'EMPTY',
      billingRules: [
        (v) => !!v || '',
      ],
    };
  },
  mounted() {
    if (this.dealData.billingOption) {
      this.billingOptions = this.dealData.billingOption;
    }
  },
  computed: {
    isReadOnly() {
      return this.$store.state.userReadOnly;
    },
  },
  methods: {
    emitChangeBillingOption() {
      console.log('emit changeBillingOption', this.billingOptions);
      this.$emit('changeBillingOption', this.billingOptions);
    },
  },
};
</script>

<style lang="scss">

</style>
