<template>
  <v-list-item>
    <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
      <template  v-if="creatorFull">
        <span class="black--text">
          {{ creatorFull[0].initials }}
        </span>
      </template>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-subtitle>
        Q2B
      </v-list-item-subtitle>
      <v-list-item-title>
        {{ dealData.creator.firstName }} {{ dealData.creator.lastName }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: 'PeopleQ2b',
  props: {
    dealData: Object,
    creatorFull: Array,
  },
};
</script>
