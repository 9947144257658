<template>
  <div class="empty-card mb-6">
    <v-card
      class="pa-4"
      outlined
      tile
    >
      <div class="d-flex justify-center align-center">
        <p v-if="forUser" class="text-small my-7">No persons for this function at the moment.</p>
        <p v-else class="text-small my-7">No deals at the moment.</p>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'EmptyCard',
  props: {
    forUser: Boolean,
  },
};
</script>

<style lang="scss">
  .empty-card {
    p {
      color: #999;
    }
    .theme--light.v-card.v-card--outlined {
      border-color: #e6e6e6;
    }
  }
</style>
