<template>
  <div class="home-view">
    <shs-header
      :full-header="false"
      :is-user="false"
    >
    </shs-header>
    <v-container>
      <v-row>
        <v-col class="pt-10">
          <h1 class="text--primary pt-0 pt-sm-10">SHS Pricing Escalation App</h1>
          <p>The SHS Pricing Escalation App is an internal tool for Siemens Healthineers employees.<br>
            The application processes SmartExports from SHARE and transfers them into a digital
            approval process.</p>
          <h3 v-if="noAccess" class="pt-10">No Access to Application</h3>
          <h3 v-else class="pt-10">Go to Application</h3>
          <p v-if="noAccess">You currently don't have access to the Pricing Escalation App
            with the currently logged in user.</p>
          <p v-else>Access to the application requires a separate activation.</p>
          <v-row>
            <v-col cols="12" class="text-center text-sm-left">
              <template v-if="noAccess">
                <span class="d-block d-sm-inline-flex">
                  <v-btn
                    class="mr-sm-3 mb-sm-0 mr-0 mb-3"
                    rounded
                    outlined
                    @click="sendMailRequestAccess">
                    Request access</v-btn>
                </span>
                <span class="d-block d-sm-inline-flex">
                  <span>or</span>
                </span>
                <span class="d-block d-sm-inline-flex">
                  <v-btn
                    class="ml-sm-3 mt-sm-0 ml-0 mt-3"
                    rounded
                    outlined
                    @click="this.$auth.signOut">
                    Change Login
                  </v-btn>
                </span>
              </template>
              <template v-else>
                <span class="d-block d-sm-inline-flex">
                  <v-btn
                    class="mr-sm-3 mb-sm-0 mr-0 mb-3"
                    rounded
                    color="primary"
                    depressed
                    to="/board"
                  >
                    Login
                  </v-btn>
                </span>
                <span class="d-block d-sm-inline-flex">
                  <span>or</span>
                </span>
                <span class="d-block d-sm-inline-flex">
                  <v-btn
                    class="ml-sm-3 mt-sm-0 ml-0 mt-3"
                    rounded
                    outlined
                    @click="sendMailRequestAccess">
                    Request access</v-btn>
                </span>
              </template>
            </v-col>
          </v-row>
          <h3 class="pt-10">Contact</h3>
          <p>For general questions & feedback feel free to contact Tyler MacLean.<br>
            For any technical questions and support contact Samuel Kuhn.</p>
          <v-divider class="my-7"></v-divider>
          <v-row>
            <v-col cols="12" lg="6">
              <h5>Tyler MacLean</h5>
              <h6>SHS AM NAM FI DX</h6>
              <div class="d-block">
                <v-icon left color="primary">mdi-phone</v-icon>+1 (914) 255-8347
              </div>
              <div class="d-block">
                <v-icon left color="primary">mdi-email</v-icon>
                <a @click="sendMailMcLean">tyler.maclean@siemens-healthineers.com</a>
              </div>
            </v-col>
            <v-col cols="12" lg="6">
              <h5>Samuel Kuhn</h5>
              <h6>GBS B&S DE SOL GMS MSC MSO</h6>
              <div class="d-block">
                <v-icon left color="primary">mdi-phone</v-icon>+49 173 30 8787 5
              </div>
              <div class="d-block">
                <v-icon left color="primary">mdi-email</v-icon>
                <a @click="sendMailKuhn">samuel.kuhn@siemens.com</a>
              </div>
            </v-col>
          </v-row>
          <v-divider class="my-7"></v-divider>
        </v-col>
      </v-row>
    </v-container>
    <shs-footer
      :full-footer="false"
    ></shs-footer>
  </div>
</template>

<script>
import ShsFooter from '../components/Footer.vue';
import ShsHeader from '../components/Header.vue';

export default {
  name: 'Index',
  components: {
    ShsHeader,
    ShsFooter,
  },
  data() {
    return {
      showPassword: false,
      showNotification: false,
      noAccess: false,
    };
  },
  mounted() {
    if (this.$route.query.message === 'Noaccess') {
      this.noAccess = true;
    }
  },
  methods: {
    sendMailRequestAccess() {
      window.location = 'mailto:dxpricing-escalations.team@siemens-healthineers.com?subject=Request for access to SHS Pricing Escalation App';
    },
    sendMailMcLean() {
      window.location = 'mailto:tyler.maclean@siemens-healthineers.com';
    },
    sendMailKuhn() {
      window.location = 'mailto:samuel.kuhn@siemens.com';
    },
  },
};
</script>

<style lang="scss">
  @import 'src/scss/colors';
  .v-application {
    .home-view {
      h1, h2, h3, h6 {
        margin-bottom: 8px;
      }
      .v-icon + a {
        color: $gray-shade-4;
        &:hover {
          color: $orange;
        }
      }
    }
  }
</style>
