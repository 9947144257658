<template>
  <div class="product-data">
    <v-card-title>
      <h5 class="clickableElement" @click="showProduct = !showProduct">Product Data</h5>
      <v-btn icon @click="showProduct = !showProduct" color="primary">
        <v-icon>{{ showProduct ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-expand-transition>
      <!-- Product Data-->
      <div v-show="showProduct">
        <template v-if="dealData.quoteType.ld">
          <v-list-item>
            <v-list-item-title class="font-weight-bold">
              Laboratory Diagnostics (LD)
            </v-list-item-title>
          </v-list-item>
          <v-data-table class="shs-table px-4"
            :headers="tableData.productLineTableLd.headers"
            :items="indexedItemsLd"
            :single-expand="tableData.productLineTableLd.singleExpand"
            :expanded.sync="tableData.productLineTableLd.expanded"
            item-key="id"
            disable-pagination
            disable-sort
            show-expand
            :hide-default-footer="true"
            @click:row="expandTableLdRow"
          >
            <template v-slot:expanded-item="{ item }">
              <td colspan="4" v-if="!$vuetify.breakpoint.smAndDown"></td>
              <table class="expanded-items">
                <template v-if="getCollapsibleItems(item) && getCollapsibleItems(item).length">
                  <thead>
                  <tr>
                    <th>INS / RCO</th>
                    <th>Floor Price / Discount</th>
                    <th>Quote Price</th>
                  </tr>
                  </thead>
                </template>
                <template v-else>
                  <thead>
                  <tr>
                    <th>No floor price violations.</th>
                  </tr>
                  </thead>
                </template>
                <tbody>
                  <template v-for="(tableItem, index) in getCollapsibleItems(item)">
                    <tr v-if="tableItem && tableItem.name" :key="'A' + index">
                      <td class="text-start">{{ tableItem.name }}</td>
                      <td class="text-start">{{ tableItem.floorPrice | toCurrency }}</td>
                      <td class="text-start">{{ tableItem.cpNet | toCurrency }}</td>
                    </tr>
                    <tr v-else-if="tableItem && (tableItem.rcosName || tableItem.serviceLevel)" :key="'B' + index">
                      <!-- eslint-disable-next-line max-len -->
                      <td class="text-start">{{ tableItem.serviceLevel == null ? tableItem.rcosName : tableItem.serviceLevel}}</td>
                      <td class="text-start">{{ tableItem.discount }}%</td>
                      <!-- eslint-disable-next-line max-len -->
                      <td class="text-start">{{ (tableItem.serviceLevel == null ? tableItem.cpUos : tableItem.cpYear) | toCurrency }}</td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <td v-if="!$vuetify.breakpoint.smAndDown"></td>
            </template>
            <template v-slot:item.vcai="{ item }">
              <template v-if="item.profitabilityViolation == null">
                N/A
              </template>
              <template v-else>
                <span
                  v-if="item.profitabilityViolation === 'ABOVE_TARGET_LIMIT'"
                  class="success--text"
                >{{ item.vcai }}%</span>
                  <span
                    v-else-if="item.profitabilityViolation === 'BELOW_0_PERCENT'"
                    class="error--text"
                  >{{ item.vcai }}%</span>
                  <span
                    v-else-if="item.profitabilityViolation === 'ABOVE_0_PERCENT'"
                    class="error--text"
                  >{{ item.vcai }}%</span>
                  <span
                    v-else-if="item.profitabilityViolation === 'ABOVE_TRIGGER'"
                    class="minor-warning--text"
                  >{{ item.vcai }}%</span>
                  <span
                    v-else
                  >{{ item.vcai }}</span>
              </template>
            </template>
            <template v-slot:item.revenueTotal="{ item }">
              {{ item.revenueTotal | toCurrency }}
            </template>
            <template v-slot:item.fp-violation="{ item }">
              <template v-if="item.profitabilityViolation == null && getCollapsibleItems(item).length === 0">
                set manually below
              </template>
              <template v-else>
               <span
                 v-if="getCollapsibleItems(item) && getCollapsibleItems(item).length"
               >YES</span>
                <span
                  v-else
                >NO</span>
              </template>
            </template>
            <template v-slot:item.value-add="{ item }">
              <template v-if="item.valueAdd && valueAddProductSums.length > 0">
                {{ getProductValueAdd(item) | toCurrency }}
              </template>
              <template v-else-if="!item.valueAdd && valueAddProductSums.length > 0">
                {{ getProductValueAdd(item) | toCurrency }}
              </template>
              <template v-else-if="item.valueAdd && !valueAddProductSums.length > 0">
                {{ item.valueAdd | toCurrency }}
              </template>
              <template v-else>&ndash;</template>
            </template>
          </v-data-table>
          <v-list-item class="mb-6">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                LD Value Adds Total
                <span class="font-weight-regular ml-4" v-if="sumValueAddColumnLd">
                  {{ sumValueAddColumnLd | toCurrency }}
                </span>
                <span class="font-weight-regular ml-4" v-else>
                  &ndash;
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="checkAutomation"
              :disabled="isNotNew || isReadOnly"
              label="IT components violation"
              @change="emitChangeAutomation"
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="checkAptio"
              :disabled="isNotNew || isReadOnly"
              label="Aptio components violation"
              @change="emitChangeAptio"
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="checkAtellica"
              :disabled="isNotNew || isReadOnly"
              label="Atellica SC components violation"
              @change="emitChangeAtellica"
            ></v-checkbox>
          </v-list-item>
          <v-list-item>
            <v-checkbox
              class="mt-0"
              v-model="checkAccessories"
              :disabled="isNotNew || isReadOnly"
              label="Accessories, Peripheral, Software violation"
              @change="emitChangeAccessories"
            ></v-checkbox>
          </v-list-item>
        </template>
        <template v-if="dealData.quoteType.poc">
          <v-list-item class="mt-10">
            <v-list-item-title class="font-weight-bold">
              Point-of-Care Testing (POC)
            </v-list-item-title>
          </v-list-item>
          <v-data-table class="shs-table px-4"
                        :headers="tableData.productLineTablePoc.headers"
                        :items="indexedItemsPoc"
                        :single-expand="tableData.productLineTablePoc.singleExpand"
                        :expanded.sync="tableData.productLineTablePoc.expanded"
                        item-key="id"
                        disable-pagination
                        disable-sort
                        show-expand
                        :hide-default-footer="true"
                        @click:row="expandTablePocRow"
          >
            <template v-slot:expanded-item="{ item }"
            >
              <td colspan="3" v-if="!$vuetify.breakpoint.smAndDown"></td>
              <table class="expanded-items">
                <template v-if="getCollapsibleItems(item) && getCollapsibleItems(item).length">
                  <thead>
                  <tr>
                    <th>INS / RCO</th>
                    <th>Floor Price / Discount</th>
                    <th>Quote Price</th>
                  </tr>
                  </thead>
                </template>
                <template v-else>
                  <thead>
                  <tr>
                    <th>No floor price violations.</th>
                  </tr>
                  </thead>
                </template>
                <tbody>
                  <template v-for="(tableItem, index) in getCollapsibleItems(item)">
                    <tr v-if="tableItem && tableItem.name" :key="'A' + index">
                      <td class="text-start">{{ tableItem.name }}</td>
                      <td class="text-start">{{ tableItem.floorPrice | toCurrency }}</td>
                      <td class="text-start">{{ tableItem.cpNet | toCurrency }}</td>
                    </tr>
                    <tr v-else-if="tableItem && (tableItem.rcosName || tableItem.serviceLevel)" :key="'B' + index">
                      <!-- eslint-disable-next-line max-len -->
                      <td class="text-start">{{ tableItem.serviceLevel == null ? tableItem.rcosName : tableItem.serviceLevel}}</td>
                      <td class="text-start">{{ tableItem.discount }}%</td>
                      <!-- eslint-disable-next-line max-len -->
                      <td class="text-start">{{ (tableItem.serviceLevel == null ? tableItem.cpUos : tableItem.cpYear) | toCurrency }}</td>
                    </tr>
                </template>
                </tbody>
              </table>
              <td v-if="!$vuetify.breakpoint.smAndDown"></td>
            </template>
            <template v-slot:item.vcai="{ item }">
              <template v-if="item.profitabilityViolation == null">
                N/A
              </template>
              <template v-else>
               <span
                 v-if="item.profitabilityViolation === 'ABOVE_TARGET_LIMIT'"
                 class="success--text"
               >{{ item.vcai }}%</span>
                <span
                  v-else-if="item.profitabilityViolation === 'BELOW_0_PERCENT'"
                  class="error--text"
                >{{ item.vcai }}%</span>
                <span
                  v-else-if="item.profitabilityViolation === 'ABOVE_0_PERCENT'"
                  class="error--text"
                >{{ item.vcai }}%</span>
                <span
                  v-else-if="item.profitabilityViolation === 'ABOVE_TRIGGER'"
                  class="info--text"
                >{{ item.vcai }}%</span>
                <span
                  v-else
                >{{ item.vcai }}</span>
              </template>
            </template>
            <template v-slot:item.fp-violation="{ item }">
              <template v-if="item.profitabilityViolation == null && getCollapsibleItems(item).length == 0">
                set manually below
              </template>
              <template v-else>
               <span
                 v-if="getCollapsibleItems(item) && getCollapsibleItems(item).length"
               >YES</span>
                <span
                  v-else
                >NO</span>
              </template>
            </template>
            <template v-slot:item.value-add="{ item }">
              <template v-if="item.valueAdd && valueAddProductSums.length > 0">
                {{ getProductValueAdd(item) | toCurrency }}
              </template>
              <template v-else-if="!item.valueAdd && valueAddProductSums.length > 0">
                {{ getProductValueAdd(item) | toCurrency }}
              </template>
              <template v-else-if="item.valueAdd && !valueAddProductSums.length > 0">
                {{ item.valueAdd | toCurrency }}
              </template>
              <template v-else>&ndash;</template>
            </template>
          </v-data-table>
          <v-list-item class="mb-6">
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                POC Value Adds Total
                <span class="font-weight-regular ml-4" v-if="sumValueAddColumnPoc">
                    {{ sumValueAddColumnPoc | toCurrency }}
                </span>
                <span class="font-weight-regular ml-4" v-else>
                    &ndash;
                </span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="mb-6">
            <v-checkbox
              class="mt-0"
              v-model="checkDcaComp"
              :disabled="isNotNew || isReadOnly"
              label="DCA components violation"
              @change="emitChangeDcaDiscount"
            ></v-checkbox>
          </v-list-item>
        </template>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: 'ProductData',
  props: {
    dealData: Object,
    valueAddProductSums: Array,
  },
  data() {
    return {
      showProduct: true,
      checkAutomation: false,
      checkAptio: false,
      checkAtellica: false,
      checkDcaComp: false,
      checkAccessories: false,
      tableData: {
        productLineTableLd: {
          expanded: [],
          singleExpand: false,
          headers: [
            {
              text: 'Product Line',
              value: 'level',
              width: '200px',
            },
            {
              text: 'VCAI %',
              value: 'vcai',
              width: '90px',
            },
            {
              text: 'Revenue Total',
              value: 'revenueTotal',
              width: '100px',
            },
            {
              text: 'Value Add',
              value: 'value-add',
              width: '140px',
            },
            {
              text: 'FP Violation',
              value: 'fp-violation',
            },
            {
              text: '',
              value: 'data-table-expand',
            },
          ],
          items: [],
        },
        productLineTablePoc: {
          expanded: [],
          singleExpand: false,
          headers: [
            {
              text: 'Product Line',
              value: 'level',
              width: '240px',
            },
            {
              text: 'VCAI %',
              value: 'vcai',
              width: '140px',
            },
            {
              text: 'Value Add',
              value: 'value-add',
              width: '140px',
            },
            {
              text: 'FP Violation',
              value: 'fp-violation',
            },
            {
              text: '',
              value: 'data-table-expand',
            },
          ],
          items: [],
        },
      },
      valueAddSumLd: [],
      valueAddSumPoc: [],
    };
  },
  mounted() {
    if (this.dealData.acceptAtellicaSCdiscountLD) {
      this.checkAtellica = this.dealData.acceptAtellicaSCdiscountLD;
    }
    if (this.dealData.acceptAptioDiscountLD) {
      this.checkAptio = this.dealData.acceptAptioDiscountLD;
    }
    if (this.dealData.acceptAutomationITdiscountLD) {
      this.checkAutomation = this.dealData.acceptAutomationITdiscountLD;
    }
    if (this.dealData.acceptDCAdiscountPOC) {
      this.checkDcaComp = this.dealData.acceptDCAdiscountPOC;
    }
    if (this.dealData.acceptAccessoriesPeripherealSoftwareDiscountLD) {
      this.checkAccessories = this.dealData.acceptAccessoriesPeripherealSoftwareDiscountLD;
    }
    if (this.dealData.profitOverview.productVcai && this.dealData.quoteType.ld) {
      this.tableData.productLineTableLd.items = this.getProductTableDataLD(this.dealData.profitOverview.productVcai);
      // console.log('tableData', this.tableData.productLineTableLd.items);
    }
    if (this.dealData.profitOverview.productVcai && this.dealData.quoteType.poc) {
      this.tableData.productLineTablePoc.items = this.getProductTableDataPOC(this.dealData.profitOverview.productVcai);
      // console.log('tableData', this.tableData.productLineTablePoc.items);
    }
  },
  methods: {
    expandTableLdRow(item) {
      const expandedRows = this.tableData.productLineTableLd.expanded;
      this.expandRow(item, expandedRows);
    },
    expandTablePocRow(item) {
      const expandedRows = this.tableData.productLineTablePoc.expanded;
      this.expandRow(item, expandedRows);
    },
    expandRow(item, expandedRows) {
      // check if item row is already expanded
      const found = expandedRows.some((el) => el.id === item.id);
      if (found) {
        // remove item from expanded rows
        expandedRows.splice(expandedRows.findIndex((el) => el.id === item.id), 1);
      } else {
        expandedRows.push(item);
      }
    },
    emitChangeAutomation() {
      this.$emit('changeAutomation', this.checkAutomation);
    },
    emitChangeAptio() {
      this.$emit('changeAptio', this.checkAptio);
    },
    emitChangeAtellica() {
      this.$emit('changeAtellica', this.checkAtellica);
    },
    emitChangeDcaDiscount() {
      this.$emit('changeDcaDiscount', this.checkDcaComp);
    },
    emitChangeAccessories() {
      this.$emit('changeAccessories', this.checkAccessories);
    },
    getProductTableDataLD(items) {
      return items.filter((item) => item.productType === 'LD');
    },
    getProductTableDataPOC(items) {
      return items.filter((item) => item.productType === 'POC');
    },
    getProductValueAdd(tableItem) {
      let sum = 0;
      const filtered = this.valueAddProductSums.filter((item) => item.product === tableItem.level);
      if (filtered.length) {
        sum = filtered[0].sum;
        if (tableItem.productType === 'LD') {
          this.setValueAddValueLd(sum, tableItem.id);
        }
        if (tableItem.productType === 'POC') {
          this.setValueAddValuePoc(sum, tableItem.id);
        }
      }
      return sum;
    },
    setValueAddValueLd(value, id) {
      this.tableData.productLineTableLd.items[id].valueAdd = value;
    },
    setValueAddValuePoc(value, id) {
      this.tableData.productLineTablePoc.items[id].valueAdd = value;
    },
    getCollapsibleItems(tableItem) {
      const collapsedData = [];

      // eslint-disable-next-line max-len
      const priceEquipSoftware = this.dealData.priceEquipSoftware?.equipmentSoftwares.filter((item) => item.spl === tableItem.level);
      if (priceEquipSoftware) collapsedData.push(...priceEquipSoftware);
      // eslint-disable-next-line max-len
      const priceReagents = this.dealData.priceReagents?.reagents.filter((item) => item.spl === tableItem.level);
      if (priceReagents) collapsedData.push(...priceReagents);
      // eslint-disable-next-line max-len
      const priceServices = this.dealData.priceServices?.services.filter((item) => item.spl === tableItem.level);
      if (priceServices) collapsedData.push(...priceServices);
      // eslint-disable-next-line max-len
      const priceConsumables = this.dealData.priceConsumables?.consumables.filter((item) => item.spl === tableItem.level);
      if (priceConsumables) collapsedData.push(...priceConsumables);

      // console.log('collapsedData', collapsedData);
      return collapsedData;
    },
  },
  computed: {
    isNotNew() {
      return this.dealData.escalationState !== 'NEW';
    },
    indexedItemsLd() {
      return this.tableData.productLineTableLd.items.map((item, index) => ({
        id: index,
        ...item,
      }));
    },
    indexedItemsPoc() {
      return this.tableData.productLineTablePoc.items.map((item, index) => ({
        id: index,
        ...item,
      }));
    },
    sumValueAddColumnLd() {
      return this.tableData.productLineTableLd.items.reduce((acc, item) => acc + item.valueAdd, 0);
    },
    sumValueAddColumnPoc() {
      return this.tableData.productLineTablePoc.items.reduce((acc, item) => acc + item.valueAdd, 0);
    },
    isReadOnly() {
      return this.$store.state.userReadOnly;
    },
  },
};
</script>

<style lang="scss">
  .product-data {
    .hide-on-mobile {
      display: none;
    }
    .expanded-items {
      tbody tr:nth-child(odd) {
          background-color: #e6e6e6;
      }
    }
    tr:hover {
      cursor: pointer;
      opacity: .9;
    }
    .expanded-items tr:hover, .v-data-table__expanded__content:hover {
      cursor: default;
      opacity: 1;
    }
/*    .v-data-table__mobile-table-row {
      :nth-child(1) {
        order: 1;
      }
      :nth-child(2) {
        order: 2;
      }
      :nth-child(4) {
        order: 3;
      }
      :nth-child(3) {
        order: 4;
      }
      :nth-child(5) {
        order: 5;
      }
    }*/
    .v-input__slot {
      margin-bottom: 0;
    }
  }
  @media screen and (min-width: 400px) {
    .product-data {
      .hide-on-mobile {
        display: block;
      }
    }
  }
</style>
