<template>
  <v-row align="center" justify="center">
    <v-dialog :fullscreen="$vuetify.breakpoint.mdAndDown"
      v-model="dialog"
      max-width="1280"
    >
      <div class="deal-card__upload">
        <v-card
         class="mx-auto deal-upload pa-4"
         max-width="1280"
         light
         tile
        >
          <div class="align-top-right pa-7">
            <v-btn icon
                   @click="closeOverlay"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <section>
            <template v-if="error">
              <div class="d-flex flex-column flex-md-row mb-5 mt-12 text-center text-md-left">
                <v-icon size="60" color="#FFD200" class="mb-5 mr-md-5">mdi-alert-circle</v-icon>
                <p class="header-5 mb-0">Sorry, but this file cannot be processed!<br>
                  Please make sure that the file is a standard Smart Export in .xlsm format.
                  .xls files have to be converted before.</p>
              </div>
            </template>
            <template v-else>
              <div class="header-5 mb-5 mt-12">To begin escalating a new deal simply drop your Smart Export Excel
                file here. <br>Please make sure that the file is in .xlsm format.
                .xls files have to be converted before.</div>
            </template>
            <div ref="dropzone" class="drop-zone mb-12 d-flex justify-center align-center">
              <template v-if="uploadReady">
                  <v-icon x-large class="mb-5 hidden-sm-and-down">mdi-upload</v-icon>
                  <div class="header-5 hidden-sm-and-down">Drag and Drop here</div>
                  <div class="header-5 mb-5 hidden-sm-and-down">or</div>
                  <v-btn
                    outlined
                    rounded
                    @click="selectFile"
                  >Browse file</v-btn>
                  <input
                    ref="uploader"
                    class="d-none"
                    type="file"
                    @change="onFileChanged"
                    >
              </template>
              <template v-if="uploadAnalyzing">
                <spinner :fullscreen="false"/>
                <div class="header-5 mt-5">Please wait while your file is being analyzed...</div>
              </template>
            </div>
          </section>
        </v-card>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import ApiService from '@/services/ApiService';
import Spinner from './Spinner.vue';

export default {
  name: 'DealUpload',
  components: {
    Spinner,
  },
  props: {
    files: Array,
  },
  data() {
    return {
      dialog: false,
      uploadReady: true,
      uploadAnalyzing: false,
      fileToUpload: '',
      error: false,
      selectedFile: null,
      isSelecting: false,
      dragAndDropCapable: false,
      filesDropped: [],
    };
  },
  mounted() {
    this.dialog = true;
    if (typeof this.files !== 'undefined' && this.files.length) {
      this.checkFileFormat(this.files[0]);
    }
    this.$nextTick(() => {
      this.dragAndDropCapable = this.determineDragAndDropCapable();

      if (this.dragAndDropCapable) {
        ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach((evt) => {
          this.$refs.dropzone.addEventListener(evt, (e) => {
            e.preventDefault();
            e.stopPropagation();
          }, false);
        });

        this.$refs.dropzone.addEventListener('drop', (e) => {
          this.error = false;
          for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
            this.filesDropped.push(e.dataTransfer.files[i]);
            this.checkFileFormat(this.filesDropped[0]);
          }
        });
      }
    });
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.closeOverlay();
      }
    },
  },
  methods: {
    closeOverlay() {
      console.log('..closing');
      if (typeof this.files === 'undefined') {
        this.$router.push({ path: '/board' }).then(() => {
          this.dialog = false;
        }).catch((err) => {
          throw new Error(`Error occurred redirecting to /board: ${err}.`);
        });
      }
      this.emitUploadDialogClosed();
      this.dialog = false;
    },
    checkFileFormat(file) {
      // eslint-disable-next-line prefer-destructuring
      this.fileToUpload = file;
      if (/\.(xlsm)$/i.test(this.fileToUpload.name)) {
        this.uploadDeal();
      } else {
        this.error = true;
        this.filesDropped = [];
      }
    },
    uploadDeal() {
      this.uploadReady = false;
      this.uploadAnalyzing = true;
      const formData = new FormData();
      formData.append('file', this.fileToUpload);
      ApiService.uploadDeal(formData).then((response) => {
        if (response) {
          // console.log('Yay...', response);
          if (response.status === 200) {
            this.fileToUpload = '';
            this.$router.push({ path: `/board/${response.data}` }).then(() => {
              this.dialog = false;
            }).catch((err) => {
              throw new Error(`Error occurred redirecting to /board/${response.data}: ${err}.`);
            });
          } else if (response.code === 'E400-001') {
            this.error = true;
          }
        }
      })
        .catch((error) => {
          console.log('Error:', error);
        }).finally(() => {
          this.emitUploadComplete();
        });
    },
    selectFile() {
      this.isSelecting = true;
      window.addEventListener('focus', () => {
        this.isSelecting = false;
      }, { once: true });
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedFile = e.target.files[0];
      this.checkFileFormat(this.selectedFile);
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div');

      return (('draggable' in div)
        || ('ondragstart' in div && 'ondrop' in div))
        && 'FormData' in window && 'FileReader' in window;
    },
    emitUploadComplete() {
      this.$emit('uploadCompleted');
    },
    emitUploadDialogClosed() {
      this.$emit('uploadDialogClosed');
    },
  },
};
</script>

<style lang="scss">
  .deal-card {
    &__upload {
      .drop-zone {
        flex-direction: column;
        border: none;
        min-height: 200px;
      }
    }
  }
  @media screen and (min-width: 400px) {
    .deal-card {
      &__upload {
        .drop-zone {
          flex-direction: column;
          border: 3px dashed #EC6602;
          width: 100%;
          min-height: 363px;
        }
      }
    }
  }
</style>
