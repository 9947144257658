<template>
  <div class="done-labels">
    <template v-if="isDetailView">
      <!-- Level -->
      <v-chip
        class="ml-3 mb-2"
        label
        :class="[dealData.escalationlevelOverwrite === 3
         || dealData.escalationlevelOverwrite === 5 ? 'text--white' : '',
             this.isManuallyChanged ? `border--dotted level--${dealData.escalationlevelOverwrite}` : '']"
        :color="getLevelData(dealData.escalationlevelOverwrite)"
      >
        Level {{ dealData.escalationlevelOverwrite }}
      </v-chip>
      <!-- Combo Deal -->
      <template v-if="dealData.comboDealId">
        <v-chip
          class="ml-3 mb-2"
          label
          color="#2B2483"
          text-color="white"
        >
          Combo Deal
        </v-chip>
      </template>
      <!-- Standard Deal -->
      <template v-if="dealData.distributorStandardAgreement">
        <v-chip
          class="ml-3 mb-2"
          label
          color="#e6e6e6"
        >
          Distributor deal
        </v-chip>
      </template>
      <!-- Manually set level -->
      <template v-if="isManuallyChanged">
        <v-chip
          class="ml-3 mb-2"
          label
          color="#e6e6e6"
        >
          Manually set level
        </v-chip>
      </template>
      <!-- Inquiry waiting -->
      <template v-if="dealData.inquiry">
        <v-chip
          class="ml-3 mb-2"
          label
          color="#FFD200"
        >
          Awaiting reply
        </v-chip>
      </template>
    </template>
    <template v-else>
      <template v-if="dealData.escalationState === 'ARCHIVE'
       || dealData.escalationState === 'DONE'">
        <div class="d-flex justify-space-between">
          <!-- Level -->
          <v-chip
            class="ml-3 mb-2"
            label
            :class="[dealData.escalationlevelOverwrite === 3
             || dealData.escalationlevelOverwrite === 5 ? 'text--white' : '',
             this.isManuallyChanged ? `border--dotted level--${dealData.escalationlevelOverwrite}` : '']"
            :color="getLevelData(dealData.escalationlevelOverwrite)"
          >
            Level {{ dealData.escalationlevelOverwrite }}
          </v-chip>
          <!-- Combo Deal -->
          <template v-if="dealData.comboDealId">
            <v-chip
              class="ml-3 mb-2"
              label
              color="#2B2483"
              text-color="white"
            >
              Combo Deal
            </v-chip>
          </template>
          <template v-if="dealData.escalationState !== 'DONE'
           && dealData.escalationState !== 'ARCHIVE'">
            <!-- Standard Deal -->
            <template v-if="dealData.distributorStandardAgreement">
              <v-chip
                class="ml-3 mb-2"
                label
                color="#e6e6e6"
              >
                Distributor deal
              </v-chip>
            </template>
          </template>
          <template v-if="dealData.escalationState === 'DONE'">
            <v-btn
              icon
              color="#666"
              :disabled="isReadOnly"
              @click.native.stop.prevent="sendToArchive"
            >
              <v-icon
                size="24"
              >mdi-archive</v-icon>
            </v-btn>
          </template>
          <template v-if="dealData.escalationState === 'ARCHIVE'">
            <v-row justify="end" class="mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    color="#666"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="isReadOnly"
                    @click.native.stop.prevent="sendBackToDone"
                  >
                    <v-icon
                      size="24"
                    >mdi-undo</v-icon>
                  </v-btn>
                </template>
                <span>Restore to board</span>
              </v-tooltip>
            </v-row>
          </template>
        </div>
        <template v-if="dealData.escalationState === 'DONE'
         || dealData.escalationState === 'ARCHIVE'">
          <!-- Standard Deal -->
          <template v-if="dealData.distributorStandardAgreement">
            <v-chip
              class="ml-3 mb-2"
              label
              color="#e6e6e6"
            >
              Distributor deal
            </v-chip>
          </template>
        </template>
      </template>
      <template v-else>
        <!-- Level -->
        <v-chip
          class="ml-3 mb-2"
          label
          :class="[dealData.escalationlevelOverwrite === 3
           || dealData.escalationlevelOverwrite === 5 ? 'text--white' : '',
             this.isManuallyChanged ? `border--dotted level--${dealData.escalationlevelOverwrite}` : '']"
          :color="getLevelData(dealData.escalationlevelOverwrite)"
        >
          Level {{ dealData.escalationlevelOverwrite }}
        </v-chip>
        <!-- Combo Deal -->
        <template v-if="dealData.comboDealId">
          <v-chip
            class="ml-3 mb-2"
            label
            color="#2B2483"
            text-color="white"
          >
            Combo Deal
          </v-chip>
        </template>
        <!-- Standard Deal -->
        <template v-if="dealData.distributorStandardAgreement">
          <v-chip
            class="ml-3 mb-2"
            label
            color="#e6e6e6"
          >
            Distributor deal
          </v-chip>
        </template>
        <!-- Inquiry waiting -->
        <template v-if="dealData.inquiry">
          <v-chip
            class="ml-3 mb-2"
            label
            color="#FFD200"
          >
            Awaiting reply
          </v-chip>
        </template>
        <template v-if="dealData.escalationState === 'DONE'">
          <v-btn
            icon
            color="#666"
            :disabled="isReadOnly"
            @click.native.stop.prevent="sendToArchive"
          >
            <v-icon
              size="24"
            >mdi-archive</v-icon>
          </v-btn>
        </template>
        <template v-if="dealData.escalationState === 'ARCHIVE'">
          <v-row justify="end" class="mr-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="#666"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="isReadOnly"
                  @click.native.stop.prevent="sendBackToDone"
                >
                  <v-icon
                    size="24"
                  >mdi-undo</v-icon>
                </v-btn>
              </template>
              <span>Restore to board</span>
            </v-tooltip>
            <!--<v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                        icon
                        color="#666"
                        v-bind="attrs"
                        v-on="on"
                        @click.native.stop.prevent="deleteDeal"
                >
                  <v-icon
                          size="24"
                  >mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>delete deal permanently</span>
            </v-tooltip>-->
          </v-row>
        </template>
      </template>
    </template>
    <!-- Approved -->
    <template v-if="dealData.doneStatus === 'APPROVED'">
      <v-chip
        class="ml-3 mb-2"
        label
        color="#009A38"
        text-color="white"
      >
        Approved
      </v-chip>
    </template>
    <!-- Declined -->
    <template v-else-if="dealData.doneStatus === 'DECLINED'">
      <v-chip
        class="ml-3 mb-2"
        label
        color="#7A162D"
        text-color="white"
      >
        Declined
      </v-chip>
    </template>
    <!-- Cancelled -->
    <template v-else-if="dealData.doneStatus === 'CANCELLED'">
      <v-chip
        class="ml-3 mb-2"
        label
        color="#7A162D"
        text-color="white"
      >
        Cancelled
      </v-chip>
    </template>
  </div>
</template>

<script>
import ApiService from '../../services/ApiService';
import dealFunctions from '../../mixins/DealFunctions';

export default {
  name: 'DoneLabels',
  mixins: [
    dealFunctions,
  ],
  props: {
    dealData: Object,
    isDetailView: Boolean,
  },
  methods: {
    sendToArchive() {
      let dealId;
      if (this.dealData.id) dealId = this.dealData.id;
      if (this.dealData.quoteId) dealId = this.dealData.quoteId;
      const data = { comment: '', rowVersion: this.dealData.rowVersion };
      ApiService.setEscalationState(dealId, 'ARCHIVED', JSON.stringify(data)).then((response) => {
        if (response) {
          console.log('deal archived', response.data);
          if (this.isDetailView) this.emitClose();
          else this.emitReload();
        }
      }).catch((error) => console.log(error));
    },
    sendBackToDone() {
      console.log('sending deal back to done...');
      let dealId;
      if (this.dealData.id) dealId = this.dealData.id;
      if (this.dealData.quoteId) dealId = this.dealData.quoteId;
      const data = { comment: '', rowVersion: this.dealData.rowVersion };
      ApiService.setEscalationState(dealId, 'BACK_TO_DONE', JSON.stringify(data)).then((response) => {
        if (response) {
          console.log('deal back in done', response.data);
          if (this.isDetailView) this.emitClose();
          else this.emitReload();
        }
      }).catch((error) => console.log(error));
    },
    deleteDeal() {
      console.log('deleting deal...');
      let dealId;
      if (this.dealData.id) dealId = this.dealData.id;
      if (this.dealData.quoteId) dealId = this.dealData.quoteId;
      const data = { comment: '', rowVersion: this.dealData.rowVersion };
      ApiService.setEscalationState(dealId, 'TRASH', JSON.stringify(data)).then((response) => {
        if (response) {
          console.log('deal deleted', response.data);
          if (this.isDetailView) this.emitClose();
          else this.emitReload();
        }
      }).catch((error) => console.log(error));
    },
    emitClose() {
      this.$emit('closeToParent');
    },
    emitReload() {
      this.$emit('reloadOverview');
    },
  },
  computed: {
    isReadOnly() {
      return this.$store.state.userReadOnly;
    },
  },
};
</script>

<style lang="scss">
  @import "src/scss/colors";
</style>
