<template>
  <v-row align="center" justify="center">
    <v-dialog :fullscreen="$vuetify.breakpoint.mdAndDown"
              v-model="dialog"
              max-width="1280"
    >
      <template slot="default">
        <user-detail
          :user-id="parseInt($route.params.id)"
          v-on:closeToParent="closeOverlay"
        ></user-detail>
      </template>
    </v-dialog>
  </v-row>
</template>

<script>
import UserDetail from '@/views/user/UserDetail.vue';

export default {
  name: 'OverlayUserArchiveDetail',
  components: {
    UserDetail,
  },
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {
    this.dialog = true;
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.closeOverlay();
      }
    },
  },
  methods: {
    closeOverlay() {
      // console.log('..closing user archive details');
      this.$router.push({ path: '/user-archive' }).then(() => {
        this.dialog = false;
      }).catch((err) => {
        throw new Error(`Error occurred redirecting to /user-archive: ${err}.`);
      });
    },
  },
};
</script>
