import axios from 'axios';
import { getMSALAccessToken } from '../plugins/auth';

const config = {
  withCredentials: true,
  responseType: 'json',
};

const configBlob = {
  exposedHeaders: ['Content-Disposition'],
  withCredentials: true,
  responseType: 'blob',
};

const configFiles = {
  withCredentials: true,
  headers: {
    'content-type': 'multipart/form-data',
  },
};

const configPost = {
  withCredentials: true,
  headers: {
    'content-type': 'application/json',
  },
};

axios.interceptors.request.use(async (requestConfig) => {
  const accessToken = await getMSALAccessToken();
  requestConfig.headers.Authorization = `Bearer ${accessToken}`; // eslint-disable-line no-param-reassign
  return requestConfig;
}, (error) => Promise.reject(error));

axios.interceptors.response.use((response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      if (localStorage.getItem('currentUser')) {
        localStorage.removeItem('currentUser');
      }
      // eslint-disable-next-line no-restricted-globals
      location.href = '/?message=Noaccess';
    }
    return Promise.reject(error);
  });

const ApiService = {

  // fetching all data
  fetchingAllDealsComplete() {
    return axios.get('/quotes/overview', config);
  },

  // fetching data of current user
  fetchAllDeals() {
    return axios.get('/quotes/overview/user', config);
  },

  // fetching data of user
  fetchDealsByUser(userId) {
    return axios.get(`/quotes/overview/user/${userId}`, config);
  },

  fetchArchivedDeals(searchTerm) {
    return axios.get(`/quotes/overview/archive?search=${searchTerm}`, config);
  },

  fetchDealDetails(id) {
    return axios.get(`quotes/${id}`, config);
  },

  fetchComboDeals(dealId) {
    return axios.get(`quotes/comboDealByQuote/${dealId}`, config);
  },

  fetchComboDeal(comboDealId) {
    return axios.get(`quotes/comboDeal/${comboDealId}`, config);
  },

  // upload file
  uploadDeal(file) {
    return axios.post('/quotes/uploadSmartExportFile', file, configFiles);
  },

  setEscalationState(quoteId, action, comment) {
    return axios.put(`quotes/${quoteId}/escalationState/${action}`, comment, configPost);
  },

  setEscalationLevel(quoteId, level, rowVersion) {
    return axios.put(`quotes/${quoteId}/level?escalationLevel=${level}&rowVersion=${rowVersion}`, {}, configPost);
  },

  getApprovers(level, shsFunction, quoteType) {
    return axios.get(`users/approvers/function/${shsFunction}/level/LEVEL_${level}/quoteType/${quoteType}`, config);
  },

  setApprovers(quoteId, data) {
    return axios.patch(`quotes/${quoteId}/approver`, data, configPost);
  },

  setReviewInput(quoteId, data) {
    return axios.patch(`quotes/${quoteId}/reviewinput`, data, configPost);
  },

  setQuoteDataInput(quoteId, data) {
    return axios.patch(`quotes/${quoteId}/quotedata`, data, configPost);
  },

  setValueAddInput(quoteId, data) {
    return axios.patch(`quotes/${quoteId}/valueadd`, data, configPost);
  },

  getDealHistory(quoteId) {
    return axios.get(`histories/${quoteId}`, config);
  },

  setDealComment(quoteId, data) {
    return axios.put(`histories/quotes/${quoteId}/create`, data, configPost);
  },

  createComboDeal(data) {
    return axios.post('quotes/saveComboDeal', data, configPost);
  },

  saveComboDeal(comboDealId, data) {
    return axios.patch(`quotes/${comboDealId}/updateComboDeal`, data, configPost);
  },

  fetchAllUsers() {
    return axios.get('users/overview', config);
  },

  createUser(data) {
    return axios.post('users/create', data, configPost);
  },

  editUser(userId) {
    return axios.get(`users/edit/${userId}`, config);
  },

  saveUser(userId, data) {
    return axios.patch(`users/save/${userId}`, data, configPost);
  },

  getUserHistory(userId) {
    return axios.get(`users/histories/${userId}`, config);
  },

  getLoggedinUser() {
    return axios.get('users/loggedInUser', config);
  },

  downloadExcel(url) {
    return axios.get(url, configBlob).then((response) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      const contentDisposition = response.headers['content-disposition'];
      let excelFileName = 'export_pricing-escalation-app.xlsx';
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        const fileName = fileNameMatch[1];
        excelFileName = fileName;
      }
      link.setAttribute('download', excelFileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
  },

  createNotification(data) {
    return axios.post('/notifications/create', data, configPost);
  },

  fetchNotification() {
    return axios.get('/notifications', config);
  },

  saveNotification(data) {
    return axios.patch('/notifications/save', data, configPost);
  },
};

export default ApiService;
