<template>
  <v-snackbar
    :timeout="timeout"
    v-model="snackbar"
    bottom
    text
    color="white"
    outlined
    multi-line
  >
    <div>
      <p class="mb-3">This deal has been changed by another user or by you in another window.
        The values entered there are shown here now.<br /></p>
      <p class="mb-0">Please check your last entry and repeat your changes if necessary.</p>
    </div>
    <template v-slot:action="{ attrs }">
        <v-btn icon
               @click="hideSnackbar"
               v-bind="attrs"
        >
          <v-icon color="white" >mdi-close</v-icon>
        </v-btn>
    </template>
  </v-snackbar>
</template>

<script>

export default {
  name: 'DataChangedNotification',
  props: {
    timeout: {
      type: Number,
      default: 10000,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      snackbar: false,
    };
  },
  methods: {
    hideSnackbar(event) {
      event.stopPropagation();
      this.snackbar = false;
    },
  },
  watch: {
    visible(val) {
      this.snackbar = val;
      // console.log('visible is changing to', val);
    },
    snackbar(val) {
      // console.log('snackbar is changing to', val);
      this.$emit('update:visible', val);
    },
  },
};
</script>
<style lang="scss">
  .v-snack {
    .v-snack__wrapper {
      border: none;
      background-color: #ec6602 !important;
    }
    p {
      font-size: .95rem;
    }
  }
</style>
