<template>
  <div class="people-level1">
    <!-- {{ dealType }} Verifier n.a. -->
    <v-list-item>
      <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
        <v-icon v-if="(typeof salesRep === 'undefined' || !salesRep) && !dealData.salesRep">
          mdi-account
        </v-icon>
        <template v-if="salesRep">
          <span class="black--text">
            {{ getInitialsFull(salesRep) }}
          </span>
        </template>
        <template v-else-if="dealData.salesRep">
          <span class="black--text">
            {{ getInitialsFull(dealData.salesRep) }}
          </span>
        </template>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle>
          {{ dealType }} Sales Approver*
        </v-list-item-subtitle>
        <v-list-item-title v-if="salesRep">
          {{ salesRep }}
        </v-list-item-title>
        <v-list-item-title v-else-if="dealData.salesRep">
          {{ dealData.salesRep }}
        </v-list-item-title>
        <v-list-item-title v-else>
          &ndash;
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!-- {{ dealType }} Finance Approver -->
    <v-list-item>
      <v-list-item-avatar color="#EEEEEE" size="40" class="mr-4">
        <template  v-if="creatorFull">
        <span class="black--text">
          {{ creatorFull[0].initials }}
        </span>
        </template>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-subtitle>
          {{ dealType }} Finance Approver
        </v-list-item-subtitle>
        <v-list-item-title>
          {{ dealData.creator.firstName }} {{ dealData.creator.lastName }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import dealFunctions from '../../../mixins/DealFunctions';

export default {
  name: 'PeopleLevel1',
  props: {
    dealData: Object,
    creatorFull: Array,
    dealType: String,
    salesRep: String,
  },
  mixins: [
    dealFunctions,
  ],
};
</script>
