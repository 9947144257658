import Vue from 'vue';
import Vuex from 'vuex';
import ApiService from '../services/ApiService';
import isInTime from '../mixins/isInTime';

Vue.use(Vuex);

const initialNotificationState = {
  message: '',
  type: '',
  periodOfTime: [],
  active: false,
  hasNotificationShown: false,
  rowVersion: null,
};

export default new Vuex.Store({
  state: {
    loggedInUser: {},
    jumpTarget: null,
    levelChanged: false,
    levelManuallySet: false,
    userReadOnly: false,
    notification: initialNotificationState,
  },
  getters: {
    loggedInUser: (state) => state.loggedInUser,
    jumpTarget: (state) => state.jumpTarget,
    levelChanged: (state) => state.levelChanged,
    levelManuallySet: (state) => state.levelManuallySet,
    userReadOnly: (state) => state.userReadOnly,
    // eslint-disable-next-line consistent-return
    initials: (state) => {
      if (state.loggedInUser.firstName) {
        return state.loggedInUser.firstName[0] + state.loggedInUser.lastName[0];
      }
    },
    notification: (state) => state.notification,
    isNotificationShown: (state) => (
      !!state.notification.active
      && !state.notification.hasNotificationShown
      && isInTime(state.notification.periodOfTime)
    ),
  },
  mutations: {
    setLoggedInUser(state, user) {
      state.loggedInUser = user;
    },
    setJumpTarget(state, target) {
      state.jumpTarget = target;
    },
    setLevelChanged(state, levelChanged) {
      state.levelChanged = levelChanged;
    },
    setlevelManuallySet(state, levelManuallySet) {
      state.levelManuallySet = levelManuallySet;
    },
    setUserReadOnly(state, userReadOnly) {
      state.userReadOnly = userReadOnly;
    },
    setNotificationShownState(state) {
      state.notification.hasNotificationShown = true;
    },
    setNotificationFields(state, payload) {
      const newField = {
        [payload.fieldName]: payload.value,
      };
      state.notification = { ...state.notification, ...newField };
    },
    setNotification(state, payload) {
      state.notification = { ...state.notification, ...payload };
    },
  },
  actions: {
    getLoggedInUser({ commit }) {
      ApiService.getLoggedinUser().then((response) => {
        if (response) {
          commit('setLoggedInUser', response.data);
          commit('setUserReadOnly', response.data.readOnly);
          localStorage.setItem('currentUser', JSON.stringify(response.data));
        }
      })
        .catch((error) => console.log(error));
    },
    getNotification(context) {
      ApiService.fetchNotification().then((response) => {
        if (response) {
          const newDataObj = {
            message: response.data.message,
            periodOfTime: [response.data.from, response.data.until],
            active: response.data.active,
            type: response.data.type,
            rowVersion: response.data.rowVersion,
          };
          context.commit('setNotification', newDataObj);
        } else {
          const newDataObj = {
            message: '',
            periodOfTime: [],
            active: false,
            type: '',
            rowVersion: null,
          };
          context.commit('setNotification', newDataObj);
        }
      }).catch((error) => {
        console.log(error.message);
      });
    },
    saveNotificationFields(context, payload) {
      const { fieldName, value } = payload;
      const newValue = (fieldName === 'message' && value.length === 0) ? context.state.notification[fieldName] : value;
      context.commit('setNotificationFields', { fieldName, value: newValue });
      const callApi = fieldName === 'active' || context.state.notification.active;
      if (callApi) {
        const [from, until] = context.state.notification.periodOfTime.sort((a, b) => new Date(a) - new Date(b));
        const dataModel = { ...context.state.notification, from, until };
        delete dataModel.hasNotificationShown;
        delete dataModel.periodOfTime;
        if (dataModel.rowVersion === null) {
          ApiService.createNotification(dataModel).catch((error) => console.log(error.message));
        } else {
          ApiService.saveNotification(dataModel).catch((error) => console.log(error.message));
        }
      }
    },
  },
  modules: {
  },
});
