<template>
  <v-dialog v-model="isModalOn" max-width="700" v-on="$listeners">
    <v-card class="py-2">
      <v-card-title>
        <h5>Deal Data Export</h5>
        <v-spacer></v-spacer>
        <v-icon class="cra-btn-close" large @click="closeAndCancel">mdi-close</v-icon>
      </v-card-title>
      <div v-if="!activeDownload">
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <p>Use the options below to create an excel export of the existing deal data - either related to a specific time period or overall. Please note that especially with an overall export it may take some time until the export is generated. Leave this window open until the corresponding file downloads.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Period of time"
                      append-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      clearable
                    />
                  </template>
                  <v-date-picker
                    v-model="periodOfTime"
                    no-title
                    range
                  />
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="px-5 pb-5">
          <v-btn
            rounded
            color="primary"
            depressed
            :disabled="periodOfTime.length !== 2"
            @click="periodExport"
          >
            Generate period related export
          </v-btn>
          <v-spacer/>
          <v-btn
            rounded
            color="primary"
            depressed
            :disabled="periodOfTime.length !== 0"
            @click="overallExport"
          >
            Generate overall export
          </v-btn>
        </v-card-actions>
      </div>
      <div v-else>
        <v-card-text>
          <v-container fluid class="px-0">
            <v-row align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="primary"
              >
              </v-progress-circular>
            </v-row>
          </v-container>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import ApiService from '../../services/ApiService';

export default {
  name: 'ExportDialog',
  data() {
    return {
      modal: true,
      periodOfTime: [],
      menu: false,
      baseUrl: '/quotes/downloadExcelExport',
      activeDownload: false,
    };
  },
  emits: ['close'],
  computed: {
    isModalOn: {
      get() {
        return this.modal;
      },
      set() {
        this.$emit('close');
      },
    },
    dateRangeText: {
      get() {
        return this.periodOfTime.join(' - ');
      },
      set() {
        this.periodOfTime = [];
      },
    },
  },
  methods: {
    closeAndCancel() {
      this.$emit('close');
    },
    periodExport() {
      const [from, to] = this.periodOfTime.sort((a, b) => new Date(a) - new Date(b));
      const urlParams = `?from=${from}&to=${to}`;
      this.callApi(this.baseUrl + urlParams);
    },
    overallExport() {
      this.callApi(this.baseUrl);
    },
    callApi(url) {
      this.activeDownload = true;
      ApiService.downloadExcel(url).then(() => {
        this.activeDownload = false;
        this.$emit('close');
      });
    },
  },
};
</script>
