import Vue from 'vue';
import axios from 'axios';
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib'; // Globally import VTextField for currency field
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { AuthPlugin } from './plugins/auth';
import ConstPlugin from './plugins/constants';

axios.defaults.baseURL = process.env.VUE_APP_API_PATH;

const getConfig = async () => {
  const Config = await fetch('/config/config.json');
  return Config.json();
};

getConfig().then((json) => {
  // eslint-disable-next-line prefer-destructuring
  const tenantId = json.tenantId;
  // eslint-disable-next-line prefer-destructuring
  const clientId = json.clientId;

  // Install the authentication plugin here
  Vue.use(AuthPlugin, {
    tenantId,
    clientId,
    onLoginCallback: (goTo) => {
      console.log('login succeed, redirection to', goTo);
      router.push({ path: goTo });
    },
    onErrorCallback: () => {
      console.log('login failed, redirection to Login page');
      router.push({ path: '/' });
    },
  });

  Vue.use(ConstPlugin);
  Vue.component('v-text-field', VTextField);
  Vue.use(VCurrencyField, {
    locale: 'en-US',
    decimalLength: 0,
    autoDecimalMode: false,
    min: null,
    max: null,
    defaultValue: null,
    valueAsInteger: true,
    allowNegative: true,
  });

  Vue.config.productionTip = false;

  Vue.filter('toCurrency', (value) => {
    if (value == null) return '';

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });

    return formatter.format(value);
  });
  Vue.filter('toNumber', (value) => {
    if (!value) return '';

    const formatter = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
    });

    return formatter.format(value);
  });
  Vue.filter('toDate', (value) => {
    if (!value) return '';

    const options = {
      year: 'numeric', month: '2-digit', day: '2-digit', hour: 'numeric', minute: 'numeric',
    };
    const dateString = new Date(value).toLocaleString('en-US', options);

    return dateString;
  });

  new Vue({
    router,
    store,
    vuetify,
    axios,
    render: (h) => h(App),
  }).$mount('#app');
});
