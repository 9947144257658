<template>
  <div class="approval-text">
    <template v-if="dealData.escalationState==='NEW'">
      <v-card-title class="mt-10 mb-5">
        <h5>Items needing Approval*</h5>
      </v-card-title>
      <v-list-item>
        <v-list-item-content class="py-0">
          <v-form v-model="valid">
            <v-row>
              <v-col cols="12" class="py-0">
                <v-textarea
                  v-model="approvalText"
                  :rules="approvalRules"
                  required
                  :placeholder="longPlaceHolder"
                  auto-grow
                  rows="1"
                  row-height="15"
                  :disabled="isReadOnly"
                  @blur="emitChangeApprovalText"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-checkbox
          class="mt-0"
          v-model="checkStandAgreement"
          label="Distributor Standard Agreement"
          :disabled="isReadOnly"
          @change="emitChangeStandardAgreement"
        ></v-checkbox>
      </v-list-item>
    </template>
    <template v-else>
      <v-card-title class="mt-10 mb-5">
        <h5>Items needing Approval</h5>
      </v-card-title>
      <v-card-text>
        <p>{{ dealData.approvalText }}</p>
      </v-card-text>
    </template>
    <template v-if="isVerifier">
      <v-list-item>
        <v-checkbox
          class="mt-0"
          v-model="approvalsAlreadyObtained"
          label="Further Approvals already Obtained"
          :disabled="isReadOnly"
          @change="emitChangeApprovalsObtained"
        ></v-checkbox>
      </v-list-item>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ApprovalText',
  props: {
    dealData: Object,
  },
  data() {
    return {
      valid: true,
      approvalText: '',
      approvalRules: [
        (v) => !!v || '',
      ],
      longPlaceHolder: 'Please enter all information relevant for approving this deal. If no items need to be approved, this information must be entered as well.',
      checkStandAgreement: false,
      approvalsAlreadyObtained: false,
    };
  },
  mounted() {
    if (this.dealData.approvalText) {
      this.approvalText = this.dealData.approvalText;
    }
    if (this.dealData.distributorStandardAgreement) this.checkStandAgreement = true;
  },
  methods: {
    emitChangeApprovalText() {
      this.$emit('changeApprovalText', this.approvalText);
    },
    emitChangeStandardAgreement() {
      this.$emit('changeStandardAgreement', this.checkStandAgreement);
    },
    emitChangeApprovalsObtained() {
      this.$emit('changeApprovalsObtained', this.approvalsAlreadyObtained);
    },
    emitFormValid(val) {
      this.$emit('changeFormValid', val);
    },
  },
  computed: {
    isReadOnly() {
      return this.$store.state.userReadOnly;
    },
    isVerifier() {
      return (this.dealData.escalationState === 'VERIFIER_LD' || this.dealData.escalationState === 'VERIFIER_POC');
    },
  },
  watch: {
    valid(val) {
      // console.log('Valid changed Approval', val);
      this.emitFormValid(val);
    },
  },
};
</script>

<style lang="scss">
  .approval-text {
    .v-card__text {
      white-space: pre-line;
    }
  }
</style>
